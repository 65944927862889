import React, { useContext, useEffect, useState } from "react";
import Breadcrumb from "../../../CommonComponents/BreadCrumb/BreadCrumb";
import CustomInput from "../../../CommonComponents/CustomForm/CustomInputField";
import Button from "../../../Elements/Button";
import { SubscriptionPeriodArr } from "../../../../hooks/utils";
import { csrfToken } from "../../../../utis/csrfTokenUtils";
import { networkService } from "../../../config/axios";
import { Form } from "react-bootstrap";
import UserInfoContext from "../../../../utis/userInfoContext";

const AddSubscriptionPage = (props) => {
  const { edit_subscription, type } = props
  const { setShowNotifications } = useContext(UserInfoContext)
  const [subscription, setSubscription] = useState({
    name: '',
    description: '',
    product_id: '',
    period: '',
    amount: '',
    currency: '',
    discount: '',
    payment_url: '',
  })
  const [error, setError] = useState({})

  const handleChange = ({ target: { name, value } }) => {
    setError({
      [name]: '',
    })
    setSubscription({
      ...subscription,
      [name]: value
    });
  }

  const handleValidations = (subscription) => {
    let hasError = false;
    const newErrors = {};

    if (!subscription?.name) {
      newErrors.name = "Please add the Subscription name";
      hasError = true;
    }
    if (!subscription?.product_id) {
      newErrors.product_id = "Please enter the product ID";
      hasError = true;
    }
    if (!subscription?.period) {
      newErrors.amount = "Please enter the Period";
      hasError = true;
    }
    if (!subscription?.amount) {
      newErrors.amount = "Please enter the amount";
      hasError = true;
    }
    if (!subscription?.currency) {
      newErrors.currency = "Please enter the currency";
      hasError = true;
    }
    if (!subscription?.discount) {
      newErrors.discount = "Please enter the discount";
      hasError = true;
    }
    if (!subscription?.payment_url) {
      newErrors.payment_url = "Please enter the payment URL";
      hasError = true;
    }

    if (hasError) {
      setError({ ...error, ...newErrors });
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (handleValidations(subscription)) {
      const data = {
        authenticity_token: csrfToken(),
        ...(edit_subscription && { id: edit_subscription?.id }),
        subscription: {
          name: subscription?.name,
          description: subscription?.description,
          product_id: subscription?.product_id,
          period: subscription?.period,
          amount: subscription?.amount,
          currency: subscription?.currency,
          discount: subscription?.discount,
          payment_url: subscription?.payment_url,
        }
      }
      if (!subscription?.name) {
        setError({ ...error, name: 'Please add the organisation name' })
      }
      const url = edit_subscription?.id ? `admin/subscriptions/${edit_subscription?.id}.json` : 'admin/subscriptions.json'
      await networkService(url, edit_subscription?.id ? 'PATCH' : 'POST', data).
        then((response) => {
          if (response.status == 200 || response?.status === 201) {
            window.location.href = '/admin/subscriptions'
            setShowNotifications({
              showToast: true,
              toastType: "success",
              toastMessage: `Subscription ${edit_subscription?.id ? 'Updated' : 'Added'}`,
            })
          }
        }).
        catch((error) => {
          setShowNotifications({
            showToast: true,
            toastType: 'danger',
            toastMessage: "Something went wrong"
          })
        });
    }
  }

  useEffect(() => {
    setSubscription(edit_subscription)
  }, [edit_subscription])

  return (
    <>
      <Breadcrumb string={`Admin/Subscriptions/${type == 'edit' ? 'Update' : 'ADD'}`} />
      <div className="logs-form">
        <div className="form-group">
          <label className="mb-1" htmlFor="name">
            *Name
          </label>
          <CustomInput
            placeholder={"name"}
            value={subscription?.name}
            onChange={handleChange}
            name={'name'}
            required={true}
            type={'text'}
            error={error?.name}
          />
        </div>
        <div className="form-group">
          <label className="mb-1" htmlFor="description">
            Description
          </label>
          <CustomInput
            placeholder={"description"}
            value={subscription?.description}
            onChange={handleChange}
            name={'description'}
            type={'text'}
            required={true}
          />
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="mb-1" htmlFor="thrive">
                *Thrive product id
              </label>
              <CustomInput
                placeholder={"product_id"}
                value={subscription?.product_id}
                onChange={handleChange}
                name={'product_id'}
                required={true}
                type={'text'}
                error={error?.product_id}
              />
            </div>
          </div>
          <div className="col-md-6">
            <label className="mb-1" htmlFor="thrive">
              *Period
            </label>
            <Form.Select name='period'
              value={subscription?.period}
              onChange={handleChange}
            >
              {SubscriptionPeriodArr?.map((option, idx) => (
                <option key={idx} value={option?.value}>{option?.name}</option>
              ))}
            </Form.Select>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-md-4">
              <label className="mb-1" htmlFor="amount">
                *Amount
              </label>
              <CustomInput
                placeholder={"amount"}
                value={subscription?.amount}
                onChange={handleChange}
                name={'amount'}
                required={true}
                type={'text'}
                error={error?.amount}
              />
            </div>
            <div className="col-md-4">
              <label className="mb-1" htmlFor="currency">
                *Currency
              </label>
              <CustomInput
                placeholder={"currency"}
                value={subscription?.currency}
                onChange={handleChange}
                name={'currency'}
                required={true}
                type={'text'}
                error={error?.currency}
              />
            </div>
            <div className="col-md-4">
              <label className="mb-1" htmlFor="discount">
                *Discount
              </label>
              <div className="d-flex">
                <CustomInput
                  placeholder={"discount"}
                  value={subscription?.discount}
                  name={'discount'}
                  onChange={handleChange}
                  required={true}
                  type={'text'}
                  error={error?.discount}
                />
                <div className="input-group-append">
                  <span className="input-group-text">%</span>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="form-group">
          <label className="mb-1" htmlFor="pay">
            *Payment url
          </label>
          <CustomInput
            placeholder={"payment_url"}
            value={subscription?.payment_url}
            onChange={handleChange}
            name={'payment_url'}
            required={true}
            type={'text'}
            error={error?.payment_url}
          />
        </div>
        <div className="form-group mb-5">
          <div className="back-sub d-flex justify-content-end gap-2">
            <Button
              classList="alert"
              text="Back"
              onClick={() => (window.location.href = "/admin/subscriptions")}
            />
            <Button
              classList="success"
              type="submit"
              text={`${edit_subscription?.id ? 'Update' : 'Create'} Subscription`}
              onClick={(e) => handleSubmit(e)}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default AddSubscriptionPage;
