import React, { useContext } from "react";
import CommonModal from "../CommonModal";
import { EditorInfoContext } from "../../../utis/userInfoContext";
import { networkService } from "../../config/axios";
import useEditorHook from "../../Pages/Kdf/EditKdf/editorHook/useEditorHook";
import Button from "../../Elements/Button";

const DeleteElementModal = ({ openDeleteModal, closeModal, elemId, modalType }) => {
    const { setElements, lightBoxList, setLightBoxList, setEditElement, setShowToast, setImageElement } = useContext(EditorInfoContext)
    const { setTool } = useEditorHook()

    const handleDeleteElement = async (elemId) => {
        if (modalType == 'lightbox') {
            const lightboxDeleted = await networkService(`docs/lightboxes/${elemId}.json`, 'DELETE');
            if (lightboxDeleted.status == 204) {
                setShowToast({
                    showToast: true,
                    toastType: 'success',
                    toastMessage: "Lightbox deleted"
                })
                setLightBoxList(lightBoxList.filter(element => element.id !== elemId))
            } else {
                setShowToast({
                    showToast: true,
                    toastType: 'danger',
                    toastMessage: "Something went wrong"
                })
            }
            closeModal(false)
            setTool({})
        } else {
            const DeleteElement = await networkService(`docs/elements/${elemId}.json`, 'DELETE');
            if (DeleteElement.status == 204) {
                setShowToast({
                    showToast: true,
                    toastType: 'success',
                    toastMessage: "Element Deleted"
                })
                setElements(prevElements => {
                    const { [elemId]: _, ...newElements } = prevElements;
                    return newElements;
                });
                setImageElement(prevElements => {
                    const { [elemId]: _, ...newElements } = prevElements;
                    return newElements;
                })
            } else {
                setShowToast({
                    showToast: true,
                    toastType: 'danger',
                    toastMessage: "Something went wrong"
                })
            }
            setEditElement({})
            closeModal(false)
        }
    }

    return (
        <CommonModal
            title={"Remove Element"}
            modalStatus={openDeleteModal}
            changeActiveStatus={closeModal}
            classList="header-profile-modal-wrapper alert-modal"
            showFooter={false}
            centered
        >
            <div>
                <p className="mb-2">Are you sure you want to delete this {modalType === 'lightbox' ? 'Lightbox' : 'Element'} ?</p>
                <div className="kdf-btn mt-3">
                    <Button
                        text={'Cancel'}
                        classList={"alert"}
                        addonClass={'me-3'}
                        onClick={() => closeModal(false)}
                    />
                    <Button
                        text={'Yes'}
                        classList={"success"}
                        addonClass={''}
                        onClick={() => handleDeleteElement(elemId)}
                    />
                </div>
            </div>
        </CommonModal>
    )
}
export default DeleteElementModal