import React from "react";
import CommonModal from "../CommonModal";
import Button from "../../Elements/Button";

const MoveDataModel = ({ openModal, closeModal, userData, handleSubmit }) => {
    return (
        <>
            <CommonModal
                title={"Move Data"}
                modalStatus={openModal}
                changeActiveStatus={(status) => closeModal()}
                classList="header-profile-modal-wrapper"
                centered
            >
                <div>
                    <div className="d-flex flex-column justify-content-between ">
                        <div className="overview-header mb-3">
                            <span className="user-name">{userData?.name}</span> Overview:
                        </div>
                    </div>
                    <ul className="overview-details mb-3">
                        <li>
                            <strong>{userData?.docs_count}</strong> KDFs created
                        </li>
                        <li>
                            <strong>{userData?.tracking_links_count}</strong> LaserLINX created
                        </li>
                        <li>
                            Last login: <strong>{userData?.last_logged_in}</strong>
                        </li>
                    </ul>
                    <Button
                        text={'MOVE KDFs & LaserLINX and REMOVE this User'}
                        classList={'success'}
                        addonClass={'my-2 mx-2 move-kdf-modal'}
                        onClick={handleSubmit}
                    // className="action-button"
                    />
                </div>
            </CommonModal>
        </>
    );
};
export default MoveDataModel; 