import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import noImage from "../../../../assets/images/icon_upload.svg";
import CustomTooltip from "../../Elements/Tooltip";

const CustomDropdown = ({ options, onSelect, selected, placeholder }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleErrorImage = (element) => {
    element.target.src = noImage;
  };

  const handleSelect = (eventKey) => {
    const selectedOption = options.find((option) => option.value === eventKey);
    if (selectedOption && !selectedOption.disable) {
      onSelect(eventKey);
    }
  };

  const updateSelectedValue = () => {
    const selectedOptionToUpdate = options?.find(
      (option) => option.value == selected
    );
    if (
      selectedOptionToUpdate &&
      Object.keys(selectedOptionToUpdate)?.length > 0
    ) {
      setSelectedOption({ ...selectedOptionToUpdate });
    }
  };

  useEffect(() => {
    updateSelectedValue();
  }, [selected]);

  return (
    <Dropdown onSelect={handleSelect}>
      <Dropdown.Toggle
        variant="light"
        id="custom-dropdown"
        className="w-100"
        style={{
          height: "35px",
          border: "1px solid #ced4da",
          background: "#fff",
        }}
      >
        {selected ? (
          <div className="d-flex align-items-center">
            {selectedOption?.class && (
              <div
                className={selectedOption?.class}
                style={{ width: 22, marginRight: 2 }}
              ></div>
            )}
            {selectedOption?.image && (
              <img
                src={selectedOption.image}
                alt={selectedOption.label}
                style={{ width: 22, marginRight: 8, height: 25 }}
                onError={(e) => handleErrorImage(e)}
              />
            )}
            <span
              // style={{
              //   padding: "3px 5px 3px 10px",
              // }}
              className={selectedOption?.class && "ms-1"}
            >
              {selectedOption?.label || "Select Language"}
            </span>
          </div>
        ) : (
          <span
            // style={{
            //   padding: "3px 5px 3px 10px",
            // }}
            className={selectedOption?.class && "ms-1"}
          >
            {placeholder || "Select an option"}
          </span>
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="w-100 laserlink-dropdown"
        style={{ maxHeight: "180px", overflowY: "scroll" }}
      >
        {options?.map((option, optionIndex) => (
          <>
            {option.labelTitle ? (
              <CustomTooltip title={option.labelTitle}>
                <p className="d-flex align-items-center dropdown-item blurItem">
                  {option?.class && (
                    <div
                      className={option.class}
                      style={{
                        width: "25px",
                        height: "25px",
                        marginRight: 2,
                      }}
                    ></div>
                  )}
                  {option?.image && (
                    <img
                      src={option.image}
                      alt={option.label}
                      style={{ width: 22, marginRight: 8 }}
                      onError={(e) => handleErrorImage(e)}
                    />
                  )}
                  <span className={selectedOption?.class && "ms-1"} >
                    {option?.label}
                  </span>
                </p>
              </CustomTooltip>
            ) : (
              <Dropdown.Item
                key={`${option.value}-${optionIndex}`}
                eventKey={option.value}
                className={`d-flex align-items-center ${option.disable ? "disabled" : ""
                  }`}
                disabled={option.disable} // Add disabled attribute
              >
                {option?.class && (
                  <div
                    className={option?.class}
                    style={{
                      width: "25px",
                      height: "25px",
                      marginRight: 2,
                    }}
                  ></div>
                )}
                {option.image && (
                  <img
                    src={option.image}
                    alt={option.label}
                    style={{ width: 22, marginRight: 8 }}
                    onError={(e) => handleErrorImage(e)}
                  />
                )}
                <span className={selectedOption?.class && "ms-1"}
                  style={{
                    width: "100px",
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}>
                  {option.label}
                </span>

                {/* {
                                option?.labelTitle ? (
                                    // <CustomTooltip title={option.labelTitle}>
                                        <span>{option.label}</span>
                                    // </CustomTooltip>
                                ) : (
                                    <span>{option.label}</span>
                                )
                                } */}
              </Dropdown.Item>
            )}
          </>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CustomDropdown;
