import React from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const PieChart = ({ data, name }) => {

    const graphData = data?.map(item => ({
        name: item?.[name] ?? name,
        y: item?.hits
    }));

    const pieOptions = {
        chart: {
            type: 'pie',
        },
        title: {
            text: '',
        },
        tooltip: {
            pointFormat: '{point.y} Visits ({point.percentage:.1f}%)',
        },
        series: [
            {
                name: name,
                data: graphData,
            },
        ],
    };
    return (
        <>
            <HighchartsReact highcharts={Highcharts} options={pieOptions} />
        </>
    )
}
export default PieChart