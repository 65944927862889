import React from "react";

const ElementPosition = ({ tool, handleChange }) => {
  return (
    <div className="border-mask-section d-flex">
      <div className="toggle-container">
        <label className="img-lab-clr">FIT WITHIN PAGE BORDERS</label>
        <input
          type="checkbox"
          id="toggle"
          className="toggle-input"
          name='geometry.keep_within_page'
          value={tool?.geometry?.keep_within_page}
          checked={tool?.geometry?.keep_within_page}
          onChange={handleChange}
        />
        <label htmlFor="toggle" className="toggle-btn"></label>
      </div>
      <div className="toggle-container">
        <label className="img-lab-clr">MASK BEYOND PAGE EDGES</label>
        <input
          type="checkbox"
          id="toggle3"
          className="toggle-input"
          name="geometry.hide_beyond_page_edges"
          checked={tool?.geometry?.hide_beyond_page_edges}
          value={tool?.geometry?.hide_beyond_page_edges}
          onChange={handleChange}
        />
        <label htmlFor="toggle3" className="toggle-btn"></label>
      </div>
    </div>
  );
};
export default ElementPosition;
