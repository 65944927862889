import React from "react"
import PositionSection from "./PositionSection"
import TransparencySection from "./TransparencySection"
import TagSection from "./TagSection"
import AnimationSection from "./AnimationSection"
import ClickActionSection from "./ClickActionSection"
import RotateSection from "./RotateSection"
import ElementPosition from "./ElementPosition"

const FormFieldsSection = ({ handleChange, tool, toolType, docData, editElement, setTool, totalPages, errorState }) => {

    return (
        <>
            <PositionSection
                handleChange={handleChange}
                tool={tool}
                setTool={setTool}
                errorState={errorState}
            />
            {(toolType === 'ImageToolbox' || editElement?.element_type === 4) &&
                <>
                    <ElementPosition
                        handleChange={handleChange}
                        tool={tool}
                    />
                    <TransparencySection
                        handleChange={handleChange}
                        tool={tool}
                    />
                </>
            }
            {tool?.id && <RotateSection tool={tool} setTool={setTool} />}
            <TagSection
                handleChange={handleChange}
                tool={tool}
                totalPages={docData?.num_pages}
                setTool={setTool}
                errorState={errorState}
                toolType={toolType}
            />
            <AnimationSection
                handleChange={handleChange}
                tool={tool}
            />
            {
                (toolType === 'ImageToolbox' ||
                    toolType === 'HotspotToolbox' ||
                    editElement?.element_type === 4 ||
                    editElement?.element_type === 9) && toolType !== 'Clone' &&
                <ClickActionSection
                    totalPages={totalPages}
                    handleChange={handleChange}
                    tool={tool}
                    currentDocId={docData?.doc_id}
                    setTool={setTool}
                    errorState={errorState}
                />
            }
        </>
    )
}
export default FormFieldsSection