import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const TermsAndPolicyModal = ({ title, children, footer, modalStatus, modalActiveStatus, showCloseButton = true }) => {
    const [show, setShow] = useState(false);
    useEffect(() => {
        setShow(modalStatus);
        return () => {
            setShow(false);
        }
    }, [modalStatus]);
    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header style={{ backgroundColor: '#294561', height: '50px' }}>
                <Modal.Title className='text-light'>{title}</Modal.Title>
                {
                    showCloseButton && <FontAwesomeIcon onClick={() => modalActiveStatus(false)} icon={faXmark} className='au-modal-close-btn' />
                }
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>{footer}</Modal.Footer>
        </Modal>
    )
}
export default TermsAndPolicyModal;