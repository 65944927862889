import React from "react";
import Button from "../../../Elements/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faEye, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Table } from "react-bootstrap";
import CustomTooltip from "../../../Elements/Tooltip";
import { quality_data } from "../../../../hooks/utils";

const KDFSettingsPageListing = ({
  PdfList,
  handleUploadPDFModalStatus,
  docs,
  handleSetReplaceWith,
  handleDeletePDFEvent,
  handleUpdateDocStatus,
}) => {
  const handlePDFUpdateStatus = (e, fileID) => {
    handleUpdateDocStatus(
      { status: e.target.value, switch_active: true },
      fileID
    );
  };

  return (
    <>
      <div className="Language-Editions-main">
        <div className="setting-table">
          <h6 className="text-start">Language Editions</h6>
          <Table className="kdf-set-table" bordered>
            <thead>
              <tr>
                <th>File Name</th>
                <th>Language</th>
                <th>Date Added / Updated</th>
                <th style={{ width: "280px" }}>Status</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {PdfList?.map((pdf, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <CustomTooltip title={<p className='p-1 text-light'>{quality_data().find(option => option?.value === pdf?.quality)?.name}</p>}>
                        <span className="kdf-data-center">{pdf?.title}</span>
                      </CustomTooltip>
                    </td>
                    <td>
                      <span className="kdf-data-center">
                        <img
                          src={`${assetsBasePath}/${(pdf.language.locale.includes("-")
                            ? pdf.language.locale.toLowerCase().split("-")[1]
                            : pdf.language.locale) ?? "en"
                            }.png`}
                          alt={pdf?.language.name}
                          width="22px"
                        />{" "}
                        {pdf?.language?.name ?? "English (US)"}
                      </span>
                    </td>
                    <td>
                      <CustomTooltip title={<p className='p-1 text-light'>{pdf?.date_tooltip}</p>}>
                        <span className="kdf-data-center">{pdf?.updated_date ?? ""}</span>
                      </CustomTooltip>
                    </td>
                    <td>
                      <span className="kdf-data-center">
                        {pdf?.show_switch_to_active && (
                          <span className="d-flex align-items-center justify-content-between gap-5">
                            <CustomTooltip title={<p className='p-1 text-light'>Ready for editing and sharing</p>}>
                              <select
                                id="mySelect"
                                onChange={(e) => handlePDFUpdateStatus(e, pdf.id)}
                                value={pdf.status}
                              >
                                <option value={"active"}>Ready</option>
                                <option value={"inactive"}>Edit Only</option>
                                <option value={"offline"}>Offline</option>
                              </select>
                            </CustomTooltip>
                            <u>
                              <span
                                className="setting-link ml-2"
                                onClick={() => handleSetReplaceWith(pdf.id)}
                              >
                                Replace PDF
                              </span>
                            </u>
                          </span>
                        )}
                      </span>
                    </td>
                    <td>
                      <CustomTooltip title={<p className="p-1 text-light">Edit this KDF edition</p>}>
                        <span className="kdf-data-center">
                          {pdf?.show_switch_to_active && (
                            <FontAwesomeIcon
                              icon={faPencil}
                              className=""
                              onClick={() => {
                                window.open(`/editor/${docs?.id}`, `editor-${docs?.id}`);
                              }}
                            />
                          )}
                        </span>
                      </CustomTooltip>
                    </td>
                    <td>
                      <CustomTooltip title={<p className="p-1 text-light">View KDF</p>}>
                        <span className="kdf-data-center">
                          {pdf?.show_switch_to_active && (
                            <FontAwesomeIcon
                              icon={faEye}
                              onClick={() => window.open(`${pdf.viewer}`, `viewer-${pdf?.id}`)}
                            />
                          )}
                        </span>
                      </CustomTooltip>
                    </td>
                    <td>
                      <span className="kdf-data-center">
                        {pdf?.show_switch_to_active && (
                          <FontAwesomeIcon
                            icon={faTrashCan}
                            className="cancel"
                            onClick={() => handleDeletePDFEvent(pdf.id)}
                          />
                        )}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <div className="mt-5 mb-5">
          <Button
            onClick={() => handleUploadPDFModalStatus(true)}
            text={"Add Language"}
            addonClass={"rounded-0"}
            classList={'success'}
          />
        </div>
      </div>
    </>
  );
};

export default KDFSettingsPageListing;
