import React, { useContext, useEffect, useState } from "react";
import CustomInput from "../../../CommonComponents/CustomForm/CustomInputField";
import { csrfToken } from "../../../../utis/csrfTokenUtils";
import { networkService } from "../../../config/axios";
import Button from "../../../Elements/Button";
import CustomForm from "../../../CommonComponents/CustomForm/CustomForm";
import Select from "../../../Elements/Select";
import { userTypeArr } from "../../../../hooks/utils";
import UserInfoContext from "../../../../utis/userInfoContext";

const AddUserForm = ({ setShow, companyId, selectedUser }) => {
  const { setShowNotifications } = useContext(UserInfoContext);
  const initialState = {
    email: "",
    user_type: "",
    profile: {
      first_name: "",
      last_name: "",
      time_zone: "",
    },
  };

  const [timeZoneData, setTimeZoneData] = useState({});
  const [error, setError] = useState({});
  const [user, setUser] = useState(initialState);

  const handleChange = ({ target: { name, value, type } }) => {
    const profileFields = ["first_name", "last_name", "time_zone"];
    if (type === "date") {
      let formattedDate = new Date(value);
      value = formattedDate.toISOString();
    }
    setError({
      [name]: "",
    });
    if (profileFields.includes(name)) {
      setUser({
        ...user,
        profile: {
          ...user?.profile,
          [name]: value,
        },
      });
    } else {
      setUser({
        ...user,
        [name]: value,
      });
    }
  };

  const handleValidations = () => {
    if (!user?.name) {
      setError({ ...error, fieldError: "Please add the lightbox name" });
    } else if (!elementName) {
      setError({ ...error, elementError: "Please select the Element" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let url = selectedUser?.id
      ? `admin/users/${selectedUser?.id}.json`
      : "admin/users.json";
    if (!user?.name) {
      setError({ ...error, name: "Please add the name" });
    }
    const data = {
      authenticity_token: csrfToken(),
      ...(selectedUser?.id && { id: selectedUser?.id }),
      user: {
        company_id: companyId,
        email: user?.email,
        user_type: user?.user_type ?? user?.type,
        profile: {
          first_name: user?.profile?.first_name,
          last_name: user?.profile?.last_name,
          time_zone: user?.profile?.time_zone,
          ...(selectedUser?.id && { id: user?.profile?.id }),
        },
      },
    };
    await networkService(url, selectedUser?.id ? "PATCH" : "POST", data)
      .then((response) => {
        if (response.status == 200 || response?.status == 201) {
          setShow("list");
          setShowNotifications({
            showToast: true,
            toastType: "success",
            toastMessage: `User ${selectedUser?.id ? "Updated" : "Added"}`,
          });
          setUser(initialState);
        }
      })
      .catch((error) => {
        setShowNotifications({
          showToast: true,
          toastType: "danger",
          toastMessage:
            error?.response?.data?.errors?.email[0] ?? "Something went wrong",
        });
      });
  };

  useEffect(() => {
    const updateTimeZone = async () => {
      let getTimeZoneData = await networkService(`time-zones`);
      setTimeZoneData(getTimeZoneData?.data);
    };
    updateTimeZone();
  }, []);

  useEffect(() => {
    setUser(selectedUser);
  }, [selectedUser]);

  return (
    <CustomForm>
      <div className="amount-currencywrap mt-3">
        <div className="amnwrap">
          <CustomInput
            placeholder={"First name"}
            value={user?.profile?.first_name}
            name={"first_name"}
            onChange={handleChange}
            required={true}
            type={"text"}
            error={error?.first_name}
          />
        </div>
        <div className="amnwrap">
          <CustomInput
            placeholder={"Last name"}
            value={user?.profile?.last_name}
            name={"last_name"}
            onChange={handleChange}
            // required={true}
            type={"text"}
            // error={error?.last_name}
          />
        </div>
        <div className="amnwrap">
          <CustomInput
            placeholder={"Email"}
            value={user?.email}
            name={"email"}
            onChange={handleChange}
            // required={true}
            type={"text"}
            // error={error?.email}
          />
        </div>
        <Select
          classList="mb-3"
          name={"time_zone"}
          required={true}
          label={""}
          optionList={Object.keys(timeZoneData)?.map((item) => {
            return {
              value: timeZoneData[item].id,
              title: timeZoneData[item].name,
            };
          })}
          onChange={(e) => handleChange(e)}
          value={user?.profile?.time_zone}
        />
        <Select
          classList=""
          name="user_type"
          // required={true}
          onChange={handleChange}
          defaultValue={user?.user_type ?? user?.type}
          optionList={userTypeArr?.map((type) => {
            return {
              value: type.value,
              title: type.name,
            };
          })}
        />
      </div>
      <div className="manage-btn mt-3 text-center mb-3 d-flex">
        <Button
          classList="alert"
          text="Cancel"
          onClick={() => {
            setShow("list");
          }}
        />
        <Button
          classList="success me-2"
          type="button"
          text="Save"
          onClick={(e) => handleSubmit(e)}
        />
      </div>
    </CustomForm>
  );
};

export default AddUserForm;
