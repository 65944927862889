import React from "react"
import { useAnimate } from "../../../../hooks/useAnimate";

const AnimatedElements = ({ element, children, timerOnDoc, visible }) => {
    const { animateClass } = useAnimate(element, timerOnDoc);

    return (
        <div className={timerOnDoc == false && element?.value?.animation ?
            `animate__animated animate__${animateClass?.animationName ? animateClass.animationName : element?.value?.animation} animate__${animateClass?.infinite} ${animateClass?.infinite}`
            : ''}
            style={{ height: '100%' }}
        >
            {visible && children}
        </div>
    );
}
export default React.memo(AnimatedElements) 