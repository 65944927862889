import React, { useContext, useEffect, useState } from "react";
import CommonModal from "../../Modals/CommonModal";
import { Form } from "react-bootstrap";
import Button from "../../Elements/Button";
import { networkService } from "../../config/axios";
import UserInfoContext from "../../../utis/userInfoContext";
import { csrfToken } from "../../../utis/csrfTokenUtils";

const AddCustomizationModal = ({ openModal, closeModal, customizationData, setCustomizationId, setOpenClickModel }) => {

    const AddCustomizationBody = () => {
        const { userInfoData, setShowNotifications } = useContext(UserInfoContext);
        const msg = `I'd like to replace the default consent pop-up with one or more of the following: our own text, our own link to our cookie policy, and our image.`
        const [text, setText] = useState(msg)

        const handleSubmit = async (e) => {
            e.preventDefault()
            const url = customizationData?.id ? `customizations/${customizationData?.id}.json` : `customizations`
            const data = {
                authenticity_token: csrfToken(),
                ...(customizationData?.id && { id: customizationData?.id }),
                ...(customizationData?.id && { message: text }),
                ...(
                    !customizationData?.id && {
                        customization: {
                            user_id: userInfoData?.id,
                            company_id: userInfoData?.company?.id,
                            message: text,
                            approved: customizationData?.id ? true : false
                        }
                    }
                )
            }

            await networkService(url, customizationData?.id ? 'PATCH' : 'POST', data).
                then((response) => {
                    if (response.status == 200 || response?.status == 201) {
                        setCustomizationId(response?.data?.customization?.id)
                        setOpenClickModel(true)
                        closeModal(false)
                        setShowNotifications({
                            showToast: true,
                            toastType: "success",
                            toastMessage: "Customization request generated!",
                        })
                        setText('')
                    }
                }).
                catch((error) => {
                    setShowNotifications({
                        showToast: true,
                        toastType: 'danger',
                        toastMessage: "Something went wrong"
                    })
                });
        }

        useEffect(() => {
            if (customizationData?.id) {
                setText(customizationData?.message)
            } else {
                setText(msg)
            }
        }, [customizationData])

        return (
            <div>
                Request
                <Form.Control
                    as="textarea"
                    placeholder="Add text here"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    style={{ height: '100px' }}
                />
                <div className="mt-3">
                    <Button
                        classList={'success'}
                        text={'Save'}
                        onClick={(e) => {
                            handleSubmit(e)
                        }}
                        type='button'
                    />
                </div>
            </div>
        )
    }

    return (
        <CommonModal
            title={"Send Request"}
            modalStatus={openModal}
            changeActiveStatus={(status) => closeModal(false)}
            classList="header-profile-modal-wrapper"
            centered
        >
            <AddCustomizationBody />
        </CommonModal>
    )
}
export default AddCustomizationModal