import React from "react";
import { networkService } from "../../../../../../config/axios";
import CustomTagsInput from "../../../../../../CommonComponents/Tags/CustomTagsInput";
const TagSection = ({
  tool,
  handleChange,
  totalPages,
  setTool,
  errorState,
  toolType
}) => {

  const handeAddTags = async (value) => {
    let tagsList = await networkService(`tags.json`, "POST", { name: value });
    if (tagsList?.status === 201) {
      setTool((prevState) => {
        const newTags = prevState.tags
          ? [...prevState.tags, tagsList.data]
          : [tagsList.data];
        return {
          ...prevState,
          tags: newTags,
        };
      });
    }
  };

  const handleRemoveTags = (value) => {
    setTool({
      ...tool,
      tags: value,
    });
  };

  return (
    <>
      <div className="tags-inp row">
        <div className="col-md-6 p-0 ">
          <div className="d-flex  align-items-center">
            <label className="me-2 img-lab-clr" htmlFor="">
              Tags:
            </label>
            <CustomTagsInput
              tags={tool?.tags || []}
              handleAddTags={handeAddTags}
              handleRemoveTags={handleRemoveTags}
            />
          </div>
          {(!tool?.tags || tool?.tags?.length == 0) && (
            <p className="text-danger">{errorState?.tagErr}</p>
          )}
        </div>
        <div className="col-md-6">
          <div className="toggle-container high-element">
            <label className="img-lab-clr">High Priority Element: </label>
            <input
              type="checkbox"
              name="priority"
              id="toggle2"
              value={tool?.priority}
              onChange={handleChange}
              className="toggle-input"
            />
            <label htmlFor="toggle2" className="toggle-btn"></label>
          </div>
        </div>
      </div>
      <div className="color-select">
        <div className="d-flex align-items-center justify-content-between">
          {toolType != 'Clone' && <div>
            <p>BACKGROUND COLOR:</p>
            <div className="colour-picker-field">
              <input
                className="colour-picker-field__item--picker"
                name="bg_color"
                type="color"
                value={
                  tool?.value?.bg_color ?? "#ffffff"
                  // ? rgbStringToHex(tool.value.bg_color)
                }
                onChange={handleChange}
              />
              <input
                className="colour-picker-field__item colour-picker-field__item--text"
                value={tool?.value?.bg_color}
                name='bg_color'
                onChange={handleChange}
              />
            </div>
          </div>}
          <div className="show-on">
            <p>SHOW ON PAGE:</p>
            <div className="">
              <input
                type="number"
                name="page"
                value={tool?.page}
                onChange={handleChange}
              />
              / {totalPages ?? 1}
            </div>
          </div>
        </div>
        {tool?.page > totalPages ? (
          <p className="text-danger text-end">
            Please enter page from 1 to {totalPages}
          </p>
        ) : (
          <p></p>
        )}
      </div>
    </>
  );
};
export default TagSection;
