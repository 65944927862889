import React from "react"
import Button from "../Elements/Button"

const ThanksSection = () => {
    return (
        <div>
            <div className="login-heading">
                <h2 className="color-blue">THANK YOU</h2>
            </div>
            <div className="thankyou-para">
                <div className="mb-4">
                    <p>
                        Thanks for signing up.
                    </p>
                    <p>
                        You're going to love this amazing service.
                    </p>
                </div>
                <div className="mb-4">
                    <p>
                        A confirmation link has been emailed to you.
                    </p>
                </div>
                <div className="mb-4">
                    <p>
                        Check your email, click on the included link,
                    </p>
                    <p>
                        and get ready to put your content to work!
                    </p>
                </div>
                <div className=" text-center mb-4">
                    <Button
                        text={'Ok'}
                        classList={'success'}
                        onClick={() => window.location.reload()}
                    />
                </div>
                <div className="resend-link">
                    <p>
                        Didn’t receive a link?
                    </p>
                    <a href="/users/confirmation">Resend</a>
                </div>
            </div>
        </div>
    )
}
export default ThanksSection