import React, { useEffect, useState } from "react";
import styles from "./Viewer.module.css";
import { networkService } from "../../../config/axios";
import { useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import CustomTooltip from "../../../Elements/Tooltip";
import noImage from "../../../../../assets/images/icon_upload.svg";
import { EditorInfoContext } from "../../../../utis/userInfoContext";
import PDFViewer from "../../Kdf/EditKdf/pdfViewer/Viewer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboard,
  faCookieBite,
  faLocationDot,
  faMinus,
  faPlus,
  faRightToBracket,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../../../Elements/Button";

const Viewer = () => {
  const [viewerData, setViewerData] = useState([]);
  const [showWithTags, setShowWithTags] = useState(false);
  const { hash } = useLocation();
  const {
    tl,
    doc,
    pdfFile: { thumbs_details },
  } = window.objInit;
  const zoomOptions = window?.objZoomOptions;
  const token = tl?.token || doc?.group_id;
  const [selectedPage, setSelectedPage] = useState({ id: 1, index: null });
  const [pdfFile, setPdfFile] = useState(window.objInit.pdfFile);
  const [zoomIndex, setZoomIndex] = useState({
    index: 1,
    value: "0.5",
  });
  const [editEnabled, setEditEnabled] = useState(false);
  const [openAddElement, setOpenAddElement] = useState(false);
  const [toolType, setToolType] = useState("");
  const [rectangles, setRectangles] = useState([]);
  const [activeRectangleIndex, setActiveRectangleIndex] = useState(null);
  const [geo, setGeo] = useState({
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    keep_proportions: true,
    keep_within_page: true,
    page: 1,
  });
  const [editElement, setEditElement] = useState({});
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [elements, setElements] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [lightBoxList, setLightBoxList] = useState([]);
  const [openLightBox, setOpenLightBox] = useState(false);
  const [editLightBox, setEditLightBox] = useState({});
  const [imageElement, setImageElement] = useState({});
  const [selectedPdf, setSelectedPdf] = useState("");
  const [showToast, setShowToast] = useState({
    showToast: false,
    toastType: "success",
    toastMessage: "",
  });
  const [tagValue, setTagValue] = useState(false);
  const [pageDimensions, setPageDimensions] = useState({
    height: "",
    width: "",
  });
  const [sessionIndex, setSessionIndex] = useState(0);

  const handleZoomIn = () => {
    if (zoomIndex.index < 8) {
      setZoomIndex((prev) => ({
        ...prev,
        index: prev?.index + 1,
        value: zoomOptions[Object.keys(zoomOptions)[prev?.index + 1]].value,
      }));
    }
  };

  const handleZoomOut = () => {
    if (zoomIndex.index > 0 && zoomIndex.index < 9) {
      setZoomIndex((prev) => ({
        ...prev,
        index: prev?.index - 1,
        value: zoomOptions[Object.keys(zoomOptions)[prev?.index - 1]].value,
      }));
    }
  };

  const updateReceiverInfo = async () => {
    try {
      let { data, status } = await networkService(
        `/statistics/receivers?receiver=${
          hash.replace("#/viewer/", "").split("&")[0] || ""
        }&token=${token}&type=${tl ? "tl" : "doc"}`,
        "GET"
      );
      if (data?.length > 0) {
        setViewerData(data);
      }
    } catch (error) {
      console.error("error ", error);
    }
  };

  const getLatestRecord = (data) => {
    return data?.reduce((latest, current) => {
      const currentDate = new Date(current.date.replace("IST", "").trim());
      const latestDate = new Date(latest.date.replace("IST", "").trim());
      return currentDate > latestDate ? current : latest;
    }, data[0]);
  };

  const handleErrorImage = (element) => {
    element.target.src = noImage;
  };

  const viewsPercentage = (score, total) => ((score / total) * 100).toFixed(2);

  const afterFilterData = (status, data, tagsArray) => {
    let valueToUpdate = [];
    if (status == true) {
      valueToUpdate = data?.filter(
        (spage) => tagsArray[`${spage?.page}`]?.length == 0
      );
    } else {
      valueToUpdate = data;
    }
    return valueToUpdate || [];
  };

  useEffect(() => {
    updateReceiverInfo();
  }, []);

  return (
    <>
      {viewerData?.map((viewDt, viewInd) => (
        <Container key={`${viewInd}-${viewDt?.optin_session?.id}`}>
          <Col md={8}>
            <Row className="my-2">
              <Col md={6}>
                <div className={styles.headerCard}>
                  <h5>{viewDt?.name || "-"}</h5>
                  <hr />
                  {viewDt?.sessions?.map(
                    (session, idx) =>
                      idx == sessionIndex && (
                        <div key={idx}>
                          <div className={styles.locationInfo}>
                            <CustomTooltip
                              title={
                                <p className="p-1 text-light">
                                  {viewDt?.name || "-"} viewed this KDF on{" "}
                                  {session?.date} via {session?.ip} in{" "}
                                  {session?.country}
                                </p>
                              }
                            >
                              <>
                                <span>
                                  KDF viewed on: {session.date?.split(" ")[0]}
                                </span>
                                <img
                                  src={`${assetsBasePath}/${session?.ip_details?.country_code?.toLowerCase()}.png`}
                                  alt={session?.ip_details?.country_code}
                                  className={styles.flag}
                                />
                              </>
                            </CustomTooltip>
                            <span>
                              {session?.ip_details?.city},{" "}
                              {session?.ip_details?.subdivision_code}
                            </span>
                          </div>
                          <div className={styles.locationInfo}>
                            <CustomTooltip
                              title={
                                <p className="p-1 text-light">
                                  {viewDt.name || "-"} viewed this KDF on{" "}
                                  {getLatestRecord(session?.pages)?.date} via{" "}
                                  {session?.ip} in {session?.country}
                                </p>
                              }
                            >
                              <>
                                <span>
                                  Last seen:{" "}
                                  {
                                    getLatestRecord(
                                      session?.pages
                                    )?.date?.split(" ")[0]
                                  }
                                </span>
                                <img
                                  src={`${assetsBasePath}/${session?.ip_details?.country_code?.toLowerCase()}.png`}
                                  alt={session?.ip_details?.country_code}
                                  className={styles.flag}
                                />
                              </>
                            </CustomTooltip>
                            <span>
                              {session?.ip_details?.city},{" "}
                              {session?.ip_details?.subdivision_code}
                            </span>
                          </div>
                        </div>
                      )
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div className={styles.headerCard}>
                  <Row>
                    <Col>
                      <div className={styles.stat}>
                        <span className={styles.value}>
                          {viewDt?.engagement || "-"}
                        </span>
                        <div>Score</div>
                      </div>
                    </Col>
                    <Col>
                      <div className={styles.stat}>
                        <span className={styles.value}>
                          {viewDt?.percent_viewed || "-"}
                        </span>
                        <div>Viewed</div>
                      </div>
                    </Col>
                    <Col>
                      <div className={styles.stat}>
                        <CustomTooltip
                          title={
                            <p className="p-1 text-light">
                              HH:MM:SS
                              <br />
                              Total time may vary by a few seconds
                            </p>
                          }
                        >
                          <span className={styles.value}>
                            {viewDt?.min_viewed || "-"}
                          </span>
                        </CustomTooltip>
                        <div>Total Time</div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className={styles.stat}>
                        <span className={styles.value}>
                          {viewDt?.sessions?.length}
                        </span>
                        <div>Visit</div>
                      </div>
                    </Col>
                    <Col>
                      <div className={styles.stat}>
                        {viewDt?.pages_by_time_spent?.pages?.map((pg, ind) => (
                          <CustomTooltip
                            key={ind}
                            light={true}
                            title={
                              <div className="bg-whte p-2">
                                <img
                                  src={`${thumbs_details?.normal}${pg - 1}.${
                                    thumbs_details?.extension
                                  }`}
                                  onError={(e) => handleErrorImage(e)}
                                  alt={pg}
                                  width="99px"
                                  height="129px"
                                />
                              </div>
                            }
                          >
                            <span className={`${styles.value} cursor-help`}>
                              {pg}
                            </span>
                          </CustomTooltip>
                        ))}
                        <div>Top Page</div>
                      </div>
                    </Col>
                    <Col>
                      <div className={styles.stat}>
                        {viewDt?.popular_exit_pages?.pages?.map((pg, ind) => (
                          <CustomTooltip
                            key={ind}
                            light={true}
                            title={
                              <div className="bg-whte p-2">
                                <img
                                  src={`${thumbs_details?.normal}${pg - 1}.${
                                    thumbs_details?.extension
                                  }`}
                                  onError={(e) => handleErrorImage(e)}
                                  alt={pg}
                                  width="99px"
                                  height="129px"
                                />
                              </div>
                            }
                          >
                            <span className={`${styles.value} cursor-help`}>
                              {pg}
                            </span>
                          </CustomTooltip>
                        ))}
                        <div>Exit Page</div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
          <Row>
            <Col md={8}>
              <div className="d-flex gap-2">
                {viewDt?.sessions?.map((session, index) => (
                  <span
                    key={index}
                    title="Most recent visits"
                    className={`${styles.statsHeader} ${
                      styles.statsTagSingle
                    } ${sessionIndex == index ? styles.active : ""}`}
                    onClick={() => {
                      setSessionIndex(index);
                    }}
                  >
                    {`${session?.heading_date}`}
                  </span>
                ))}
              </div>
              {viewDt?.sessions?.map(
                (session, idx) =>
                  idx == sessionIndex && (
                    <div key={idx}>
                      <div
                        className={`d-flex border-top align-items-center justify-content-around ${styles.statsTableHeader}`}
                      >
                        <Col xs="auto" className={styles.statItem}>
                          <CustomTooltip
                            title={
                              <p className="p-1 text-light">
                                Total time may vary by a few seconds
                              </p>
                            }
                          >
                            <span className={styles.label}>
                              Total Time: {session?.min_viewed}
                            </span>
                          </CustomTooltip>
                        </Col>
                        <Col xs="auto" className={styles.statItem}>
                          <span className={styles.label}>
                            Viewed: {session?.percent_viewed}
                          </span>
                        </Col>
                        <Col xs="auto" className={styles.statItem}>
                          <CustomTooltip
                            light={true}
                            title={
                              <div className="bg-whte p-2">
                                <img
                                  src={`${thumbs_details?.normal}${
                                    session?.pages_by_time_spent?.pages[0] - 1
                                  }.${thumbs_details?.extension}`}
                                  onError={(e) => handleErrorImage(e)}
                                  alt={session?.pages_by_time_spent?.pages[0]}
                                  width="99px"
                                  height="129px"
                                />
                                <span>
                                  Duration :{" "}
                                  {session?.pages_by_time_spent?.time_spent}
                                </span>
                              </div>
                            }
                          >
                            <span className={`${styles.label} cursor-help`}>
                              Top Page: {session?.pages_by_time_spent?.pages[0]}
                            </span>
                          </CustomTooltip>
                        </Col>
                        <Col xs="auto" className={styles.statItem}>
                          {session?.popular_exit_pages?.map((pg, ind) => (
                            <CustomTooltip
                              key={ind}
                              light={true}
                              title={
                                <div className="bg-whte p-2">
                                  <img
                                    src={`${thumbs_details?.normal}${
                                      pg?.page - 1
                                    }.${thumbs_details?.extension}`}
                                    onError={(e) => handleErrorImage(e)}
                                    alt={pg?.page}
                                    width="99px"
                                    height="129px"
                                  />
                                </div>
                              }
                            >
                              <span className={`${styles.label} cursor-help`}>
                                Exit Page: {pg?.page}
                              </span>
                            </CustomTooltip>
                          ))}
                        </Col>
                        <Col xs="auto" className={styles.statItem}>
                          <CustomTooltip
                            title={
                              <p className="p-1 text-light">
                                (Number of sessions with selected engagement
                                pages over the threshold * Number of engagement
                                pages over the threshold) / (Total number of
                                sessions * Total number of pages)
                              </p>
                            }
                          >
                            <span className={`${styles.label} cursor-help`}>
                              Score: {session?.engagement}
                            </span>
                          </CustomTooltip>
                        </Col>
                        <Col xs="auto" className={styles.statItem}>
                          <CustomTooltip
                            light={true}
                            title={
                              <div className="bg-whte p-2">
                                <a
                                  href={`https://google.com/maps/place/${session?.ip_details?.location?.lat},${viewDt?.optin_session?.ip_details?.location?.long}`}
                                  target="_blank"
                                >
                                  <FontAwesomeIcon icon={faLocationDot} />
                                  <img
                                    src={`${assetsBasePath}/${
                                      session?.ip_details?.country_code?.toLowerCase() ??
                                      "en"
                                    }.png`}
                                    alt={session?.ip_details?.country}
                                    width="22px"
                                    className="mr-1"
                                  />
                                  {session?.ip_details?.city ?? "-"},
                                  {session?.ip_details?.subdivision_code ?? "-"}
                                  ,{session?.ip_details?.country ?? "-"},
                                  {session?.ip_details?.continent_code ?? "-"}
                                </a>
                              </div>
                            }
                          >
                            <p className={`${styles.label} cursor-pointer`}>
                              <FontAwesomeIcon icon={faLocationDot} />
                            </p>
                          </CustomTooltip>
                        </Col>
                        <Col xs="auto" className={styles.icons}>
                          <CustomTooltip
                            title={
                              <p className="p-1 text-light">
                                {session?.browser_info?.browser}
                              </p>
                            }
                          >
                            <img
                              className="d-inline-flex"
                              src={`/browser/${session?.browser_info?.browser_icon?.toLowerCase()}`}
                            />
                          </CustomTooltip>
                          <CustomTooltip
                            title={
                              <p className="p-1 text-light">
                                {session?.browser_info?.os_family}
                              </p>
                            }
                          >
                            <img
                              className="d-inline-flex"
                              src={`/os/${session?.browser_info?.os_icon}`}
                            />
                          </CustomTooltip>
                          <CustomTooltip
                            title={
                              <p className="p-1 text-light">
                                {session?.browser_info?.device}
                              </p>
                            }
                          >
                            <img
                              className="d-inline-flex"
                              src={`/device/${session?.browser_info?.device_icon}`}
                            />
                          </CustomTooltip>
                        </Col>
                      </div>
                      <div className="d-flex">
                        <div className={styles.statsViewerContainer}>
                          <div className="d-flex my-4">
                            <span
                              onClick={() => setShowWithTags((prev) => true)}
                              className="text-link-success text-white tag-primary mx-1"
                            >
                              Show pages without tags
                            </span>
                            <span
                              onClick={() => setShowWithTags((prev) => false)}
                              className="text-link-success text-white tag-primary mx-1"
                            >
                              Show all
                            </span>
                          </div>
                          <table className="w-100">
                            <thead className={styles.statsTableHead}>
                              <tr>
                                <th></th>
                                <th>Page</th>
                                <th>Duration</th>
                                <th>Page Tags</th>
                                <th>Elements Tags</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            {afterFilterData(
                              showWithTags,
                              session?.pages,
                              viewDt?.page_tags
                            )?.map((statePage, statePageIndex) => (
                              <tbody key={statePageIndex}>
                                <tr
                                  onClick={() =>
                                    setSelectedPage({
                                      id: statePage?.page,
                                      index: statePageIndex,
                                    })
                                  }
                                >
                                  <td>
                                    <img
                                      src={`${thumbs_details?.small}${
                                        statePage?.page - 1
                                      }.${thumbs_details?.extension}`}
                                      onError={(e) => handleErrorImage(e)}
                                      width="50px"
                                      height="75px"
                                      alt={statePage?.page}
                                      className={
                                        selectedPage?.id == statePage?.page &&
                                        selectedPage?.index == statePageIndex
                                          ? styles.selectedImage
                                          : ""
                                      }
                                    />
                                  </td>
                                  <td>{statePage?.page}</td>
                                  <td>
                                    <CustomTooltip
                                      title={
                                        <p className="p-1 text-light">
                                          {statePage?.time_tooltip}
                                        </p>
                                      }
                                    >
                                      <span>{statePage?.min_viewed}</span>
                                    </CustomTooltip>
                                  </td>
                                  <td>
                                    {viewDt?.page_tags[
                                      `${statePage?.page}`
                                    ]?.map((tag, tagIndex) => (
                                      <span
                                        key={`${tag}-${tagIndex}`}
                                        className="tag-primary text-white"
                                      >
                                        {tag}
                                      </span>
                                    ))}
                                  </td>
                                  <td>
                                    {statePage?.element_tags?.map(
                                      (pageTag, pageTagIndex) => (
                                        <span
                                          key={`${pageTag}-${pageTagIndex}`}
                                          className="tag-primary text-white"
                                        >
                                          {pageTag}
                                        </span>
                                      )
                                    )}
                                  </td>
                                  <td>
                                    {statePage?.landing_page ==
                                    statePage?.page ? (
                                      <>
                                        <CustomTooltip
                                          title={
                                            <p className="p-1 text-light">
                                              Recipient began viewing on{" "}
                                              {viewDt?.landing_page?.date}
                                            </p>
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={faRightToBracket}
                                          />
                                        </CustomTooltip>
                                        {viewDt?.landing_page?.cookie_status ==
                                        "acceptAll" ? (
                                          <CustomTooltip
                                            title={
                                              <p className="p-1 text-light">
                                                Recipient began viewing on{" "}
                                                {viewDt?.landing_page?.date}
                                              </p>
                                            }
                                          >
                                            <FontAwesomeIcon
                                              icon={faCookieBite}
                                            />
                                          </CustomTooltip>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {session?.is_optin_session == true &&
                                    session?.has_optin == true &&
                                    session?.optin_page == statePage?.page &&
                                    session?.landing_page?.once_optin ==
                                      true ? (
                                      <FontAwesomeIcon
                                        onClick={async () =>
                                          navigator.clipboard.writeText(
                                            viewDt?.email
                                          )
                                        }
                                        icon={faClipboard}
                                        className="cursor-help"
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {session?.has_optin == true &&
                                    session?.optin_page == statePage?.page &&
                                    session?.landing_page?.once_optin ==
                                      true ? (
                                      <CustomTooltip
                                        title={
                                          <p className="p-1 text-light">
                                            OPT-IN: {viewDt?.name}{" "}
                                            {viewDt?.email} {statePage?.date}
                                          </p>
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={faUser}
                                          className="cursor-pointer"
                                        />
                                      </CustomTooltip>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                                <tr
                                  onClick={() =>
                                    setSelectedPage({
                                      id: statePage?.page,
                                      index: statePageIndex,
                                    })
                                  }
                                >
                                  <td colSpan={7} className={styles.progressTd}>
                                    <progress
                                      value={viewsPercentage(
                                        session?.landing_page?.seconds,
                                        session?.sec_viewed
                                      )}
                                      max="100"
                                    ></progress>
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                          </table>
                        </div>
                      </div>
                    </div>
                  )
              )}
            </Col>
            <Col md={4}>
              <input
                className="edit-value-inp ms-1 me-1"
                id="pageNumber"
                value={selectedPage?.id}
                type="hidden"
              />
              <div
                className={`side-duration-img duration-img pdfPreview ${styles.pdfContainer}`}
              >
                <EditorInfoContext.Provider
                  value={{
                    editEnabled: editEnabled,
                    openAddElement: openAddElement,
                    rectangles: rectangles,
                    activeRectangleIndex: activeRectangleIndex,
                    geo: geo,
                    toolType,
                    elements: elements,
                    editElement: editElement,
                    pageNumber: pageNumber,
                    openRemoveModal,
                    editLightBox,
                    lightBoxList,
                    imageElement,
                    openLightBox,
                    selectedPdf,
                    showToast,
                    tagValue,
                    pageDimensions,
                    setTagValue,
                    setShowToast,
                    setSelectedPdf,
                    setOpenLightBox,
                    setImageElement,
                    setLightBoxList,
                    setEditElement,
                    setRectangles,
                    setOpenAddElement,
                    setEditEnabled,
                    setActiveRectangleIndex,
                    setGeo,
                    setToolType,
                    setElements,
                    setOpenRemoveModal,
                    setPageNumber,
                    setEditLightBox,
                    setPageDimensions,
                  }}
                >
                  <PDFViewer
                    zoom={zoomIndex}
                    pageNum={pageNumber}
                    url={`${window.location.origin.toString()}${
                      pdfFile?.file?.url
                    }`}
                    docFileId={pdfFile?.id}
                    docId={tl?.doc?.id || doc?.id}
                    editortype={"viewer"}
                    docFiles={tl?.doc || doc}
                    timerOnDoc={tl?.doc?.timer_on_doc || doc?.timer_on_doc}
                  />
                </EditorInfoContext.Provider>
                <div className={styles.zoomButtons}>
                  <button
                    className={`cursor-pointer ${styles.zoomBtn}`}
                    onClick={handleZoomIn}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                  <button
                    className={`cursor-pointer ${styles.zoomBtn}`}
                    onClick={handleZoomOut}
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </button>
                </div>
                <div className="pagenition mt-3">
                  <p>
                    {selectedPage?.id}/{pdfFile?.num_pages}
                  </p>
                </div>
              </div>
              <div className={`text-center`}>
                <Button
                  text={"Preview"}
                  classList={"outline"}
                  onClick={() =>
                    window.open(
                      `/viewer/${token}/${doc?.language?.locale}`,
                      `viewer-${token}`
                    )
                  }
                />
              </div>
              <CustomTooltip
                title={
                  <p className="p-1 text-light">
                    KFD Title: {viewerData[0].optin_session?.doc_title}
                  </p>
                }
              >
                <p className="custom-link-success cursor-pointer">
                  {viewerData[0].created_time_stamp}
                </p>
              </CustomTooltip>
              <p>Opt-in Occurred</p>
            </Col>
          </Row>
        </Container>
      ))}
    </>
  );
};
export default Viewer;
