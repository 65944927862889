import React, { useEffect, useMemo, useRef, useState } from "react";
import { checkPageVisibility } from "../../../../hooks/utils";
import { getCookie } from "../../../../utis/helper";

const useStatHook = () => {
    const [trakingData, setTrackingData] = useState({})
    const [wsIntegrated, setWsintegrated] = useState(false)
    const [ws, setWs] = useState(null);
    const secondsRef = useRef(1);
    const videoSeconds = useRef(1);
    const [page, setPage] = useState(1);
    const [videoData, setVideoData] = useState({ isPlaying: false })
    const WEB_URL = `wss://staging2.seriousinsight.com`
    // const WEB_URL = 'ws://localhost:3000';

    const message = useMemo(() => {
        const newData = {};
        newData.session_id = trakingData?.sess_id;
        newData.tracking_link_id = trakingData?.tlb?.id;
        newData.doc_id = trakingData?.doc_id;
        newData.docs_file_id = trakingData?.active_files && Object.keys(trakingData?.active_files)[0];
        return newData;
    }, [trakingData])

    const statRes = (strAction, page = 1, docId, videoStatsData) => {
        const page_id = localStorage.getItem('page_id');
        const receiverCookie = getCookie('receiver', {});
        let receiver_id = ""
        if (receiverCookie[trakingData?.tlb?.token] && receiverCookie[trakingData?.tlb.token].id) {
            receiver_id = receiverCookie[trakingData?.tlb.token].id;
        }
        let id = undefined
        if (page_id != null) {
            id = Number(page_id);
        }
        return {
            ...message,
            action: strAction,
            id,
            receiver_id,
            ...(docId && videoData?.isPlaying == false ? { clicked_after: secondsRef?.current ?? 1 } : { seconds_on_page: secondsRef?.current ?? 1 }),
            ...(page && { page: page }),
            ...(docId && { docs_element_id: docId }),
            ...(videoStatsData && { session_page_id: Number(id), watchedPercent: Math.ceil(videoStatsData?.watchedPercent), watchedSeconds: videoStatsData?.watchedSeconds, duration: videoStatsData?.duration })
        }
    }

    // websocket connection
    useEffect(() => {
        setTrackingData(window?.objInit)
        const websocket = new WebSocket(`${WEB_URL}/cable`);
        setWs(websocket)
        websocket.onopen = (e) => {
            console.log("WebSocket connection established.");

            // Send the subscription message
            const subscriptionMessage = JSON.stringify({
                command: "subscribe",
                identifier: JSON.stringify({ channel: "SessionMonitoringChannel", data: { session_id: window?.objInit?.sess_id } })
            });

            websocket.send(subscriptionMessage);
            setWsintegrated(true);
        }

        websocket.onerror = (e) => {
            console.log("Error in websocket: ", e);
        }

        websocket.onmessage = (e) => {
            try {
                const data = JSON.parse(e.data);
                if (data?.message?.id) {
                    localStorage.setItem('page_id', data?.message?.id);
                }
            } catch (error) {

            }
        }

        if (document) {
            setPage(Number(document?.getElementById('pageNumber')?.value));
        }

        window.onload = () => {
            localStorage.removeItem("page_id");
        };

        return () => {
            window.onload = null;
        };
    }, []);

    // function to send the data in ws
    const sendData = (objData, strAction) => {
        const data = {
            command: 'message',
            identifier: JSON.stringify({ channel: "SessionMonitoringChannel", data: { session_id: trakingData?.sess_id } }),
            ...(objData && { data: JSON.stringify({ message: objData, action: strAction }) })
        }
        return ws.send(JSON.stringify(data), strAction);
    }

    const actionCableOptIn = () => {
        sendData(statRes("opt_in_info", Number(document?.getElementById('pageNumber')?.value)), 'opt_in_info')
    }

    // when window is paused it will be sent 
    useEffect(() => {
        const intervalId = setInterval(() => {
            if (wsIntegrated) {
                if (ws?.readyState === WebSocket.OPEN) {
                    secondsRef.current += 1;
                    if (videoData?.isPlaying) {
                        videoSeconds.current += 1;
                        let newWatchedPercent = Number(((videoSeconds?.current / videoData?.duration) * 100).toFixed(2));
                        let videoStats = {
                            watchedPercent: newWatchedPercent,
                            watchedSeconds: videoSeconds?.current,
                            duration: videoData?.duration
                        }
                        sendData(statRes('save_video_info', null, videoData?.id, videoStats), 'save_video_info');
                    } else if (videoData?.isPlaying == false) {
                        if (!checkPageVisibility()) {
                            sendData(statRes('win_pause_info', Number(document?.getElementById('pageNumber')?.value)), 'win_pause_info');
                        } else {
                            sendData(statRes('save_info', Number(document?.getElementById('pageNumber')?.value)), 'save_info');
                        }
                    }
                } else {
                    console.log('WebSocket is not open or is closed');
                }
            }
        }, 2000);
        return () => {
            clearInterval(intervalId);
        };
    }, [wsIntegrated, ws, videoData?.isPlaying]);

    // when page changes this is going to be send 
    const sendScrollInfo = (page) => {
        secondsRef.current = 1;
        localStorage.removeItem('page_id');
    }

    //when element is clicked
    const elemClick = (docId) => {
        if (ws?.readyState === WebSocket.OPEN) {
            sendData(statRes('save_click', null, docId), 'save_click');
        } else {
            console.log('WebSocket is not open or is closed');
        }
    }

    // video stats 
    // const videoStats = (videoData) => {
    //     if (ws?.readyState === WebSocket.OPEN) {
    //         sendData(statRes('save_video_info', null, videoData?.id, videoData), 'save_video_info');
    //     } else {
    //         console.log('WebSocket is not open or is closed');
    //     }
    // }

    return {
        message,
        sendScrollInfo,
        elemClick,
        actionCableOptIn,
        setVideoData
    }

}

export default useStatHook;