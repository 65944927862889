import React from "react";
const PreviewUi = ({ fieldsData }) => {

    return (
        <div className="mt-3 ms-4">
            <div className="preview-custo">
                <h6>
                    <b>Preview</b>
                </h6>
                <div className="pre-cus-p"
                    style={{
                        border: `${fieldsData?.modal_details?.border_weight}px solid ${fieldsData?.modal_details?.border_color}`,
                        background: `${fieldsData?.modal_details?.bg_color}`
                    }}
                >
                    <p className="px-2">
                        {fieldsData?.pp_text}
                        <a href={fieldsData?.link_details?.link_url} className="privacy-link"> {fieldsData?.link_details?.link_text} </a>
                        .
                    </p>
                    <div className="button-group">
                        <button
                            className="btn"
                            style={{
                                border: `2px solid ${fieldsData?.value?.btn1_border_color}`,
                                background: `${fieldsData?.value?.btn1_bg_color}`,
                                color: `${fieldsData?.value?.btn1_text_color}`
                            }}
                        >
                            Accept essential cookies
                        </button>
                        <button
                            className="btn"
                            style={{
                                border: `2px solid ${fieldsData?.value?.btn2_border_color}`,
                                background: `${fieldsData?.value?.btn2_bg_color}`,
                                color: `${fieldsData?.value?.btn2_text_color}`
                            }}
                        >Accept all cookies
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PreviewUi