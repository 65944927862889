import React, { useEffect, useRef, useState } from "react";
import {
  faCaretLeft,
  faCaretRight,
  faCircleCheck,
  faCircleInfo,
  faCircleMinus,
  faCirclePlus,
  faDownload,
  faEllipsisVertical,
  faLessThan,
  faMagnifyingGlass,
  faPencil,
  faRightFromBracket,
  faShareFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LanguageDropdown from "../../../../CommonComponents/LanguageDropdown/LanguageDropdown";
import CustomTooltip from "../../../../Elements/Tooltip";
import portrait from "../../../../../../assets/images/portrait.png";
import landscape from "../../../../../../assets/images/landscape.png";
import { networkService } from "../../../../config/axios";
import { NavDropdown } from "react-bootstrap";
import { dispatchEvent, handleClearCookie, scrollPage } from "../../../../../utis/helper";
import Button from "../../../../Elements/Button";

const EditorHeader = ({
  pdfFile,
  setOpenSideSection,
  openSideSection,
  setZoomIndex,
  zoomIndex,
  pageNumber,
  setPageNumber,
  docFiles,
  setSelectedPdf,
  title,
  downloadStatus,
  selectedPdf,
  tlb,
  editorStatus,
  setLaserLINKEditStatus,
  showToolbar,
  showEditBtn,
  currentUserId
}) => {
  const [langName, setLangName] = useState({
    img: "",
    name: "",
    class: "flag-icon flag-icon-us",
    groupId: "",
  });
  const [searchValue, setSearchValue] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [isPdfPublished, setIsPdfPublished] = useState(false)
  const [userProfileData, setUserProfileData] = useState({})
  const pageNumberRef = useRef(null);

  const zoomingOptions = window.objInit?.zoomOptions;
  const arrayOfElements = Object.values(
    Object.entries(docFiles).length !== 0 && docFiles
  );
  const selectedLanguage = arrayOfElements?.find(
    (elem) => elem.id == pdfFile?.id
  );

  const handleZoom = (option) => {
    if (option === "in" && zoomIndex.index < 8) {
      setZoomIndex({
        ...zoomIndex,
        index: zoomIndex?.index + 1,
        value:
          zoomingOptions[Object.keys(zoomingOptions)[zoomIndex?.index + 1]]
            .value,
      });
    } else if (option === "out" && zoomIndex.index > 0 && zoomIndex.index < 9) {
      setZoomIndex({
        ...zoomIndex,
        index: zoomIndex?.index - 1,
        value:
          zoomingOptions[Object.keys(zoomingOptions)[zoomIndex?.index - 1]]
            .value,
      });
    } else if (option == "landscape") {
      setZoomIndex({
        ...zoomIndex,
        index: 6,
        value: "2",
      });
    } else if (option == "portrait") {
      setZoomIndex({
        ...zoomIndex,
        index: 2,
        value: "0.75",
      });
    }
  };

  const handleSelect = (value) => {
    setLangName({
      img: value?.language?.locale,
      class: value?.language?.class,
      name: value?.language?.name,
      groupId: value?.language?.group_id,
    });
    setSelectedPdf(value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      const num = parseInt(e.target.value, 10);
      dispatchEvent("change", "pageNumber");
      setPageNumber(num);
      scrollPage(num, "instant");
      e.preventDefault();
    }
  };

  const handlesearchKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      window?.find(searchValue);
    }
  };

  const updateInputRefEvent = (valueToUpdate) => {
    if (pageNumberRef && pageNumberRef.current) {
      setTimeout(() => {
        pageNumberRef.current.value = valueToUpdate || 1;
      }, 400);
    }
  };

  useEffect(() => {
    updateInputRefEvent(pageNumber);
  }, [pageNumber]);

  useEffect(() => {
    setLangName({
      ...langName,
      groupId: docFiles[0]?.group_id,
    });
  }, [docFiles]);

  useEffect(() => {
    setIsPdfPublished(window.objInit && window.objInit.live_files_count &&
      window.objInit.live_files_count[selectedPdf?.language?.locale ?? pdfFile?.language] > 0)
  }, [selectedPdf])

  useEffect(() => {
    const fetchUserData = async () => {
      const response = await networkService(`profiles/${currentUserId}.json`, "GET");
      setUserProfileData(response?.data)
    };
    if (!editorStatus) {
      setOpenSideSection(tlb?.cyoa_enabled != true)
    }
    if (currentUserId) {
      fetchUserData()
    }
  }, [])

  return (
    <header className="header-editor" style={{ display: showToolbar === false ? 'none' : '' }}>
      <div className={pdfFile?.num_pages > 1 ? "h-first" : ''}>
        <div className="d-flex align-items-center">
          {editorStatus && (
            <div>
              <button
                className="back-button"
                onClick={() => (window.location = "/kdfs")}
              >
                <FontAwesomeIcon className="me-1" icon={faLessThan} />
                KDFs
              </button>
            </div>
          )}
          <>
            <CustomTooltip title={<p className="p-1 text-light">Toggle Sidebar</p>} placement={"bottom"}>
              <div
                className="ms-2 three-dots"
                onClick={() => setOpenSideSection(!openSideSection)}
              >
                <FontAwesomeIcon icon={faEllipsisVertical} className="fs-4" />
              </div>
            </CustomTooltip>
            <div
              className={`${pdfFile?.num_pages > 1
                ? "d-flex align-items-center ms-3 editor-inp me-3"
                : "d-none"
                }`}
            >
              <input
                type="number"
                ref={pageNumberRef}
                className="edit-value-inp ms-1 me-1"
                id="pageNumber"
                min={1}
                max={pdfFile?.num_pages}
                onKeyDown={handleKeyDown}
              />
              <p className="text-light">/ {pdfFile?.num_pages}</p>
            </div>
          </>
        </div>
      </div>

      <div className="title edit-middle-h d-flex align-items-center  px-1">
        {tlb?.cyoa_enabled != true && (
          <div className="h-first-search align-items-center">
            <FontAwesomeIcon
              onClick={() => setShowSearch(!showSearch)}
              icon={faMagnifyingGlass}
              className="ms-2 cursor-pointer"
            />
            {showSearch && (
              <>
                <FontAwesomeIcon icon={faCircleCheck} className="ms-2" />
                <input
                  className="h-first-inp ms-2 me-2 text-light"
                  placeholder="Search..."
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target?.value)}
                  onKeyDown={handlesearchKeyDown}
                />
                <button
                  onClick={() => window.find(searchValue, false, true)}
                  className="icon-button"
                >
                  <FontAwesomeIcon
                    icon={faCaretLeft}
                    className="fs-4 cursor-pointer text-light"
                  />
                </button>
                <button
                  className="icon-button"
                  onClick={() => window.find(searchValue)}
                >
                  <FontAwesomeIcon
                    icon={faCaretRight}
                    className="fs-4 cursor-pointer text-light"
                  />
                </button>
              </>
            )}
          </div>
        )}
        {showEditBtn &&
          <Button
            text={<>Edit KDF
              <FontAwesomeIcon
                icon={faPencil}
                className="success ms-1"
              /></>}
            classList={'success'}
            addonClass={'ms-2'}
            onClick={() => (window.location = `/editor/${pdfFile?.doc_id}`)}
          />
        }
        <p className="fs-4 text-ellipsis text-light m-auto">
          {title?.substring(0, 40)} {title.length > 40 ? "..." : ""}
        </p>
        {editorStatus && isPdfPublished && (
          <div className="mt-2">
            <CustomTooltip title={<p className="p-1 text-light">Share</p>} placement={"bottom"}>
              <FontAwesomeIcon
                className="fs-5 cursor-pointer"
                icon={faShareFromSquare}
                onClick={() => setLaserLINKEditStatus(true)} />
            </CustomTooltip>
          </div>
        )}
      </div>
      <div className="edior-last-el">
        <div className="icons-editor">
          <CustomTooltip title={<p className="p-1 text-light">Zoom In</p>} placement={"bottom"}>
            <FontAwesomeIcon
              icon={faCirclePlus}
              className="fs-4 me-2 cursor-pointer"
              onClick={() => handleZoom("in")}
              id="zoomIn"
            />
          </CustomTooltip>
          <CustomTooltip title={<p className="p-1 text-light">Zoom Out</p>} placement={"bottom"}>
            <FontAwesomeIcon
              icon={faCircleMinus}
              onClick={() => handleZoom("out")}
              className="fs-4 me-2 cursor-pointer"
              id="zoomOut"
            />
          </CustomTooltip>
          <CustomTooltip title={<p className="p-1 text-light">Full height</p>} placement={"bottom"}>
            <img
              src={portrait}
              alt="portrait"
              height="35px"
              width="30px"
              onClick={() => handleZoom("portrait")}
              className="me-2 cursor-pointer"
            />
          </CustomTooltip>
          <CustomTooltip title={<p className="p-1 text-light">Full width</p>} placement={"bottom"}>
            <img
              src={landscape}
              alt="landscape"
              height="30px"
              width="35px"
              onClick={() => handleZoom("landscape")}
              className="me-2 cursor-pointer"
            />
          </CustomTooltip>
          <NavDropdown
            title={
              <FontAwesomeIcon
                className="fs-4 cursor-pointer"
                icon={faCircleInfo}
              />
            }
            id="navbarScrollingDropdown"
            className="px-0 header-info-icons mt-1"
          >
            <NavDropdown.Item className="profile-drop">
              <div className="text-dark">
                KDF:{" "}
                <span
                  onClick={() =>
                    (window.location = `/kdfs/token/${langName?.groupId}`)
                  }
                >
                  {title}
                </span>
              </div>
            </NavDropdown.Item>
            <NavDropdown.Item
              className="profile-drop"
              download={selectedLanguage?.title ?? title}
              href={selectedPdf?.file?.url ?? selectedPdf?.url}
              id="download-pdf"
            >
              <div className="text-dark">
                PDF source:
                <span className="ms-1">{selectedLanguage?.title}</span>
              </div>
            </NavDropdown.Item>
          </NavDropdown>
        </div>
        <div className="ms-2 icons-editor me-2">
          <LanguageDropdown
            arrayOfElements={arrayOfElements}
            name={langName}
            handleSelect={handleSelect}
            selectedLanguage={selectedLanguage?.language}
          />
          {downloadStatus && (
            <a
              href={selectedPdf?.file?.url ?? selectedPdf?.url}
              download={docFiles?.title ?? title}
              id="download-pdf"
            >
              <FontAwesomeIcon icon={faDownload} className="ms-2" />
            </a>
          )}
        </div>
        {editorStatus && <NavDropdown
          title={
            <>
              <img className="user-imgs" src={`https:${userProfileData?.image}`} alt="" />
              <span className="mx-3 user-name-header"></span>
            </>
          }
          id="navbarScrollingDropdown"
          className="header-info-iconss"
        >
          <NavDropdown.Item className="profile-drop" href="/customizations">
            <FontAwesomeIcon icon={faCircleInfo} className="" /> Customizations
          </NavDropdown.Item>
          <NavDropdown.Item
            className="profile-drop"
            onClick={handleClearCookie}
          >
            <FontAwesomeIcon
              icon={faRightFromBracket}
              className="active-icon"
            />
            Logout
          </NavDropdown.Item>
        </NavDropdown>}
      </div>
    </header >
  );
};

export default EditorHeader;
