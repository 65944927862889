import React, { useEffect } from "react";
import flip_icon from "../../../../../../../../assets/images/flip_icon.svg";
import CustomInput from "../../../../../../CommonComponents/CustomForm/CustomInputField";

const RotateSection = ({ tool, setTool }) => {
  const handleRotation = (value, type) => {
    if (type === "rotate") {
      setTool({
        ...tool,
        rotate: value ?? tool?.rotate,
      });
    } else {
      setTool({
        ...tool,
        flip: tool?.flip === 1 ? -1 : 1,
      });
    }
  };

  useEffect(() => {
    if (tool.rotate > 360) {
      handleRotation(0, "rotate")
    }
  }, [tool.rotate])

  return (
    <>
      <div className="transparency-section-flip row">
        <div className="col-6">
          <p>ROTATE AND FLIP</p>
        </div>
        <div className="col-6 d-flex rotate-flip-icon">
          <div className="d-flex align-items-center">
            <p>Rotate:</p>
            <CustomInput
              placeholder={""}
              value={tool?.rotate}
              name={"rotate"}
              onChange={(e) => handleRotation(e.target.value, "rotate")}
              required={true}
              type={"number"}
              min={1}
              max={360}
              onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
            /> <span className="fs-3">&deg;</span>
          </div>
          <div className="d-flex align-items-center">
            <p>Flip:</p>
            <img
              src={flip_icon}
              alt="tenor logo"
              height="20px"
              onClick={() => handleRotation(-1, "flip")}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default RotateSection;