import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import Giphy_logo from "../../../../../../../../assets/images/Giphy logo.png";
import tenor_logo from "../../../../../../../../assets/images/tenor_logo.svg";
import GifSection from "./gifSections/GifSection";
import Button from "../../../../../../Elements/Button";
import TenorSection from "./gifSections/TenorSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCirclePlus } from "@fortawesome/free-solid-svg-icons";

const ImageSection = ({
  tool,
  handleSelectGifs,
  handleImageUpload,
  uploadElement,
  editElement,
  errorState,
}) => {
  const getContentType = (media) => media?.substring(6)?.toUpperCase();
  const getContentSize = (media) => (media / 1024)?.toFixed(2);

  //image tab content
  const ImageTab = () => {
    return (
      <>
        {
          !tool?.file && !editElement?.file_url ?
            <div className="upload-file-btn select-img-pop">
              <div className="input-select-wrap position-relative ">
                <input
                  type="file"
                  id="file-input"
                  accept="image/*"
                  onChange={(e) => handleImageUpload(e, "upload")}
                />
                <FontAwesomeIcon icon={faFileCirclePlus} className="me-2" />
                Select File
              </div>
            </div> :
            <div className="file-data-main d-flex justify-content-between">
              <div>
                <div className="file-data">
                  <img
                    src={tool?.file ? tool?.file && URL.createObjectURL(tool?.file) : editElement?.file_url}
                    style={{ opacity: `${tool?.transparency ?? 10}` }}
                  />
                </div>
                <div className="file-info">
                  <p>File Name : <span>{tool?.file?.name ?? editElement?.file_details?.name}</span></p>
                  <div className="d-flex gap-3">
                    <p>Kind : <span>{getContentType(tool?.file?.type ?? editElement?.file_details?.content_type)} image</span></p>
                    <p>Size : <span>{getContentSize(tool?.file?.size ?? editElement?.file_details?.file_size)} KB</span></p>
                  </div>
                </div>
              </div>
              {
                !editElement?.file_url ? <div className="me-3 d-flex flex-column gap-2">
                  <Button
                    text={<><i className="fa-solid fa-upload me-1" />Upload</>}
                    classList={'success'}
                    addonClass={'d-block w-100'}
                    type='submit'
                    onClick={(e) => uploadElement(e, null, 'image')}
                  />
                  <Button
                    text={<><i className="fa-regular fa-circle-xmark"></i> Cancel</>}
                    classList={'alert'}
                    onClick={(e) => {
                      handleImageUpload(e, 'cancel')
                    }}
                  >
                  </Button>
                </div>
                  : <div className="upload-file-btn select-img-pop">
                    <div className="input-select-wrap replace-file-btn position-relative ">
                      <input
                        type="file"
                        id="file-input"
                        accept="image/*"
                        onChange={(e) => handleImageUpload(e, "upload")}
                      />
                      <FontAwesomeIcon icon={faFileCirclePlus} className="me-2" />
                      Replace File
                    </div>
                  </div>
              }
            </div>
        }
        <p id="file-name-display" className="file-name-display"></p>
      </>
    )
  }

  return (
    <>
      <Tabs
        defaultActiveKey={
          editElement.gif_type === "sticker"
            ? "gifs"
            : editElement.gif_type === "tenor"
              ? "tenor"
              : "image"
        }
        id="uncontrolled-tab-example"
        className="mt-1 px-2 select-file-pop"
      >
        <Tab eventKey="image" title={"Local Files"}>
          <ImageTab />
        </Tab>
        <Tab
          eventKey="gifs"
          title={
            <img src={Giphy_logo} alt="Giphy logo" height="13px" width="63px" />
          }
        >
          <GifSection tool={tool} handleSelectGifs={handleSelectGifs} />
        </Tab>
        <Tab
          eventKey="tenor"
          title={
            <img src={tenor_logo} alt="tenor logo" height="14px" width="54px" />
          }
        >
          <TenorSection tool={tool} handleSelectGifs={handleSelectGifs} />
        </Tab>
      </Tabs>
      <p className="text-danger">{errorState?.imgErr}</p>
    </>
  );
};
export default ImageSection;
