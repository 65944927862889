import React, { useContext, useState } from "react"
import { EditorInfoContext } from "../../../../../utis/userInfoContext";
import useCanvasHook from "../editorHook/useCanvasHook";

const PdfCanvas = ({ canvasRefs, index }) => {
    const { draw, createDiv } = useCanvasHook();
    const {
        editEnabled,
        setOpenAddElement,
        rectangles,
        geo,
        setRectangles,
        setActiveRectangleIndex,
        setGeo,
        setPageDimensions
    } = useContext(EditorInfoContext);
    const [isDrawing, setIsDrawing] = useState(false);
    const [startCoords, setStartCoords] = useState(null);

    const handleMouseDown = (event, index) => {
        const rect = canvasRefs.current[index].getBoundingClientRect();

        setIsDrawing(true);
        const startCoords = {
            x: event.clientX - rect.left,
            y: event.clientY - rect.top,
        };
        setStartCoords(startCoords);

        const newRectangles = [...rectangles];
        newRectangles[index] = [
            ...newRectangles[index],
            { startCoords, currentCoords: startCoords, media: null },
        ];

        setRectangles(newRectangles);
        setActiveRectangleIndex(newRectangles[index].length - 1);
    };

    const handleMouseMove = (event, index) => {
        if (!isDrawing) return;

        const rect = canvasRefs.current[index].getBoundingClientRect();
        const currentX = event.clientX - rect.left;
        const currentY = event.clientY - rect.top;

        const newRectangles = [...rectangles];
        const currentRectangles = newRectangles[index];
        const lastRectangle = currentRectangles[currentRectangles.length - 1];

        lastRectangle.currentCoords = { x: currentX, y: currentY };
        setRectangles(newRectangles);

        draw(index, canvasRefs);
    };

    const handleMouseUp = (e, index) => {
        if (!isDrawing) return;
        setIsDrawing(false);
        setOpenAddElement(true);

        rectangles?.filter(
            (elem, index) =>
                elem?.length > 0 &&
                elem?.map((element, idx) => {
                    setGeo({
                        ...geo,
                        left: element?.startCoords?.x,
                        top: element?.startCoords?.y,
                        width: element?.currentCoords?.x - element?.startCoords?.x,
                        height: element?.currentCoords?.y - element?.startCoords?.y,
                        page: index + 1,
                    });
                })
        );

        const canvas = canvasRefs?.current[index];
        const rect = canvas.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        const width = x - startCoords.x;
        const height = y - startCoords.y;

        const newRect = { x: startCoords.x, y: startCoords.y, width, height };
        createDiv(newRect, index, canvasRefs);

        // Clear the canvas
        const context = canvas.getContext("2d");
        context.clearRect(0, 0, canvas.width, canvas.height);
    };

    return (
        <canvas
            ref={(el) => (canvasRefs.current[index] = el)}
            id={index + 1}
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 2,
                pointerEvents: "auto",
            }}
            onMouseDown={(e) => {
                editEnabled &&
                    (handleMouseDown(e, index),
                        setActiveRectangleIndex({
                            pageIndex: index,
                            rectIndex: rectangles[index].length,
                        }));
                setPageDimensions({
                    width: canvasRefs?.current[0]?.width,
                    height: canvasRefs?.current[0]?.height
                })
            }}
            onMouseMove={(e) => editEnabled && handleMouseMove(e, index)}
            onMouseUp={(e) => editEnabled && handleMouseUp(e, index)}
        />
    )
}
export default PdfCanvas