// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".StatsTableView-module__tableContainer___3nupT {\n    margin-top: 20px;\n    /* padding: 10px; */\n  }\n  \n  .StatsTableView-module__customTable___2vU_l {\n    font-size: 14px;\n    text-align: center;\n    border: 1px solid #ccc;\n    background-color: #f9f9f9;\n  }\n  \n  .StatsTableView-module__customTable___2vU_l th {\n    background-color: #f1f1f1;\n    color: #333;\n    font-weight: bold;\n  }\n  \n  .StatsTableView-module__customTable___2vU_l tbody tr:nth-child(even) {\n    background-color: #fdfdfd;\n  }\n  \n  .StatsTableView-module__customTable___2vU_l tbody tr:nth-child(odd) {\n    background-color: #f7f7f7;\n  }\n  \n  .StatsTableView-module__customTable___2vU_l td {\n    vertical-align: middle;\n  }\n  ", "",{"version":3,"sources":["StatsTableView.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,kBAAkB;IAClB,sBAAsB;IACtB,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,WAAW;IACX,iBAAiB;EACnB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,sBAAsB;EACxB","file":"StatsTableView.module.css","sourcesContent":[".tableContainer {\n    margin-top: 20px;\n    /* padding: 10px; */\n  }\n  \n  .customTable {\n    font-size: 14px;\n    text-align: center;\n    border: 1px solid #ccc;\n    background-color: #f9f9f9;\n  }\n  \n  .customTable th {\n    background-color: #f1f1f1;\n    color: #333;\n    font-weight: bold;\n  }\n  \n  .customTable tbody tr:nth-child(even) {\n    background-color: #fdfdfd;\n  }\n  \n  .customTable tbody tr:nth-child(odd) {\n    background-color: #f7f7f7;\n  }\n  \n  .customTable td {\n    vertical-align: middle;\n  }\n  "]}]);
// Exports
exports.locals = {
	"tableContainer": "StatsTableView-module__tableContainer___3nupT",
	"customTable": "StatsTableView-module__customTable___2vU_l"
};
module.exports = exports;
