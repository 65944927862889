import React from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const Column3DChart = ({ data, name }) => {

  const columnChartOptions = {
    chart: {
      type: 'column',
      options3d: {
        enabled: true,
        alpha: 15, // Tilt
        beta: 15,  // Rotation
        depth: 50, // Adds depth
        viewDistance: 25, // Distance from the viewer
      },
    },
    title: {
      text: '',
    },
    tooltip: {
      pointFormat: '{point.y} Visits',
    },
    plotOptions: {
      column: {
        depth: 25,
      },
    },
    xAxis: {
      categories: data?.map((item, idx) => item?.[name] ?? `visit ${idx + 1}`),
      title: { text: name },
    },
    yAxis: {
      title: { text: name },
    },
    legend: {
      enabled: false, // This hides the legend entirely
    },
    series: [
      {
        name: name,
        data: data?.map(item => item?.hits),
        colorByPoint: true,
      },
    ],
  };

  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={columnChartOptions} />
    </>
  )
}
export default Column3DChart