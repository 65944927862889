import React, { useState } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import styles from "./StatsFilter.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import CustomTooltip from "../../Elements/Tooltip";
import CustomInput from "../../CommonComponents/CustomForm/CustomInputField";
import { networkService } from "../../config/axios";

const sortingOptions = [
  { key: "first_last_asc", label: "First name, Last name", icon: faArrowUp },
  { key: "first_last_desc", label: "First name, Last name", icon: faArrowDown },
  { key: "last_first_asc", label: "Last name, First name", icon: faArrowUp },
  { key: "last_first_desc", label: "Last name, First name", icon: faArrowDown },
  { key: "email_asc", label: "Email", icon: faArrowUp },
  { key: "email_desc", label: "Email", icon: faArrowDown },
  { key: "percent_asc", label: "Percent viewed", icon: faArrowUp },
  { key: "percent_desc", label: "Percent viewed", icon: faArrowDown },
  { key: "viewed_time_asc", label: "Time Viewed", icon: faArrowUp },
  { key: "viewed_time_desc", label: "Time Viewed", icon: faArrowDown },
];

const StatsFilters = ({
  threshold,
  onFilterChange,
  sorting = false,
  defaultDirection,
  token
}) => {
  const { pathname, hash } = useLocation();
  const [filters, setFilters] = useState({
    email: "",
    location: "",
    seen_pages: "",
    seen_mins: "",
    on_page: "",
    from_date: "",
    to_date: "",
    direction: defaultDirection,
    recipient: "",
  });
  const [openEngField, setOpenEngField] = useState(false)

  const handleEngagement = async (value) => {
    const response = await networkService(`kdf-stats-filter?parent_type=doc&receiver=${token}&threshold=${value}&token=${token}&type=stats_sessions`, "GET");
  }


  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  // Handle Apply Filters
  const handleApplyFilters = () => {
    if (onFilterChange) {
      onFilterChange(filters); // Notify parent with updated filters
    }
  };

  // Handle Reset Filters
  const handleResetFilters = () => {
    const defaultFilters = {
      email: "",
      location: "",
      seen_pages: "",
      seen_mins: "",
      from_date: "",
      to_date: "",
      direction: defaultDirection,
      recipient: "",
    };
    setFilters(defaultFilters); // Reset state
    if (onFilterChange) {
      onFilterChange(defaultFilters, "reset"); // Notify parent with reset filters
    }
  };

  const selectedOption = sortingOptions.find(
    (option) => option.key === filters.direction
  );

  return (
    <Form className={`${styles.filterForm} p-3`}>
      <Row className={`align-items-center`}>
        <Col md={3} className={styles.borderRight}>
          <Form.Group>
            <Form.Control
              className={styles.customInput}
              type="email"
              name="email"
              placeholder="Email"
              value={filters.email}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group className="mt-2">
            <Form.Control
              className={styles.customInput}
              type="text"
              name="location"
              placeholder="Location: Country, City, State, Town"
              value={filters.location}
              onChange={handleInputChange}
            />
          </Form.Group>
          <div className="mt-3">
            <Button
              className={`${styles.customBtn} me-2`}
              onClick={handleApplyFilters}
            >
              Apply Filters
            </Button>
            <Button
              className={`${styles.customBtn} ${styles.secondary}`}
              onClick={handleResetFilters}
            >
              Reset Filters
            </Button>
          </div>
        </Col>

        <Col md={3} className={`${styles.borderRight}`}>
          <div className="d-flex align-items-center">
            <span className={styles.labelText}>At least</span>
            <Form.Control
              className={`${styles.customInput} me-2`}
              type="number"
              name="seen_pages"
              placeholder=""
              value={filters.seen_pages}
              onChange={handleInputChange}
            />
            <span className={styles.labelText}>pages viewed</span>
          </div>
          <div className="d-flex align-items-center">
            <span className={styles.labelText}>At least</span>
            <Form.Control
              className={`${styles.customInput} me-2`}
              type="number"
              name="seen_mins"
              placeholder=""
              value={filters.seen_mins}
              onChange={handleInputChange}
            />
            <span className={styles.labelText}>Mins</span>
            <Form.Control
              className={`${styles.customInput} me-2`}
              type="number"
              name="on_page"
              placeholder=""
              value={filters.on_page}
              onChange={handleInputChange}
            />
          </div>
        </Col>

        <Col md={2} className={styles.borderRight}>
          <Form.Group className="mb-2">
            <label>From Date</label>
            <Form.Control
              className={`${styles.customInput} w-100`}
              type="date"
              name="from_date"
              value={filters.from_date}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group>
            <label>To Date</label>
            <Form.Control
              className={`${styles.customInput} w-100`}
              type="date"
              name="to_date"
              value={filters.to_date}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>

        <Col md={3}>
          <Form.Group>
            {sorting ? (
              <DropdownButton
                className={styles.directionDropDown}
                id="dropdown-basic-button"
                title={
                  selectedOption ? (
                    <>
                      <FontAwesomeIcon
                        icon={selectedOption.icon}
                        className="me-2"
                      />
                      {selectedOption.label}
                    </>
                  ) : (
                    "Select Sorting"
                  )
                }
                onSelect={(eKey) =>
                  handleInputChange({
                    target: { name: "direction", value: eKey },
                  })
                }
                variant="secondary"
              >
                {sortingOptions.map((option) => (
                  <Dropdown.Item key={option.key} eventKey={option.key}>
                    <FontAwesomeIcon icon={option.icon} className="me-2" />
                    {option.label}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            ) : (
              <Form.Control
                className={styles.customInput}
                type="text"
                name="recipient"
                placeholder="Recipient Name"
                value={filters.recipient}
                onChange={handleInputChange}
              />
            )}
          </Form.Group>
          <div className="mt-3">
            <>
              <CustomTooltip
                title={
                  <p className="p-1 text-light">Display recipients who actively viewed this KDF for at least this amount of time per page.</p>
                }
              >
                <span className={`${styles.labelText} mr-1`}>
                  Engagement Threshold:
                </span>
              </CustomTooltip>
              <CustomTooltip
                title={
                  <p className="p-1 text-light">{hash.includes("/views")
                    ? "Click on the number to adjust the ET for this Link."
                    : "Display recipients who actively viewed this KDF for at least this amount of time per page."}</p>
                }
              >
                {
                  !openEngField ?
                    <span onClick={() => setOpenEngField(!openEngField)}> {threshold || "-"} seconds{" "}</span> :
                    <CustomInput
                      placeholder={""}
                      name={"engagement"}
                      onChange={(e) => {
                        handleEngagement(e.target.value)
                      }}
                      type={"number"}
                      onBlur={() => setOpenEngField(!openEngField)}
                    />
                }
              </CustomTooltip>
            </>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default StatsFilters;
