import React, { useContext, useEffect, useState } from "react";
import CustomInput from "../../../CommonComponents/CustomForm/CustomInputField";
import { csrfToken } from "../../../../utis/csrfTokenUtils";
import { networkService } from "../../../config/axios";
import Button from "../../../Elements/Button";
import CustomForm from "../../../CommonComponents/CustomForm/CustomForm";
import UserInfoContext from "../../../../utis/userInfoContext";

const AddPaymentSection = ({ setShow, companyId, paymentData }) => {
  const { setShowNotifications } = useContext(UserInfoContext);

  const initialState = {
    amount: "",
    currency: "",
    start_date: "",
    end_date: "",
  };

  const [payment, setPayment] = useState(initialState)
  const [error, setError] = useState({})
  const today = new Date().toISOString().split('T')[0];

  const handleChange = ({ target: { name, value, type } }) => {
    if (type === "date") {
      let formattedDate = new Date(value);
      value = formattedDate.toISOString();
    }
    setError({
      [name]: "",
    });
    setPayment({
      ...payment,
      [name]: value,
    });
  };

  const handleValidations = (payment) => {
    let hasError = false;
    const newErrors = {};

    if (!payment?.amount) {
      newErrors.amount = "Please add the amount";
      hasError = true;
    }
    if (!payment?.currency) {
      newErrors.currency = "Please add the currency";
      hasError = true;
    }
    if (!payment?.start_date) {
      newErrors.start_date = 'Please enter the start date';
      hasError = true;
    } else if (payment?.start_date < today) {
      hasError = true;
      newErrors.start_date = 'please select a valid date'
    }
    if (!payment?.end_date) {
      newErrors.end_date = 'Please enter the end date';
      hasError = true;
    } else if (payment?.end_date < today) {
      hasError = true;
      newErrors.end_date = 'please select a valid date'
    }
    if (hasError) {
      setError({ ...error, ...newErrors });
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (handleValidations(payment)) {
      const data = {
        authenticity_token: csrfToken(),
        ...(payment?.id && { id: payment?.id }),
        payment: {
          amount: payment?.amount,
          currency: payment?.currency,
          start_date: payment?.start_date,
          end_date: payment?.end_date,
          company_id: companyId,
        },
      };
      const url = payment?.id
        ? `admin/company_payments/${payment?.id}.json`
        : "admin/company_payments.json";
      await networkService(url, payment?.id ? "PATCH" : "POST", data)
        .then((response) => {
          if (response?.status === 201 || response?.status == 200) {
            setShow("list");
            setShowNotifications({
              showToast: true,
              toastType: "success",
              toastMessage: `Payment ${payment?.id ? "updated" : "added"}`,
            });
            setPayment(initialState);
          }
        })
        .catch((error) => {
          setShowNotifications({
            showToast: true,
            toastType: 'danger',
            toastMessage: error?.response?.data?.errors ?? "Something went wrong"
          })
        });
    }
  };

  useEffect(() => {
    if (paymentData?.id) {
      setPayment(paymentData);
    } else {
      setPayment(initialState);
    }
  }, [paymentData]);

  return (
    <CustomForm className="org-form-pop">
      <div className="amount-currencywrap mt-3 row">
        <div className="col-sm-6">
          <div className="amnwrap">
            <label className="ms-1">Amount</label>
            <CustomInput
              placeholder={"Amount"}
              value={payment?.amount}
              name={"amount"}
              onChange={handleChange}
              required={true}
              type={'number'}
              onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
              error={error?.amount}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="amnwrap">
            <label className="ms-1">Currency</label>
            <CustomInput
              placeholder={"Currency"}
              value={payment?.currency}
              name={"currency"}
              onChange={handleChange}
              required={true}
              type={'text'}
              error={error?.currency}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="amn-inputwrap">
            <label className="ms-1">From Date</label>
            <CustomInput
              placeholder={"From Date"}
              value={
                payment?.start_date
                  ? payment?.start_date?.split("T")[0].split(" ")[0]
                  : ""
              }
              name={"start_date"}
              onChange={handleChange}
              required={true}
              type={'date'}
              min={today}
              error={error?.start_date}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="amn-inputwrap">
            <label className="ms-1">To Date</label>
            <CustomInput
              placeholder={"To Date"}
              value={
                payment?.end_date
                  ? payment?.end_date?.split("T")[0].split(" ")[0]
                  : ""
              }
              name={"end_date"}
              onChange={handleChange}
              required={true}
              type={'date'}
              min={today}
              error={error?.end_date}
            />
          </div>
        </div>
      </div>
      <div className="manage-btn mt-3 text-center mb-3 d-flex">
        <Button
          classList="alert"
          text="Cancel"
          onClick={() => {
            setShow("list");
            setPayment(initialState);
          }}
        />
        <Button
          classList="success me-2"
          type="button"
          text="Save"
          onClick={(e) => handleSubmit(e)}
        />
      </div>
    </CustomForm>
  );
};

export default AddPaymentSection;
