import React from "react";

const DisplayLanguagesSection = ({ languages }) => {
  return (
    <div className="animation-select">
      <p>Display this image on the following language editions of this KDF:</p>
      <div>
        {languages?.map((lang, index) => {
          return (
            <div className="d-flex gap-3 align-items-center" key={index}>
              <input type="checkbox" className="fs-5" />
              <div
                style={{
                  width: "18px",
                  height: "25px",
                }}
                className={lang?.language?.class}
              ></div>
              <p className="fs-6">{lang?.language?.name ?? "English (US)"}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default DisplayLanguagesSection;
