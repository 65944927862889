import React, { useContext, useEffect, useState } from "react";
import "datatables.net";
import DataPerPage from "../../CommonComponents/Pagination/DataPerPage";
import SearchComponent from "../../CommonComponents/SearchComponent/SearchComponent";
import CustomTable from "../../CommonComponents/Table/CustomTable";
import Button from "../../Elements/Button";
import AddCustomizationModal from "./AddCustomizationModal";
import HandleUiModal from "./HandleUiModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import UserInfoContext from "../../../utis/userInfoContext";
import { networkService } from "../../config/axios";
import AdminPagination from "../../CommonComponents/Pagination/AdminPagination";

const Customizations = () => {
  const { profileData } = useContext(UserInfoContext);
  const [openModal, setOpenModal] = useState(false);
  const [openClickModel, setOpenClickModel] = useState(false);
  const [customizationData, setCustomizationData] = useState({});
  const [customizationId, setCustomizationId] = useState(null);
  const [customizations, setCustomizations] = useState([]);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [dataPerPage, setDataPerPage] = useState(10);
  const totalData = customizations?.length;

  const handlePageChange = (pageNumber) => {
    const indexOfLastData = pageNumber * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    setCurrentPageData(
      customizations?.slice(indexOfFirstData, indexOfLastData)
    );
  };

  useEffect(() => {
    const fetchCustomizations = async () => {
      const response = await networkService("customizations.json", "GET");
      setCustomizations([...response.data?.customizations]);
    };
    fetchCustomizations();
  }, [openModal, openClickModel]);

  useEffect(() => {
    handlePageChange(1);
  }, [customizations]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const columns = React.useMemo(() => {
    return [
      {
        Header: "User Name",
        accessor: "user_name",
        Cell: () => <>{profileData?.name}</>,
      },
      {
        Header: "Message",
        accessor: "message",
      },
      {
        Header: "status",
        accessor: "approved",
        Cell: ({ row: { original } }) => (
          <>{original?.approved ? "Approved" : "Rejected"}</>
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => (
          <div className="d-flex">
            <FontAwesomeIcon
              className="me-2 cursor-pointer success success-green"
              icon={faPen}
              onClick={() => {
                setCustomizationData(row?.original);
                setOpenModal(true);
              }}
            />
          </div>
        ),
      },
    ];
  }, [currentPageData, profileData]);

  return (
    <>
      {openModal && (
        <AddCustomizationModal
          openModal={openModal}
          closeModal={handleCloseModal}
          customizationData={customizationData}
          setCustomizationId={setCustomizationId}
          setOpenClickModel={setOpenClickModel}
        />
      )}
      {openClickModel && (
        <HandleUiModal
          openModal={openClickModel}
          closeModal={() => {
            setOpenClickModel(false)
            setCustomizationData({});
          }}
          customizationId={customizationId}
          isEdit={customizationData?.id ? true : false}
        />
      )}
      <div className="d-flex justify-content-between margin-left-10 margin-right-10 align-items-center px-3">
        <span style={{ fontWeight: 800, fontSize: "30px" }}>
          Consent Modal Change Requests
        </span>
        <Button
          classList={"success"}
          onClick={() => {
            setOpenModal(true);
            setCustomizationData({})
          }}
          type="button"
          text="Request a Change"
        />
      </div>
      <div className="show-select1 d-flex mb-2">
        <div className="mb-2">
          <DataPerPage
            dataPerPage={dataPerPage}
            setDataPerPage={setDataPerPage}
          />
          <SearchComponent
            data={customizations}
            setCurrentPageData={setCurrentPageData}
          />
        </div>
      </div>
      <div className="consent-wrapper ms-1">
        <div className="consent-table ms-0">
          <div className="table-scroll-container">
            <CustomTable
              data={currentPageData}
              showFilters={false}
              columnData={columns}
            />
          </div>
          <AdminPagination
            dataPerPage={dataPerPage}
            totalData={totalData}
            onPageChange={handlePageChange}
          />
        </div>
      </div>


    </>
  );
};
export default Customizations;
