import React from "react";

const TermsAndPolicyContent = () => {
  return (
    <>
      <div className="modal-body">
        <div>
          <p className="mb-2">
            This Privacy Notice describes our practices in connection with
            Personal Data that we or our service providers collect through our
            Services operated and controlled by us.
          </p>
        </div>
        <div className="mt-3">
          <p className="mb-2">
            By using our Services, you will likely be sharing Personal Data /
            Personal Information pertaining to you with us. Essentially, you’re
            trusting us with your information. We understand this is a big
            responsibility and work hard to protect your information and put you
            in control.
          </p>
        </div>
        <div className="mt-3">
          <p className="mb-2">
            This Privacy Notice is meant to help you understand what Personal
            Data we Process (collect, access, transform, host and share), under
            which Purpose and Scope we do this, and which 3rd parties may have
            access. It also explains how you can update, manage, export, and
            delete your information.
            <strong>
              We do not share your personal information with any third party.
            </strong>
          </p>
        </div>
        <div className="mt-3">
          <h2>What we do</h2>
          <p className="mb-2">
            We build, operate, and maintain a range of Services that help our
            Corporate Clients and Customers, Partners, and Prospects improve and
            distribute their content, simplify business processes, and gain
            insight into their audiences’ needs. Our Services include:
          </p>
          <ul>
            <li>
              Sites, apps, and subscriptions such as: BoxOnline.com, Break-Even
              Analysis, and LaserLINX.
            </li>
            <li>Platforms like KinetiZine and 123.ch.</li>
            <li>
              Products that are integrated into third-party apps and sites like
              Sonic Medicine and The Unbeatable Goal Workbook.
            </li>
          </ul>
          <p className="mb-2">hereinafter the “Services”.</p>
        </div>
        <div>
          <h2>The Company</h2>
          <p className="mb-2">
            Trading Names: <br />
            KinetiZine, 123, LaserLINX, Funnel Accelerator, DealCertainty,
            SecureInsight
          </p>
          <p className="mb-2">
            Company Name: <br />
            BOX Ventures LLC
          </p>
          <p className="mb-2">
            Company Address: <br />
            Feldhof 12, 6300 Zug, Switzerland
          </p>
          <p className="mb-2">
            UID Number: <br />
            CHE-112.151.830
          </p>
        </div>
        <div>
          <h2>Definitions</h2>
          <p className="mb-2">
            <strong>Personal Data</strong>
            <br />
            Any identifier that either on its own (e.g. social security number)
            or where cross-referenced with other data enables the identification
            of a specific natural person (e.g. name combined with email
            address).
          </p>
          <p className="mb-2">
            <strong>Data Controller</strong>
            <br />
            The data controller determines the purposes for which and the means
            by which personal data is processed. So, if your company/
            organization decides ‘why’ and ‘how’ the personal data should be
            processed it is the data controller. Employees processing personal
            data within your organization do so to fulfill your tasks as data
            controller. Ref.
            https://commission.europa.eu/law/law-topic/data-protection/reform/rules-business-and-organisations/obligations/controllerprocessor/what-data-controller-or-data-processor_en#:~:text=The%20data%20controller%20determines%20the,it%20is%20the%20data%20controller.
          </p>
          <p className="mb-2">
            <strong>Processing</strong>
            <br />A natural person whose Personal Data is being Processed.
          </p>
          <p className="mb-2">
            <strong>Child</strong>
            <br />A natural person under 18 years of age.
          </p>
          <p className="mb-2">
            <strong>We/us/our </strong>(either capitalized or not)
            <br />
            BOX Ventures LLC employees both full and part-time.
          </p>
          <p className="mb-2">
            <strong>Site </strong>(capitalized, singular or plural)
            <br />
            The Web Sites we operate.
          </p>
          <p className="mb-2">
            <strong>Service</strong>
            <br />
            Our subscriptions, consulting services, Web Sites, Apps, software,
            and digital platforms.
          </p>
          <p className="mb-2">
            <strong>User</strong>
            <br />A client using one or more of our Services.
          </p>
          <p className="mb-2">
            <strong>User’s Content</strong>
            <br />
            Any content uploaded by a User to the Service.
          </p>
          <p className="mb-2">
            <strong>KDF</strong>
            <br />
            An acronym for "Knowledge Development Framework," which is defined
            as a sharable, optionally interactive document used for sales and
            marketing purposes, designed to provide relevant communication,
            outstanding deliverability, and profound intel. The copyright for
            the content within each KDF is owned and held by the respective
            copyright holder of that content.
          </p>
          <p className="mb-2">
            <strong>LaserLINK</strong>
            <br />A unique URL generated by our Service that allows users to
            securely share KDF content as a link, rather than as an attachment.
          </p>
        </div>
        <div>
          <h2>
            Your Rights as the Data Subject under applicable Personal Data
            Protection Law
          </h2>
          <p className="mb-2">The Data Subject has the following rights:</p>
          <ol>
            <li>
              <strong>Right to information –</strong>
              <br />
              meaning you have the right to know whether your Personal Data is
              being processed; what data is gathered, from where it is obtained
              and why and by whom it is processed.
            </li>
            <li>
              <strong> Right to access –</strong>
              <br />
              meaning you have the right to access the data collected from/about
              you. This includes your right to request and obtain a copy of your
              Personal Data gathered.
            </li>
            <li>
              <strong> Right to rectification –</strong>
              <br />
              meaning you have the right to request rectification or erasure of
              your Personal Data that is inaccurate or incomplete.
            </li>
            <li>
              <strong> Right to erasure – </strong>
              <br />
              meaning in certain circumstances you can request for your Personal
              Data to be erased from our records.
            </li>
            <li>
              <strong> Right to restrict processing – </strong>
              <br />
              meaning where certain conditions apply, you have the right to
              restrict the Processing of your Personal Data.
            </li>
            <li>
              <strong> Right to object to processing – </strong>
              <br />
              meaning in certain cases you have the right to object to
              Processing of your Personal Data, for example in the case of
              direct marketing.
            </li>
            <li>
              <strong> Right to object to automated Processing – </strong>
              <br />
              meaning you have the right to object to automated Processing,
              including profiling; and not to be subject to a decision based
              solely on automated Processing. This right you can exercise
              whenever there is an outcome of the profiling that produces legal
              effects concerning or significantly affecting you.
            </li>
            <li>
              <strong> Right to data portability – </strong>
              <br />
              you have the right to obtain your Personal Data in a
              machine-readable format or if it is feasible, as a direct transfer
              from one Processor to another.
            </li>
            <li>
              <strong> Right to lodge a complaint – </strong>
              <br />
              in the event that we refuse your request under the Rights of
              Access, we will provide you with a reason as to why. If you are
              not satisfied with the way your request has been handled please
              contact us.
            </li>
          </ol>
        </div>
        <div>
          <p className="mb-2">
            Additionally, it's important to understand that these Rights are not
            absolute. There are potentially circumstances where the exercise of
            such Rights may be constrained or even not met.
          </p>
          <p className="mb-2">
            Also, please note that your consent to the processing of your
            personal data is not irreversible. You have the right to withdraw
            your consent at any time. However, be aware that withdrawing consent
            may have consequences, as it could affect our ability to continue
            processing your personal data that is essential for providing the
            services to you.
          </p>
          <p className="mb-2">
            You may exercise your Rights by emailing
            <a href="mailto:DPO@BoxOnline.com">DPO@BoxOnline.com</a>
          </p>
        </div>
        <div>
          <h2>Data We Gather</h2>
          <p className="mb-2">
            We collect data from you, including without limitation through our
            interactions with you, our events, sales, and marketing activities,
            and through our Services. Some of this data is provided by you
            directly, and we get some of it by collecting data about your
            interactions, use, and experiences with our Services. The data we
            collect depends on the context of your interactions with us and the
            choices you make, including your privacy settings, the Services, and
            features you use. We also obtain data about you from third parties.
            The data we collect from you alone or in combination with other data
            could be used to identify you.
          </p>
          <p className="mb-2">
            Similar to other commercial Web sites, our Services utilize a
            standard technology called "cookies" (see explanation below in the
            section titled:
            <a href="#tve-jump-18dc82c2f2e">
              "Cookies &amp; Similar Technologies"
            </a>
            ) and Web server logs to collect information about how our Services
            are used. Information gathered through cookies and Web server logs
            may include the date and time of visits, the pages viewed, your IP
            address, time spent on our Site(s), and the Web sites visited just
            before and just after our Web site.
          </p>
          <p className="mb-2">
            To access certain Services, you are required to provide specific
            Personal Data. If you do not provide the necessary data, you will be
            unable to use the corresponding service or feature. Additionally, in
            cases where Personal Data collection is legally mandated or
            essential for entering into or fulfilling a contract with you,
            failure to provide this data means we cannot proceed with the
            contract. This may also lead to suspension or cancellation of a
            current service you are using. We will inform you if such a
            situation arises. Please note that in cases where providing the data
            is optional, and you choose not to share your personal data, you
            will not be able to use features that rely on this data, such as
            personalization services.
          </p>
        </div>
        <div>
          <h2>Collection of Personal Data</h2>
          <strong>
            <p>Personal Data you have provided us with</p>
          </strong>
          <p>
            Examples: e-mail address, name, phone number, billing address etc…
            which is necessary for delivering a product or Service to you..
          </p>
          <p>
            If you access a KDF via one of our websites, any of our social media
            posts, email, or any other means, please be aware that we may
            collect certain Personal Data about you.
          </p>
          <strong>
            <p>Information from third parties</p>
          </strong>
          <p>
            If you access a KDF from a third party and that KDF is hosted on our
            platform, please be aware that our Corporate Clients or Customers,
            who are the proprietors of the KDF content, may collect certain
            Personal Data about you.
          </p>
          <p>
            You are advised to review and consult the Privacy Notices or Privacy
            Policies of the respective Corporate Client or Customer, as they are
            the Data Controller. We serve as a Data Processor on their behalf.
            This means that our Corporate Client or Customer, who provided you
            with a LaserLINK, is solely responsible for determining the purpose
            and scope of Processing your Personal Data during your interaction
            with the KDF on our platform.
          </p>
          <p>
            When the scope of our Services involves the Processing of Personal
            Data, it potentially includes the following types of data:
          </p>
        </div>
        <div>
          <strong>
            <p>Name and contact data</p>
          </strong>
          <p>
            Your first and last name, email address, postal address, phone
            number, and other similar contact data.
          </p>
          <strong>
            <p>Credentials</p>
          </strong>
          <p>
            Passwords, password hints, and similar security information used for
            authentication and account access.
          </p>
          <strong>
            <p>Demographic data</p>
          </strong>
          <p>Data about you such as your country, and preferred language.</p>
          <strong>
            <p>Subscription and licensing data</p>
          </strong>
          <p>
            Information about your subscriptions, licenses, and other
            entitlements.
          </p>
          <strong>
            <p>Interactions</p>
          </strong>
          <p>
            Data pertaining to your use of our Services. When you enter search
            queries, the data is provided by you to access specific features of
            our Service. In other instances, such as error reporting, the data
            is generated by us. Other examples of interaction data include:
          </p>
        </div>

        <div>
          <ul>
            <li>
              <b>Device and usage data </b>
              <p>
                Data about your device, the service, and features you use,
                including information about your hardware and software, how our
                service performs, as well as your settings. For example:
              </p>
            </li>
            <ul className="payment-hist">
              <li>
                <strong>
                  <p> Payment and Account History</p>
                </strong>
                <p>
                  Data about the items you purchase and activities associated
                  with your account.
                </p>
              </li>
              <li>
                <strong>
                  <p>Browse History</p>
                </strong>
                <p>Data about the KDFs and Web pages you visit.</p>
              </li>
              <li>
                <strong>
                  <p>Device, connectivity, and configuration data</p>
                </strong>
                <p>
                  Data about your device, your device configuration, and nearby
                  networks. For example, data about the operating systems, other
                  software installed on your device, IP address, regional
                  settings, and language settings.
                </p>
              </li>
              <li>
                <strong>
                  <p>Error reports and performance data</p>
                </strong>

                <p>
                  Data about the performance of the services and any problems
                  you experience, including error reports. Error reports
                  (sometimes called “crash logs”) can include details of the
                  software or hardware related to an error, contents of files
                  opened when an error occurred, and data about other software
                  on your device.
                </p>
              </li>
              <li>
                <strong>
                  <p>Troubleshooting and Help Data</p>
                </strong>

                <p>
                  Data you provide when you contact us for help, such as the
                  products and services you use, and other details that help us
                  provide support. For example, contact or authentication data,
                  the content of your chats and other communications with us,
                  data about the condition of your device, and the products you
                  use related to your help inquiry. When you contact us, such as
                  for customer support, phone conversations or chat sessions
                  with our representatives, the conversation may be monitored
                  and recorded.
                </p>
              </li>
            </ul>
          </ul>
          <div>
            <strong>
              <p>Content consumption data</p>
            </strong>
            <p>
              Information about the content you engage with and access through
              our Services.
            </p>
            <strong>
              <p>Searches and commands</p>
            </strong>
            <p>
              Search queries and commands you use within search or related
              functionality.
            </p>
            <strong>
              <p>Text, and typing data</p>
            </strong>
            <p>Text and typing data and related information.</p>
            <strong>
              <p>Images</p>
            </strong>
            <p>Images, videos, animations and related information</p>
            <strong>
              <p>Contacts and relationships</p>
            </strong>
            <p>
              Data about your contacts and relationships if you use a product or
              service to share information with others, communicate with others,
              or improve your productivity.
            </p>
            <strong>
              <p>Social data</p>
            </strong>
            <p>
              Information about your relationships and interactions between you,
              other people, and organizations, such as types of engagement
              (e.g., likes, dislikes, events, etc.) related to people and
              organizations.
            </p>
            <strong>
              <p>Location data</p>
            </strong>
            <p>
              Data about your device’s location, which is typically imprecise.
              For example, location can be inferred from a device’s IP address.
              We may send your IP address to a third party such as MaxMind who
              then provides us with your device’s approximate location on a city
              or postal code level.
            </p>
            <strong>
              <p>Other input</p>
            </strong>
            <p>
              Other inputs provided when you use our products. For example, data
              such as the links you open, features you engage with, and the
              length of time you engage with them.
            </p>
            <strong>
              <p>Content</p>
            </strong>
            <p>
              Content of a KDF you control and the information you add, input,
              upload, receive, and create.
            </p>
            <strong>
              <p>Feedback and ratings</p>
            </strong>
            <p>
              Information you provide to us and the content of messages you send
              to us, such as feedback, survey data, and product reviews you
              write.
            </p>
          </div>
          <div>
            <h2>Purpose and Scope of Personal Data Processing by us</h2>
          </div>
          <div>
            <p>
              For information you provide directly to us as a Corporate Client
              or Customer (e.g. your name and email address), We act as
              Controller. For information captured using our services, we act as
              a Processor. It is important that we all comply with the GDPR when
              transferring personal data.
            </p>
            <p>
              When we process Personal Data pertaining to you, we either do it
              with your Consent or under a contractual obligation with a
              Corporate Client / Customer acting as the Controller. In
              particular,
              <strong>
                where you are a Corporate Client representative or a Customer,
                we Process Personal Data to:
              </strong>
            </p>
          </div>
          <div>
            <ol>
              <li>
                Provide information or respond to requests (e.g. when you
                request a demo, an offer, a proposal, or price information), or
                register for a webinar or an event we will use your contact
                information to respond to your request. For EU Data Subjects,
                such use is necessary to respond to or implement your request;
              </li>
              <li>
                Provide our Services, which includes registering your account;
                updating, securing, and troubleshooting, as well as providing
                support. It also includes sharing data, when it is required to
                provide the Service or carry out the transactions you request;
                providing you with promotional items at your request; and
                communicating with you regarding those products and Services;
                communicating and interacting with you; and notifying you of
                changes to any of our Services;
              </li>

              <li>
                Personalization. Some of our Services may include personalized
                features, such as recommendations that enhance your productivity
                and enjoyment. These features use automated processes to tailor
                your experiences based on the data we have about you, such as
                inferences we make about you and your use of the service,
                actions taken within a KDF, interests, responses to surveys and
                polls, and location. For example, depending on your settings, if
                you view a KDF on your device as you travel, you may see a local
                language version of that KDF become available. You may also have
                access to a library of other KDFs recommended as similar to the
                one you are viewing. Some of our Services provide controls to
                enable/ disable personalized features;
              </li>
              <li>
                Subscription activation. We use data - such as device,
                application type, location, network, and subscription
                identifiers - to activate products or features that require
                activation;
              </li>
              <li>Complete a transaction (e.g. a purchase);</li>
              <li>Provide access (e.g. to a paid Service);</li>
              <li>Enhance your customer experience;</li>
              <li>Improve and develop our products;</li>
              <li>Fulfill a third party order;</li>
              <li>Fulfill an obligation under law or contract;</li>
              <li>Analyze our performance;</li>
              <li>Develop our workforce;</li>
              <li>Conduct research;</li>
              <li>Help prevent fraud;</li>
            </ol>
          </div>
          <div>
            <strong>
              <p>
                In cases where you access a KDF via one or more LaserLINX
                provided to you by one of our Corporate Clients or Customers, we
                process Personal Data for the following purposes:
              </p>
            </strong>
            <ol>
              <li>Enable access to the KDF;</li>
              <li>
                Register your Consent if required by law, requested by a
                Corporate Client, or included in a KDF feature such as an opt-in
                or quid-pro-quo module;
              </li>
              <li>
                Profile your actions within a KDF as you review its content
                (what you viewed, for how long, what you clicked or tapped,
                etc.);
              </li>
            </ol>
          </div>
          <div>
            <h2>Retaining Personal Data</h2>
            <p>
              We will retain Personal Data for the duration of an active
              Services Contract with our Corporate Clients/ Customers, or until
              they instruct us to erase it, or until such time as our legal
              obligation to retain the data expires.
            </p>
          </div>
          <div>
            <h2>Cookies & Similar Technologies</h2>
            <p>
              Cookies are small text files placed on your device to store data
              that can be recalled by a web server in the domain that placed the
              cookie. This data often consists of a string of numbers and
              letters that uniquely identifies your computer, but it can contain
              other information as well, including Personal Data pertaining to
              you, which may be sensitive or constitute a risk for you depending
              on both the purpose of such cookie as the “intentions” of the
              party that has dropped the Cookie.
            </p>
            <p>
              By law you have the Right to object to the Processing of your
              Personal Data by any cookies that are not categorized as
              'essential' for the functionality of The Website. 'Essential'
              cookies are those necessary for The Website to operate and deliver
              its services without interruption. You may refuse the use of
              non-essential cookies, as their absence does not impede The
              Website's core service delivery.
            </p>
            <p>
              Please use our Cookie Management tool (link in the footer) to
              accept either all cookies or only essential cookies during your
              visit. We do not use first party-cookies but, we support the
              addition of third-party services which often drop their own
              cookies.
            </p>
          </div>
          <div>
            <h2>Third-party cookies</h2>
            <p>
              Some of our pages display content from external providers, e.g.
              YouTube, Vimeo, X, Typeform, Paperform.
            </p>
            <p>
              To view this third-party content, you first have to accept their
              specific terms and conditions. This includes their cookie
              policies, which we have no control over.
            </p>
            <p>
              If you prefer not to access video and other types of content from
              third-party sources, you may select the option 'Accept only
              essential cookies' in our Cookie Management tool. By choosing this
              option, no third-party cookies will be installed on your device.
            </p>
            <p>
              Examples of third-party providers that could be embedded into a
              KDF by us, our Corporate Clients or Customers.
            </p>
          </div>
          <div className="mt-3">
            <h3 className="p-0">
              <a href="#">Paperform</a>
            </h3>
            <h3 className="p-0">
              <a href="#">Typeform</a>
            </h3>

            <h3 className="p-0">
              <a href="#">You Tube</a>
            </h3>

            <h3 className="p-0">
              <a href="#">Internet Archive</a>
            </h3>

            <h3 className="p-0">
              <a href="#">Google Maps</a>
            </h3>

            <h3 className="p-0">
              <a href="#">X</a>
            </h3>

            <h3 className="p-0">
              <a href="#">Vimeo</a>
            </h3>

            <h3 className="p-0">
              <a href="#">Microsoft</a>
            </h3>

            <h3 className="p-0">
              <a href="#">Facebook</a>
            </h3>
            <h3 className="p-0">
              <a href="#">Google</a>
            </h3>
            <h3 className="p-0">
              <a href="#">LinkedIn</a>
            </h3>
            <h3 className="p-0">
              <a href="#">Livestream</a>
            </h3>
            <h3 className="p-0">
              <a href="#">SoundCloud</a>
            </h3>
            <h3 className="p-0">
              <a href="#">European Parliament</a>
            </h3>

            <p>
              These third-party services are outside of our control. Providers
              may, at any time, change their terms of service, purpose and use
              of cookies, etc.
            </p>
            <p>
              <strong>
                <h3>PLEASE NOTE</h3>
              </strong>
              If you navigate away from our website (for instance, by clicking a
              link that directs you to a different website), that other website
              will likely use its own cookies. Consequently, the cookie
              preferences you set on our website will not apply to any other
              website you visit.
            </p>
          </div>
          <div>
            <h2>Where We Store and Process Personal Data</h2>
            <p>
              We operate servers hosted in major data centers in Switzerland,
              Germany, the United Kingdom, and the United States. Typically, the
              primary storage location is in Switzerland or Germany; often with
              a backup to a data center in a second, legally compliant region.
              The storage location(s) are chosen in order to operate
              efficiently, improve performance, and create redundancies in order
              to protect the data in the event of an outage or other problem. We
              take steps to ensure that the data we collect under this Privacy
              Notice is processed according to the provisions of this notice and
              the requirements of applicable law wherever the data is located.
            </p>
          </div>
          <div>
            <h2>Children</h2>
            <p>
              We do not intentionally process any Personal Data of children. Our
              Sites, along with the products and services offered through them,
              are intended for use by individuals who are 18 years of age or
              older, unless supervised by a parent or legal guardian.
              Consequently, if it comes to our attention, or if we reasonably
              suspect that we have collected Personal Data from a child, we will
              promptly delete such information. This action will be taken
              irrespective of any existing contractual commitments, in
              compliance with legal obligations regarding the protection of
              children's data
            </p>
          </div>
          <div>
            <h2>Changes to this Privacy Notice</h2>
            <p>
              We reserve the right to make changes to this Privacy Notice. The
              date of the most recent update is indicated at the top of this
              document, immediately following its title.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default TermsAndPolicyContent;
