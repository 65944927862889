import React, { useContext, useEffect, useState } from "react";
import CommonModal from "../CommonModal";
import Select from "../../Elements/Select";
import { businessTypes } from "../../../hooks/utils";
import CustomInput from "../../CommonComponents/CustomForm/CustomInputField";
import Button from "../../Elements/Button";
import { networkService } from "../../config/axios";
import CustomForm from "../../CommonComponents/CustomForm/CustomForm";
import { csrfToken } from "../../../utis/csrfTokenUtils";
import UserInfoContext from "../../../utis/userInfoContext";

const AddOrganisation = ({ openModal, closeModal, orgData }) => {
  const AddOrganisationBody = () => {
    const initialState = {
      name: "",
      company_type: "",
      trial: "",
      payment_threshold: "",
      licences: "",
      licence_threshold: "",
    };
    const { setShowNotifications } = useContext(UserInfoContext);

    const [company, setCompany] = useState({
      ...initialState,
    });
    const [error, setError] = useState({});

    const handleChange = ({ target: { name, value } }) => {
      setError({
        [name]: "",
      });
      setCompany({
        ...company,
        [name]: value,
      });
    };

    const handleValidations = (company) => {
      let hasError = false;
      const newErrors = {};

      if (!company?.name) {
        newErrors.name = "Please add the Organisation name";
        hasError = true;
      }
      if (!company?.company_type) {
        newErrors.company_type = "Please select the Company";
        hasError = true;
      }
      if (!company?.trial) {
        newErrors.trial = "Please enter the trial period";
        hasError = true;
      }
      if (!company?.payment_threshold) {
        newErrors.payment_threshold = "Please enter the payment threshold";
        hasError = true;
      }
      if (!company?.licences) {
        newErrors.licences = "Please enter the licence";
        hasError = true;
      }
      if (!company?.licence_threshold) {
        newErrors.licence_threshold = "Please enter the licence threshold";
        hasError = true;
      }

      if (hasError) {
        setError({ ...error, ...newErrors });
        return false;
      } else {
        return true;
      }
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      if (handleValidations(company)) {
        const url = company?.id
          ? `admin/orgs/${company?.id}.json`
          : "admin/orgs.json";
        const data = {
          authenticity_token: csrfToken(),
          company: {
            name: company?.name,
            company_type: company?.company_type,
            trial: company?.trial,
            payment_threshold: company?.payment_threshold,
            licences: company?.licences,
            licence_threshold: company?.licence_threshold,
          },
          ...(company?.id && { id: company?.id }),
        };
        await networkService(url, company?.id ? "PATCH" : "POST", data)
          .then((response) => {
            if (response?.status === 200 || response?.status == 201) {
              setCompany({ ...initialState });
              closeModal(null);
              setShowNotifications({
                showToast: true,
                toastType: "success",
                toastMessage: `Organisation ${company?.id ? "Updated" : "Added"
                  }`,
              });
            }
          })
          .catch((error) => {
            setShowNotifications({
              showToast: true,
              toastType: "danger",
              toastMessage: error?.response?.data?.errors?.name[0] ?? "Something went wrong",
            });
          });
      }
    };

    useEffect(() => {
      setCompany({
        ...orgData,
      });
    }, [orgData]);

    return (
      <CustomForm className="org-form-pop">
        <div className="d-flex gap-2">
          <div className="row org-name-inp org-name">
            <div className="col-sm-12 mb-3">
              <div className="text-area-inp">
                <label className="mb-2">Organization name</label>
                <CustomInput
                  placeholder={"Organization name"}
                  value={company?.name}
                  name={"name"}
                  onChange={handleChange}
                  required={true}
                  type={"text"}
                  error={error?.name}
                />
              </div>
            </div>
          </div>
          <div className="org-type">
            <div className="">
              <label className="mb-2">Organization Type</label>
              <Select
                classList=""
                name="company_type"
                required={true}
                onChange={(e) => handleChange(e)}
                defaultValue={company.company_type ?? company?.type}
                optionList={businessTypes()?.map((type) => {
                  return {
                    value: type.value,
                    title: type.name,
                  };
                })}
              />
              {error?.company_type && (
                <p className="text-danger">{error?.company_type}</p>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex gap-2">
          <div className=" d-flex align-items-center  org-name">
            <div className="form-group org-name">
              <label className="mb-1" htmlFor="trial">
                Trial Period (in days)
              </label>
              <CustomInput
                className="w-100"
                placeholder={"Trial Period (in days)"}
                value={company?.trial}
                name={"trial"}
                onChange={handleChange}
                required={true}
                type={"number"}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                error={error?.trial}
              />
            </div>
          </div>
          <div className=" d-flex align items center  org-name">
            <div className="form-group org-name">
              <label className="mb-1" htmlFor="payment">
                Payment threshold (in days)
              </label>
              <CustomInput
                className="w-100"
                placeholder={"Payment threshold (in days)"}
                value={company?.payment_threshold}
                name={"payment_threshold"}
                onChange={handleChange}
                required={true}
                type={"number"}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                error={error?.payment_threshold}
              />
            </div>
          </div>
        </div>
        <div className="d-flex gap-2">
          <div className="d-flex align items center  org-name">
            <div className="form-group org-name">
              <label className="mb-1" htmlFor="lic">
                Number of licenses
              </label>
              <CustomInput
                className="w-100"
                placeholder={"Number of licenses"}
                value={company?.licences}
                name={"licences"}
                onChange={handleChange}
                required={true}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                type={"number"}
                error={error?.licences}
              />
            </div>
          </div>
          <div className=" d-flex align items center org-name">
            <div className="form-group org-name">
              <label className="mb-1" htmlFor="lic-th">
                Licenses threshold
              </label>
              <CustomInput
                className="w-100"
                placeholder={"Licenses threshold"}
                value={company?.licence_threshold}
                name={"licence_threshold"}
                onChange={handleChange}
                required={true}
                type={"number"}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                error={error?.licence_threshold}
              />
            </div>
          </div>
        </div>
        <div className="Continue-btn p-0 mt-2">
          <Button
            classList="success"
            type="submit"
            text="Save Changes"
            onClick={(e) => handleSubmit(e)}
          />
        </div>
      </CustomForm>
    );
  };

  return (
    <CommonModal
      title={"Organization settings"}
      modalStatus={openModal}
      changeActiveStatus={(status) => closeModal()}
      classList="header-profile-modal-wrapper"
      centered
    >
      <AddOrganisationBody />
    </CommonModal>
  );
};
export default AddOrganisation;
