import React from "react";
import { Dropdown } from "react-bootstrap";

const LanguageDropdown = ({
  arrayOfElements,
  name,
  handleSelect,
  selectedLanguage,
}) => {
  return (
    <Dropdown className="flags-drop">
      <Dropdown.Toggle
        className="header-select-flag custom-toggle"
        id="dropdown-basic"
      >
        {name?.name ? (
          <div className="d-flex flag-language">
            <div
              className={name.class}
              style={{
                height: "18px",
                width: "25px",
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            ></div>
            <p className="fs-6 ps-1">{name?.name}</p>
          </div>
        ) : selectedLanguage ? (
          <div className="d-flex flag-language">
            <div
              className={selectedLanguage?.class}
              style={{
                height: "18px",
                width: "25px",
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            ></div>
            <p className="fs-6 ps-1">{selectedLanguage?.name}</p>
          </div>
        ) : (
          <p className="select-lng"> Select language</p>
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu className="custom-menu">
        {arrayOfElements?.map((elem) => {
          return (
            <Dropdown.Item key={elem?.id} onClick={() => handleSelect(elem)}>
              <div className="d-flex flag-language">
                <div
                  className={elem?.language?.class}
                  style={{
                    height: "18px",
                    width: "25px",
                  }}
                ></div>
                <span className="fs-6 ps-1">{elem?.language?.name}</span>
              </div>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default LanguageDropdown;
