import React, { useState, useEffect } from "react";
import Paginate from "../../../CommonComponents/Pagination/Paginate";
import customAPIRequest from "../../../../utis/customAPIRequest";
import { Container } from "react-bootstrap";
import styles from './KdfListingPage.module.css';
import utilsUploadFormData from "../../../../utis/upoadFormData";
import CommonModal from "../../../Modals/CommonModal";
import Button from "../../../Elements/Button";
import { networkService } from "../../../config/axios";
import KdfFilerForm from "./KdfFIlters";
import LoadingScreen from "../../../Elements/LoadingScreen/LoadingScreen";
import { useLocation } from "react-router-dom";
import KdfListCard from "./KdfListCard";

const KdfListPage = ({ props }) => {
    const itemsPerPage = 10;
    const [docData, setDocData] = useState({ loading: true });
    const [getDeleteKDFItem, setDeleteKDFItem] = useState(null);
    const [getCloneKDFItem, setCloneKDFItem] = useState(null);
    const [getPrivateFlagKDFItem, setPrivateFlagKDFItem] = useState(null);
    const { pathname } = useLocation();

    const handleGetKDFDocData = async (page = 1) => {
        if (page >= 1) {
            setDocData(prev => ({ ...prev, 'loading': true }))
        }
        let payloadtoGet = {
            "page": page,
            "per_page": itemsPerPage
        };
        if (pathname.includes('kdfs/token')) {
            payloadtoGet['doc_id'] = window?.objDoc?.id;
            payloadtoGet['name'] = window?.objDoc?.title;
        }
        let getAllDocData = await customAPIRequest('docs-search.json', 'POST', payloadtoGet);
        setDocData({ ...getAllDocData, "page": page, "per_page": itemsPerPage, 'loading': false });
    }
    // Update Tags start 
    const handleUpdateDocData = async (dataToUpdate = [], itemID) => {
        try {
            let uploadData = await utilsUploadFormData(`kdfs/${itemID}.json`, { doc: { 'tag_ids': dataToUpdate } }, 'PATCH');
            handleGetKDFDocData();
        } catch (error) {
            console.warn(error);
        }
    }

    const handleAddKDFTagsEvent = async (value, data) => {
        let valueToUpdate = [];
        if (value.length > data?.tags.length) {
            let updateTagsToDoc = await utilsUploadFormData(`tags.json`, { 'name': value[value.length - 1] }, 'POST');
            valueToUpdate = [...data?.tags?.map(item => item.id), updateTagsToDoc.data.id]
        } else {
            valueToUpdate = data?.tags.filter(item => value.includes(item.name))?.map(item => item.id);
        }
        handleUpdateDocData(valueToUpdate, data.id);
    }

    const updateRecordData = (value, data, type) => {
        if (type == 'tags') {
            handleAddKDFTagsEvent(value, data)
        }
    }

    // Update Tags end
    const handleDeleteKDFItemEvent = async () => {
        let { status } = await networkService(`/kdfs/${getDeleteKDFItem}.json`, 'DELETE');
        if (status == 204) {
            handleGetKDFDocData();
            setDeleteKDFItem(null)
        }
    }

    const handleCloneKDFEvent = async () => {
        let { data } = await networkService(`/docs/clone/${getCloneKDFItem}.json`, 'GET');
        if (data.msg == 'ok') {
            handleGetKDFDocData();
            setCloneKDFItem(null)
        }
    }

    const handleMakePrivateEvent = async () => {
        let { status } = await networkService(`kdfs/${getPrivateFlagKDFItem.id}.json`, 'PATCH', { doc_id: getPrivateFlagKDFItem?.id, private_flag: (getPrivateFlagKDFItem?.status ?? false) });
        if (status == 200) {
            handleGetKDFDocData();
            setPrivateFlagKDFItem(null)
        }
    }

    const handleTriggerApplyFilterEvent = async (data = {}) => {
        setDocData(prev => ({ ...prev, 'loading': true }));
        let getAllDocData = await customAPIRequest('docs-search.json', 'POST',
            {
                "page": 1,
                "per_page": docData.per_page,
                ...data
            }
        );
        setDocData({ "page": 1, "per_page": docData.per_page, ...getAllDocData, 'loading': false });
    }

    useEffect(() => {
        handleGetKDFDocData();
    }, [])

    return (
        <div>
            <CommonModal
                title={getDeleteKDFItem != null ? 'Delete KDF' : 'Clone this KDF'}
                modalStatus={getDeleteKDFItem != null || getCloneKDFItem != null}
                changeActiveStatus={() => { setDeleteKDFItem(null); setCloneKDFItem(null) }}
                centered
                classList={'upload-kdf-modal-wrapper alert-modal'}
                footer={
                    <div className="d-flex m-auto">
                        <Button text={'Cancel'} classList={'alert delete-btn'} addonClass={'my-2 mx-2'} onClick={() => { setDeleteKDFItem(null); setCloneKDFItem(null) }} />
                        <Button text={getDeleteKDFItem != null ? 'Delete' : 'Clone'} classList={'success delete-btn'} addonClass={'my-2 mx-2'} onClick={() => getDeleteKDFItem != null ? handleDeleteKDFItemEvent() : handleCloneKDFEvent()} />
                    </div>
                }
            >
                <p style={{ fontSize: '14px' }}>
                    {
                        getDeleteKDFItem != null ? 'Are you sure you want to delete this KDF? This action cannot be undone.' : 'Do you want to continue?'
                    }
                </p>
            </CommonModal>
            <CommonModal
                title={''}
                modalStatus={getPrivateFlagKDFItem != null}
                changeActiveStatus={() => setPrivateFlagKDFItem(null)}
                centered
                classList={'upload-kdf-modal-wrapper alert-modal'}
                footer={
                    <div className="d-flex m-auto">
                        <Button text={'Cancel'} classList={'alert delete-btn'} addonClass={'my-2 mx-2'} onClick={() => setPrivateFlagKDFItem(null)} />
                        <Button text={'Yes'} classList={'success delete-btn'} addonClass={'my-2 mx-2'} onClick={() => handleMakePrivateEvent()} />
                    </div>
                }
            >
                <p style={{ fontSize: '14px' }}>
                    Hide this KDF from other kinetizine users. Your recipients will still be able to view it via the LaserLINX you share with them.
                </p>
            </CommonModal>
            <div className="header-content-main">
                <div className="doc-filter">
                    <h4 className="filter-title">Filters</h4>
                    <KdfFilerForm
                        triggerApplyFilterEvent={handleTriggerApplyFilterEvent}
                        loading={docData?.loading}
                        kdfTitleDefault={pathname.includes('kdfs/token') ? window?.objDoc?.title : ''}
                    // triggerResetFilter={handleTriggerApplyFilterEvent}
                    />
                </div>
            </div>
            <Container className={styles.listingContainer}>
                {
                    docData?.loading ?
                        <LoadingScreen />
                        :
                        (docData?.data?.docs != undefined && docData?.data?.docs?.length > 0 ?
                            <>
                                <Paginate
                                    currentPage={docData?.page}
                                    totalPages={Math.ceil(docData?.data?.cnt / docData?.per_page)}
                                    onPageChange={handleGetKDFDocData}
                                    totalData={docData?.data?.cnt}
                                    itemTitle={'KDFs'}
                                />
                                {docData?.data?.docs?.map((kdf, index) =>
                                    <div key={index}>
                                        <KdfListCard
                                            forKey={`${kdf?.id}-${index}`}
                                            data={kdf}
                                            triggerUdateRecord={() => handleGetKDFDocData()}
                                            triggerUpdateRecordData={updateRecordData}
                                            time_zone_list={props.time_zone_list}
                                            triggerDeleteKDF={setDeleteKDFItem}
                                            triggerCloneKDF={setCloneKDFItem}
                                            triggerMakePrivateEvent={setPrivateFlagKDFItem}
                                        />
                                    </div>
                                )}
                                <Paginate
                                    currentPage={docData?.page}
                                    totalPages={Math.ceil(docData?.data?.cnt / docData?.per_page)}
                                    onPageChange={handleGetKDFDocData}
                                    totalData={docData?.data?.cnt}
                                    itemTitle={'KDFs'}
                                />
                            </>
                            :
                            docData?.error != undefined ? docData?.error : (docData?.data?.docs?.length == 0 ? "No Record Found" : 'Loading...')
                        )
                }
            </Container>
        </div >
    )
}
export default KdfListPage;