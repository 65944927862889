import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const BarChart = ({ data, name }) => {
    const options = {
        chart: {
            type: 'bar',
        },
        title: {
            text: '',
        },
        xAxis: {
            categories: data?.map(item => item?.[name]),
            title: {
                text: name,
            },
        },
        yAxis: {
            min: 0,
            title: {
                text: name,
                align: 'high',
            },
            labels: {
                overflow: 'justify',
            },
        },
        tooltip: {
            pointFormat: '{point.y} Visits',
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true,
                },
            },
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
        series: [
            {
                name: name,
                data: data?.map(item => item?.hits),
                color: '#4caeff',
            },
        ],
    };

    return (
        <>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </>
    );
};

export default BarChart;
