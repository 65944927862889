import React, { useContext, useEffect, useState } from "react";
import CommonModal from "../CommonModal";
import ManageDomainName from "../../Pages/Admin/Organisation/ManageDomainName";
import { networkService } from "../../config/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import CustomTable from "../../CommonComponents/Table/CustomTable";
import { Tab, Tabs } from "react-bootstrap";
import DeleteOrgModal from "./DeleteOrgModal";
import UserInfoContext from "../../../utis/userInfoContext";

const ManageDomain = ({ openModal, closeModal, companyId }) => {
  const AddDomainBody = () => {
    const { setShowNotifications } = useContext(UserInfoContext)
    const [show, setShow] = useState('list');
    const [domains, setDomains] = useState([])
    const [openDelete, setOpenDelete] = useState(false)
    const [domain, setDomain] = useState({})

    const handleSelect = (selectedKey) => {
      setDomain({})
      setShow(selectedKey);
    };

    useEffect(() => {
      const fetchDomains = async () => {
        const response = await networkService(`admin/company-domains/${companyId}.json`, "GET");
        setDomains([...response.data]);
      };
      fetchDomains();
    }, [show, openDelete]);

    const columns = React.useMemo(() => {
      return [
        {
          Header: 'Domain',
          accessor: 'name',
        },
        {
          Header: 'Created By',
          accessor: 'creator',
        },
        {
          Header: 'Modified By',
          accessor: 'modifier',
        },
        {
          Header: 'Actions',
          accessor: 'action',
          Cell: ({ row }) => (
            <>
              <FontAwesomeIcon
                className="me-2 cursor-pointer"
                icon={faPen}
                onClick={() => {
                  setShow('form')
                  setDomain(row?.original)
                }}
              />
              <FontAwesomeIcon
                className="cursor-pointer"
                icon={faTrashCan}
                onClick={() => {
                  setOpenDelete(true)
                  setDomain(row?.original)
                }}
              /></>
          ),
        },
      ];
    }, [domains]);

    const handleDeleteDomain = () => {
      const url = `admin/company_domains/${domain?.id}.json`;
      networkService(url, "DELETE")
        .then((response) => {
          if (response.status == 204) {
            setOpenDelete(false)
            setShowNotifications({
              showToast: true,
              toastType: "success",
              toastMessage: 'Domain successfully deleted',
            })
          }
        })
        .catch((error) => {
          setShowNotifications({
            showToast: true,
            toastType: "danger",
            toastMessage: 'something went wrong!',
          })
        });
    }

    return (
      <>
        {openDelete &&
          <DeleteOrgModal
            title={'Delete Domain'}
            openModal={openDelete}
            closeModal={setOpenDelete}
            name={'delete ' + domain?.name}
            handleDelete={() => handleDeleteDomain()}
          />
        }
        <Tabs
          activeKey={show}
          onSelect={handleSelect}
          className="mt-3 select-file-pop"
        >
          <Tab
            eventKey="list"
            title={'Domains List'}
          >
            <CustomTable data={domains} columnData={columns} showFilters={false} />
          </Tab>
          <Tab
            eventKey="form"
            title={'Add / Edit Domain'}
          >
            <ManageDomainName setShow={setShow} companyId={companyId} domain={domain} />
          </Tab>
        </Tabs>
      </>
    );
  };

  return (
    <CommonModal
      title={"Manage domains"}
      modalStatus={openModal}
      changeActiveStatus={(status) => closeModal()}
      classList="header-profile-modal-wrapper"
      centered
    >
      <AddDomainBody />
    </CommonModal>
  );
};
export default ManageDomain;
