import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faCircleInfo,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { Nav, Navbar, NavDropdown, Container } from "react-bootstrap";
import Button from "../../Elements/Button";
import "./header.css";
import UserInfoContext from "../../../utis/userInfoContext";
import apiGETRequest from "../../../utis/apiRequest";
import { csrfToken } from "../../../utis/csrfTokenUtils";
import TermsModals from "../../Modals/TermsModals";
import MyProfileModal from "../../Modals/profileModals/MyProfileModal";
import { handleClearCookie } from "../../../utis/helper";

const Header = ({ heading, currentUser, trueUser }) => {
  const { profileData, userInfoData, setUserData, setUserProfileData, setShowCreateTerms } = useContext(UserInfoContext);
  const [profileModalStaus, setProfileModalStaus] = useState(false);
  const [openTerms, setOpenTerms] = useState(false)
  const isProfileModalOpened = localStorage.getItem('modalOpen')

  const updatingUserProfile = async () => {
    let userData = await apiGETRequest(`edit.json`);
    if (userData) {
      setUserData(userData);
      let userProfileData = await apiGETRequest(
        `profiles/${userData?.id}.json`
      );
      setUserProfileData(userProfileData);
    }
  };

  const handleStopImpersonate = async () => {
    const formData = new FormData();
    formData.append("_method", "post");
    formData.append("authenticity_token", csrfToken());
    try {
      const response = await fetch(`/admin/stop_impersonating`, {
        method: "POST",
        body: formData,
        cookie: {
          "X-CSRF-Token": document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
        },
      });
      if (response?.ok) {
        window.location.href = "/";
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  const handleCreateNew = () => {
    if (userInfoData?.tos_accepted_date == null) {
      setShowCreateTerms(true)
    } else {
      window.location = "/kdfs/new"
    }
  }

  useEffect(() => {
    updatingUserProfile();
  }, []);

  useEffect(() => {
    if ((isProfileModalOpened == 'false' || isProfileModalOpened == null) && userInfoData &&
      profileData &&
      (userInfoData?.privacy_accepted == null || !userInfoData?.privacy_accepted) &&
      (profileData?.first_name == null || !profileData?.first_name)
    ) {
      setProfileModalStaus(true)
      localStorage.setItem("modalOpen", true)
    }
  }, [userInfoData, profileData])

  return (
    <header className="main-header">
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container fluid className="kdf-setting-header-h">
          <Navbar.Brand>
            {heading?.toLowerCase() == "kdfs" ? (
              <div className="d-flex align-items-center">
                <h2
               className="me-4 mb-0" > {heading}</h2>
                
                <Button
                  classList={"success"}
                  addonClass="d-inline-flex ml-4"
                  onClick={() => handleCreateNew()}
                  text={"Create New"}
                ></Button>
              </div>
            ) : (
              <h2 className="m-0">{heading} </h2>
            )}

          </Navbar.Brand>
          <Nav
            className="my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            <NavDropdown
              title={
                <>
                  <img className="user-imgs" src={`https:${profileData?.image}`} alt="" />
                  <span className="mx-3 user-name-header">
                    {profileData?.name}{" "}
                  </span>
                </>
              }
              id="navbarScrollingDropdown"
            >
              <NavDropdown.Item
                className="profile-drop"
                onClick={() => setProfileModalStaus(true)}
              >
                <FontAwesomeIcon icon={faUser} className="" /> My Profile
              </NavDropdown.Item>
              <NavDropdown.Item className="profile-drop" href="/customizations">
                <FontAwesomeIcon icon={faCircleInfo} className="" />{" "}
                Customizations
              </NavDropdown.Item>
              <NavDropdown.Item
                className="profile-drop"
                onClick={handleClearCookie}
              >
                <FontAwesomeIcon
                  icon={faRightFromBracket}
                  className="active-icon"
                />{" "}
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Container>
      </Navbar>
      <MyProfileModal
        profileModalStaus={profileModalStaus}
        setProfileModalStaus={setProfileModalStaus}
        setOpenTerms={setOpenTerms}
      />
      <TermsModals refetch={updatingUserProfile} openTerms={openTerms} setOpenTerms={setOpenTerms} />
      {currentUser?.id !== trueUser?.id && (
        <div className="alert alert-warning" role="alert">
          You (<strong>{trueUser.name || trueUser.email}</strong>) are signed in
          as <strong>{currentUser.name || currentUser.email}</strong>.
          <a onClick={handleStopImpersonate}>To go back to admin click here</a>
        </div>
      )}
    </header>
  );
};

export default Header;
