import React from "react"

const DataPerPage = ({ dataPerPage, setDataPerPage }) => {
    return (
        <>
            <div className="d-flex align-items-baseline">
                <p>Show</p>
                <div className="custom-select-container me-2 ms-2 ali">
                    <select id="custom-select"
                        className="custom-select"
                        onChange={(e) => setDataPerPage(e.target.value)}
                        defaultChecked={dataPerPage}
                    >
                        <option value={10}> 10</option>
                        <option value={25}> 25</option>
                        <option value={50}> 50</option>
                        <option value={100}> 100</option>
                    </select>
                </div>
                <p>entries</p>
            </div>
        </>
    )
}
export default DataPerPage