import React, { useContext } from "react"
import { EditorInfoContext } from "../../../../../../../utis/userInfoContext"
import { keepProportions } from "../../../../../../../hooks/utils";
const PositionSection = ({ tool, handleChange, setTool, errorState }) => {
    const { editElement, setEditElement, pageDimensions } = useContext(EditorInfoContext)

    const blurWidth = () => {
        if (Number(tool?.geometry?.width) > pageDimensions?.width - Number(tool?.geometry?.left)) {
            setTool({
                ...tool, geometry: {
                    ...tool.geometry,
                    width: pageDimensions?.width - tool?.geometry?.left
                }
            })
            if (tool.geometry.keep_proportions) {
                setTool({
                    ...tool, geometry: {
                        ...tool.geometry,
                        height: keepProportions(Number(tool?.geometry?.width), Number(tool?.geometry?.height), editElement?.geometry?.width)
                    }
                })
            }
        } else {
            if (tool.geometry.keep_proportions) {
                setTool({
                    ...tool, geometry: {
                        ...tool.geometry,
                        height: keepProportions(Number(tool?.geometry?.width), Number(tool?.geometry?.height), editElement?.geometry?.width)
                    }
                })
            }
        }
    }

    const blurHeight = () => {
        if (Number(tool?.geometry?.height) > pageDimensions?.height - tool?.geometry?.top) {
            setTool({
                ...tool, geometry: {
                    ...tool.geometry,
                    height: pageDimensions?.height - tool?.geometry?.top,
                    width: pageDimensions?.width - tool?.geometry?.left
                }
            })
        } else {
            if (tool.geometry.keep_proportions) {
                if (Number(tool?.geometry?.width) < pageDimensions?.width - tool?.geometry?.left) {
                    setTool({
                        ...tool, geometry: {
                            ...tool.geometry,
                            width: keepProportions(Number(tool?.geometry?.height), Number(tool?.geometry?.width), editElement?.geometry?.height)
                        }
                    })
                }
            }
        }
    }

    return (
        <>
            <div className="position-section d-flex align-items-center justify-content-between">
                <label className="img-lab-clr">POSITION</label>
                <div className="d-flex position-left-right">
                    <div className="me-3 ">
                        Left: <input
                            type="number"
                            name="geometry.left"
                            value={tool?.geometry?.left}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="top-left-pop">
                        Top:
                        <input
                            type="number"
                            name="geometry.top"
                            value={tool?.geometry?.top}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
            <div className="size-section d-flex justify-content-between cursor-pointer">
                <div className="const-pro">
                    CONSTRAIN PROPORTIONS
                    <input
                        type="checkbox"
                        name='geometry.keep_proportions'
                        value={tool?.geometry?.keep_proportions}
                        checked={tool?.geometry?.keep_proportions}
                        onChange={handleChange}
                    />
                </div>
                <div className="d-flex flex-column">
                    <div className="d-flex align-items-center const-width">
                        <div>
                            Width:
                            <input
                                type="number"
                                name="geometry.width"
                                value={tool?.geometry?.width}
                                onChange={(e) => {
                                    handleChange(e)
                                    // setEditElement({
                                    //     ...editElement,
                                    //     geometry: {
                                    //         ...tool?.geometry,
                                    //         width: e.target.value,
                                    //     }
                                    // })
                                }}
                                onBlur={() => blurWidth()}
                            />
                        </div>
                        <div>
                            Height:
                            <input
                                type="number"
                                name="geometry.height"
                                value={tool?.geometry?.height}
                                onChange={(e) => {
                                    handleChange(e)
                                    // setEditElement({
                                    //     ...editElement,
                                    //     geometry: {
                                    //         ...tool?.geometry,
                                    //         height: e.target.value
                                    //     }
                                    // })
                                }}
                                onBlur={() => blurHeight()}
                            />
                        </div>
                    </div>
                    <p className="text-danger">{errorState?.dimensionError}</p>
                </div>
            </div>
        </>
    )
}
export default PositionSection