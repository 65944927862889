import React, { useEffect, useState } from "react";
import { networkService } from "../../../config/axios";
import CustomTagsInput from "../../../CommonComponents/Tags/CustomTagsInput";

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const KdfFilerForm = ({ triggerApplyFilterEvent, kdfTitleDefault = '' }) => {
  const [kdfTitle, setKdfTitle] = useState(kdfTitleDefault);
  const [kdfTags, setKdfTags] = useState({ name: "", tags: [] });
  const [status, setStatus] = useState("All");
  const [loading, setLoading] = useState(false);
  const [titleSearchList, setTitleSearchList] = useState([]);
  const [tagsSearchList, setTagsSearchList] = useState([]);
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const [isTitleSelected, setIsTitleSelected] = useState(false);
  const [isTagSelected, setIsTagSelected] = useState(false);

  const debouncedKdfTitle = useDebounce(kdfTitle, 1000);
  const debouncedKdfTags = useDebounce(kdfTags, 1000);

  const titleSearch = async () => {
    if (!debouncedKdfTitle || isTitleSelected) return;
    let { data, status } = await networkService(
      `kdfs.json?title=${debouncedKdfTitle}`,
      "GET"
    );
    if (status === 200) {
      setTitleSearchList(data);
    } else {
      setTitleSearchList([]);
    }
  };

  useEffect(() => {
    if (debouncedKdfTitle && !isTitleSelected) {
      titleSearch();
    } else {
      setTitleSearchList([]);
    }
  }, [debouncedKdfTitle, isTitleSelected]);

  const handleKdfTitleChange = (e) => {
    setKdfTitle(e.target.value);
    setIsTitleSelected(false); // Reset flag when typing
  };

  const handleTitleSelect = (item) => {
    setKdfTitle(item.title);
    setTitleSearchList([]);
    setIsTitleSelected(true); // Flag as selected
  };

  const tagsSearch = async () => {
    if (kdfTags?.name !== "" && !isTagSelected) {
      let { data, status } = await networkService(
        `tags.json?tag_name=${kdfTags?.name}&items=doc_list`,
        "GET"
      );
      if (status === 200) {
        setTagsSearchList(data);
      } else {
        setTagsSearchList([]);
      }
      setKdfTags((prev) => ({ ...prev, name: "" }));
    }
  };

  useEffect(() => {
    if (debouncedKdfTags && !isTagSelected) {
      tagsSearch();
    } else {
      setTagsSearchList([]);
    }
  }, [debouncedKdfTags, isTagSelected]);

  const handleKdfTagsInputChange = (e) => {
    setKdfTags((prev) => ({ ...prev, name: e }));
    setIsTagSelected(false); // Reset flag when typing
  };

  const handleTagSelect = (item) => {
    setKdfTags((prev) => ({
      ...prev,
      tags: [...prev.tags, item],
    }));
    setTagsSearchList([]);
    setIsTagSelected(true); // Flag as selected
  };

  const handleStatusChange = (e) => setStatus(e.target.textContent);

  const applyFilters = async (e) => {
    e.preventDefault();
    setLoading(true);
    let payload = {};
    if (kdfTitle !== "") payload["name"] = kdfTitle;
    if (status?.toLocaleLowerCase() === "published")
      payload["published"] = true;
    else if (status?.toLocaleLowerCase() === "unpublished")
      payload["published"] = false;
    else if (status?.toLocaleLowerCase() === "private")
      payload["private_flag"] = true;
    else if (status?.toLocaleLowerCase() === "public")
      payload["private_flag"] = false;
    if (kdfTags?.tags?.length > 0)
      payload["tag_ids"] = kdfTags?.tags?.map((item) => item.id);

    await triggerApplyFilterEvent(payload);
    setLoading(false);
  };

  const resetFilters = () => {
    setKdfTitle("");
    setKdfTags({ name: "", tags: [] });
    setStatus("All");
    triggerApplyFilterEvent();
  };

  return (
    <form className="d-flex kdf-main-list">
      <div className="d-flex gap-3">
        <div className="form-group position-relative">
          <input
            className="kdf-title"
            type="text"
            placeholder="KDF Title"
            value={kdfTitle}
            onChange={handleKdfTitleChange}
          />
          {titleSearchList.length > 0 && (
            <ul className="kdf-title-drop-ul">
              {titleSearchList.map((item, index) => (
                <li
                  key={index}
                  className="cursor-pointer kdf-title-drop-li"
                  onClick={() => handleTitleSelect(item)}
                >
                  {item.title}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="all-btn">
          <div className="dropdown">
            <a
              className="btn btn-secondary dropdown-toggle"
              href="#"
              role="button"
              id="dropdownMenuLink"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {status}
            </a>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <li className="dropdown-item" onClick={handleStatusChange}>
                All
              </li>
              <li className="dropdown-item" onClick={handleStatusChange}>
                Published
              </li>
              <li className="dropdown-item" onClick={handleStatusChange}>
                Unpublished
              </li>
              <li className="dropdown-item" onClick={handleStatusChange}>
                Private
              </li>
              <li className="dropdown-item" onClick={handleStatusChange}>
                Public
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="d-flex">
        <div className="d-flex form-group kdf-list-inp position-relative">
          <CustomTagsInput
            tags={kdfTags?.tags || []}
            handleRemoveTags={(value) =>
              setKdfTags((prev) => ({ ...prev, tags: value }))
            }
            placeholderText={'KDF tags'}
            autoSubmit={true}
            triggerKeyDownEvent={handleKdfTagsInputChange}
          />
          {tagsSearchList.length > 0 && (
            <ul className="kdf-title-drop-ul">
              {tagsSearchList.map((item, index) => (
                <li
                  key={index}
                  className="cursor-pointer kdf-title-drop-li"
                  onClick={() => handleTagSelect(item)}
                >
                  {item.name}
                </li>
              ))}
            </ul>
          )}
        </div>
        <button
          className="btn btn-info margin-left-15 reset"
          onClick={applyFilters}
          disabled={loading}
        >
          {loading ? "Applying Filters" : "Apply Filters"}
        </button>
        <button className="reset-btn" type="button" onClick={resetFilters}>
          Reset Filters
        </button>
      </div>
    </form>
  );
};

export default KdfFilerForm;
