import React, { useEffect, useState } from "react";
import Switch from "../Form/Switch";
import { Col, Row } from "react-bootstrap";
import CustomTooltip from "../Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import Select from "../Select";
import { getTranslation } from "../../../hooks/utils";
import { element } from "prop-types";
import CommonModal from "../../Modals/CommonModal";
import Element from "../KDFElements/Element";
import Input from "../Input";
const PopupOptionsTab = ({
  editLaserLinkItem,
  updateEditLaserLinkItem,
  popupOptions,
  validationTrigger,
  errorListData,
  updateErrorStatus,
  getDocFiles
}) => {
  const [showLightBox, setShowLightBox] = useState(null);
  const renderOption = (data) => {
    return Object.keys(data)?.map((item, index) => (
      <option key={index} value={data[item]}>
        {data[item]}
      </option>
    ));
  };

  const validateInnerData = () => {
    let errorToUpdate = JSON.parse(JSON.stringify(errorListData));
    updateErrorStatus([...errorToUpdate]);
  }

  useEffect(() => {
    if (validationTrigger == true) {
      validateInnerData();
    }
  }, [validationTrigger])

  return (
    <>
      {/* editLaserLinkItem?.enter_pop_settings */}
      <CommonModal
        modalStatus={showLightBox != null}
        changeActiveStatus={(status) => setShowLightBox(null)}
        centered
        lightBox={true}
      >
        {!!showLightBox ? <Element elements={showLightBox ?? {}} /> : ""}
      </CommonModal>

      <div className="Enable-select">
        <Row className="enable-first">
          <>
            <Switch
              type="checkbox"
              checked={editLaserLinkItem?.enter_pop ?? false}
              onChange={(element) => {
                updateEditLaserLinkItem({
                  ...editLaserLinkItem,
                  enter_pop: !!!editLaserLinkItem?.enter_pop,
                });
              }}
              formLabel={"Enable EnterPop"}
            />
          </>
          <>
            {editLaserLinkItem?.enter_pop == true ? (
              <Col md={6} className="popup-option-coloum">
                <Switch
                  type="checkbox"
                  checked={editLaserLinkItem?.default_enter_pop ?? false}
                  onChange={(element) => {
                    updateEditLaserLinkItem({
                      ...editLaserLinkItem,
                      default_enter_pop:
                        !!!editLaserLinkItem?.default_enter_pop,
                    });
                  }}
                  formLabel={
                    <CustomTooltip
                      title={<p className="p-1 text-light">Save settings for new LaserLINX for this KDF</p>}
                    >
                      <span>Set as default</span>
                    </CustomTooltip>
                  }
                />
                {/* <div className="d-flex align-items-center for-space"> */}
                <Row>
                  <Col md={4}>
                    <label htmlFor="display_enter_device_Device">
                      On entry {' '}
                      {!!editLaserLinkItem?.enter_pop_settings?.lightbox && (
                        <CustomTooltip title={<p className="p-1 text-light">Preview</p>}>
                          <FontAwesomeIcon
                            icon={faEye}
                            onClick={() => {
                              if (popupOptions?.enter_pop != undefined) {
                                setShowLightBox(
                                  popupOptions?.enter_pop[
                                    editLaserLinkItem?.enter_pop_settings
                                      ?.lightbox
                                  ]?.elements
                                )
                              }
                            }
                            }
                            className="cursor-pointer"
                          />
                        </CustomTooltip>
                      )}
                    </label>
                  </Col>
                  <Col md={8}>
                    <Select
                      className="mb-2"
                      defaultValue={
                        editLaserLinkItem?.enter_pop_settings?.lightbox ?? ""
                      }
                      placeholder={"Select an EnterPop Lightbox"}
                      onChange={(element) =>
                        updateEditLaserLinkItem({
                          ...editLaserLinkItem,
                          enter_pop_settings: {
                            ...editLaserLinkItem?.enter_pop_settings,
                            lightbox: element.target.value,
                            lightbox_name:
                              popupOptions?.enter_pop[`${element.target.value}`]
                                .name,
                          },
                        })
                      }
                    >
                      {
                        // renderLightBoxOptions()
                        !!popupOptions?.enter_pop
                          ? Object.keys(popupOptions?.enter_pop)?.map(
                            (popKey) =>
                              popupOptions?.enter_pop[popKey].doc_private ==
                              false && (
                                <option
                                  key={popKey}
                                  value={popKey}
                                  title={`KDF: ${popupOptions?.enter_pop[popKey].doc_title} KDF by: ${popupOptions?.enter_pop[popKey].created_user} Lightbox: ${popupOptions?.enter_pop[popKey].lightbox_doc_user} `}
                                >
                                  {/* <CustomTooltip title={
                                        <>
                                            KDF: {popupOptions?.enter_pop[popKey].doc_title} <br />
                                            KDF by: {popupOptions?.enter_pop[popKey].created_user} <br />
                                            Lightbox: {popupOptions?.enter_pop[popKey].lightbox_doc_user}
                                        </>
                                    }> */}
                                  {popupOptions?.enter_pop[popKey].name}
                                  {/* </CustomTooltip> */}
                                </option>
                              )
                          )
                          : ""
                      }
                    </Select>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label htmlFor="display_enter_device_Device">Device</label>
                  </Col>
                  <Col md={8}>
                    <Select
                      className="mb-2"
                      defaultValue={
                        editLaserLinkItem?.enter_pop_settings
                          ?.display_enter_device ?? ""
                      }
                      id="display_enter_device_Device"
                      onChange={(element) =>
                        updateEditLaserLinkItem({
                          ...editLaserLinkItem,
                          enter_pop_settings: {
                            ...editLaserLinkItem?.enter_pop_settings,
                            display_enter_device: element.target.value,
                          },
                        })
                      }
                    >
                      {renderOption(
                        getTranslation("devise.display_device_settings")
                      )}
                    </Select>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label htmlFor="pop_setting_frequency">Frequency</label>
                  </Col>
                  <Col md={8}>
                    <Select
                      defaultValue={
                        editLaserLinkItem?.enter_pop_settings?.frequency ?? ""
                      }
                      id="pop_setting_frequency"
                      onChange={(element) =>
                        updateEditLaserLinkItem({
                          ...editLaserLinkItem,
                          enter_pop_settings: {
                            ...editLaserLinkItem?.enter_pop_settings,
                            frequency: element.target.value,
                          },
                        })
                      }
                    >
                      {renderOption(
                        getTranslation("devise.enter_frequency_settings")
                      )}
                    </Select>
                  </Col>
                </Row>
                {/* </div> */}
              </Col>
            ) : (
              <p>
                Your selected EnterPop will appear immediately after a recipient
                clicks this LaserLINK and before the privacy policy consent
                request is displayed.
              </p>
            )}
          </>
        </Row>
      </div>

      <div className="Enable-select">
        <Row className="enable-first">
          <>
            <Switch
              type="checkbox"
              checked={editLaserLinkItem?.flash_pop ?? false}
              onChange={(element) => {
                updateEditLaserLinkItem({
                  ...editLaserLinkItem,
                  flash_pop: !!!editLaserLinkItem?.flash_pop,
                });
              }}
              formLabel={"Enable TimedPop"}
            />
          </>
          <>
            {editLaserLinkItem?.flash_pop == true ? (
              <Col md={6} className="popup-option-coloum">
                <Switch
                  type="checkbox"
                  checked={editLaserLinkItem?.default_flash_pop ?? false}
                  onChange={(element) => {
                    updateEditLaserLinkItem({
                      ...editLaserLinkItem,
                      default_flash_pop:
                        !!!editLaserLinkItem?.default_flash_pop,
                    });
                  }}
                  formLabel={
                    <CustomTooltip
                      title={<p className="p-1 text-light">Save settings for new LaserLINX for this KDF</p>}
                    >
                      <span>Set as default</span>
                    </CustomTooltip>
                  }
                />
                {/* <div className="d-flex align-items-center for-space"> */}
                <Row>
                  <Col md={4}>
                    <label htmlFor="display_enter_device_Device">
                      Timed {' '}
                      {!!editLaserLinkItem?.flash_pop_settings?.lightbox && (
                        <CustomTooltip title={<p className="p-1 text-light">Preview</p>}>
                          <FontAwesomeIcon
                            icon={faEye}
                            onClick={() =>
                              setShowLightBox(
                                popupOptions?.timed_pop[
                                  editLaserLinkItem?.flash_pop_settings
                                    ?.lightbox
                                ]?.elements
                              )
                            }
                            className="cursor-pointer"
                          />
                        </CustomTooltip>
                      )}
                    </label>
                  </Col>
                  <Col md={8}>
                    <Select
                      className="mb-2"
                      defaultValue={
                        editLaserLinkItem?.flash_pop_settings?.lightbox ?? ""
                      }
                      placeholder={"Select an TimedPop Lightbox"}
                      onChange={(element) =>
                        updateEditLaserLinkItem({
                          ...editLaserLinkItem,
                          flash_pop_settings: {
                            ...editLaserLinkItem?.flash_pop_settings,
                            lightbox: element.target.value,
                            lightbox_name: popupOptions?.timed_pop[`${element.target.value}`].name,
                            seen_seconds: popupOptions?.timed_pop[`${element.target.value}`].show_after,
                            on_page: popupOptions?.timed_pop[`${element.target.value}`].show_on_page,
                          },
                        })
                      }
                    >
                      {
                        // renderLightBoxOptions()
                        !!popupOptions?.timed_pop
                          ? Object.keys(popupOptions?.timed_pop)?.map(
                            (popKey) =>
                              (popupOptions?.timed_pop[popKey].doc_private ==
                                false && (getDocFiles[0]?.num_pages) >= popupOptions?.timed_pop[popKey]?.show_after) && (
                                <option
                                  key={popKey}
                                  value={popKey}
                                  title={`KDF: ${popupOptions?.timed_pop[popKey].doc_title} KDF by: ${popupOptions?.timed_pop[popKey].created_user} Lightbox: ${popupOptions?.timed_pop[popKey].lightbox_doc_user}`}
                                >
                                  {/* <CustomTooltip title={
                                                                        <>
                                                                            KDF: {popupOptions?.timed_pop[popKey].doc_title} <br />
                                                                            KDF by: {popupOptions?.timed_pop[popKey].created_user} <br />
                                                                            Lightbox: {popupOptions?.timed_pop[popKey].lightbox_doc_user}
                                                                        </>
                                                                    }> */}
                                  {popupOptions?.timed_pop[popKey].name}
                                  {/* </CustomTooltip> */}
                                </option>
                              )
                          )
                          : ""
                      }
                    </Select>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label htmlFor="display_flash_device_Device">Device</label>
                  </Col>
                  <Col md={8}>
                    <Select
                      className="mb-2"
                      defaultValue={
                        editLaserLinkItem?.flash_pop_settings
                          ?.display_flash_device ?? ""
                      }
                      id="display_flash_device_Device"
                      onChange={(element) =>
                        updateEditLaserLinkItem({
                          ...editLaserLinkItem,
                          flash_pop_settings: {
                            ...editLaserLinkItem?.flash_pop_settings,
                            display_flash_device: element.target.value,
                          },
                        })
                      }
                    >
                      {renderOption(
                        getTranslation("devise.display_device_settings")
                      )}
                    </Select>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label htmlFor="flash_pop_settings_after">After</label>
                  </Col>
                  <Col md={8} className="mb-2">
                    <span className="cursor-pointer">
                      <input
                        className="edit-box-input"
                        type="number"
                        value={
                          editLaserLinkItem?.flash_pop_settings?.seen_seconds ??
                          0
                        }
                        disabled={true}
                      />
                      <span className="second-text">
                        seconds on page
                      </span>
                      <input
                        className="edit-box-input"
                        type="number"
                        value={
                          editLaserLinkItem?.flash_pop_settings?.on_page ?? 0
                        }
                        disabled={true}
                      />
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label htmlFor="flash_pop_settings_frequency">
                      Frequency
                    </label>
                  </Col>
                  <Col md={8}>
                    <Select
                      className="mb-2"
                      defaultValue={
                        editLaserLinkItem?.flash_pop_settings?.frequency ?? ""
                      }
                      id="flash_pop_settings_frequency"
                      onChange={(element) =>
                        updateEditLaserLinkItem({
                          ...editLaserLinkItem,
                          flash_pop_settings: {
                            ...editLaserLinkItem?.flash_pop_settings,
                            frequency: element.target.value,
                          },
                        })
                      }
                    >
                      {renderOption(
                        getTranslation("devise.enter_frequency_settings")
                      )}
                    </Select>
                  </Col>
                </Row>
                {/* </div> */}
              </Col>
            ) : (
              <p>
                Select a TimedPop to appear after recipients spend a certain
                amount of time viewing the KDF with this LaserLINK.
              </p>
            )}
          </>
        </Row>
      </div>

      <div className="Enable-select">
        <Row className="enable-first">
          <>
            <Switch
              type="checkbox"
              checked={editLaserLinkItem?.exit_pop ?? false}
              onChange={(element) => {
                updateEditLaserLinkItem({
                  ...editLaserLinkItem,
                  exit_pop: !!!editLaserLinkItem?.exit_pop,
                });
              }}
              formLabel={"Enable ExitPop"}
            />
          </>
          <>
            {editLaserLinkItem?.exit_pop == true ? (
              <Col md={6} className="popup-option-coloum">
                <Switch
                  type="checkbox"
                  checked={editLaserLinkItem?.default_exit_pop ?? false}
                  onChange={(element) => {
                    updateEditLaserLinkItem({
                      ...editLaserLinkItem,
                      default_exit_pop: !!!editLaserLinkItem?.default_exit_pop,
                    });
                  }}
                  formLabel={
                    <CustomTooltip
                      title={<p className="p-1 text-light">Save settings for new LaserLINX for this KDF</p>}
                    >
                      <span>Set as default</span>
                    </CustomTooltip>
                  }
                />
                {/* <div className="d-flex align-items-center for-space"> */}
                <Row>
                  <Col md={4}>
                    <label htmlFor="display_enter_device_Device">
                      On exit intent {' '}
                      {!!editLaserLinkItem?.exit_pop_settings?.lightbox && (
                        <CustomTooltip title={<p className="p-1 text-light">Preview</p>}>
                          <FontAwesomeIcon
                            icon={faEye}
                            onClick={() =>
                              setShowLightBox(
                                popupOptions?.exit_pop[
                                  editLaserLinkItem?.exit_pop_settings?.lightbox
                                ]?.elements
                              )
                            }
                            className="cursor-pointer"
                          />
                        </CustomTooltip>
                      )}
                    </label>
                  </Col>
                  <Col md={8}>
                    <Select
                      className="mb-2"

                      defaultValue={
                        editLaserLinkItem?.exit_pop_settings?.lightbox ?? ""
                      }
                      placeholder={"Select an ExitPop Lightbox"}
                      onChange={(element) =>
                        updateEditLaserLinkItem({
                          ...editLaserLinkItem,
                          exit_pop_settings: {
                            ...editLaserLinkItem?.exit_pop_settings,
                            lightbox: element.target.value,
                            lightbox_name:
                              popupOptions?.exit_pop[`${element.target.value}`]
                                .name,
                          },
                        })
                      }
                    >
                      {
                        // renderLightBoxOptions()
                        !!popupOptions?.exit_pop
                          ? Object.keys(popupOptions?.exit_pop)?.map(
                            (popKey) =>
                              popupOptions?.exit_pop[popKey].doc_private ==
                              false && (
                                <option
                                  key={popKey}
                                  value={popKey}
                                  title={`KDF: ${popupOptions?.exit_pop[popKey].doc_title} KDF by: ${popupOptions?.exit_pop[popKey].created_user} Lightbox: ${popupOptions?.exit_pop[popKey].lightbox_doc_user}`}
                                >
                                  {/* <CustomTooltip title={
                                                                        <>
                                                                            KDF: {popupOptions?.exit_pop[popKey].doc_title} <br />
                                                                            KDF by: {popupOptions?.exit_pop[popKey].created_user} <br />
                                                                            Lightbox: {popupOptions?.exit_pop[popKey].lightbox_doc_user}
                                                                        </>
                                                                    }> */}
                                  {popupOptions?.exit_pop[popKey].name}
                                  {/* </CustomTooltip> */}
                                </option>
                              )
                          )
                          : ""
                      }
                    </Select>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label htmlFor="display_exit_device_Device">Device</label>
                  </Col>
                  <Col md={8}>
                    <Select
                      className="mb-2"

                      defaultValue={
                        editLaserLinkItem?.exit_pop_settings?.display_device ??
                        ""
                      }
                      id="display_exit_device_Device"
                      onChange={(element) =>
                        updateEditLaserLinkItem({
                          ...editLaserLinkItem,
                          exit_pop_settings: {
                            ...editLaserLinkItem?.exit_pop_settings,
                            display_device: element.target.value,
                          },
                        })
                      }
                    >
                      {renderOption(
                        getTranslation("devise.display_device_settings")
                      )}
                    </Select>
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <label htmlFor="exit_pop_settings_scroll">
                      On scroll up
                    </label>
                  </Col>
                  <Col md={8}>
                    <Select
                      className="mb-2"
                      defaultValue={
                        editLaserLinkItem?.flash_pop_settings?.display_scroll ??
                        ""
                      }
                      id="exit_pop_settings_scroll"
                      onChange={(element) =>
                        updateEditLaserLinkItem({
                          ...editLaserLinkItem,
                          flash_pop_settings: {
                            ...editLaserLinkItem?.flash_pop_settings,
                            display_scroll: element.target.value,
                          },
                        })
                      }
                    >
                      {renderOption(
                        getTranslation("devise.display_scroll_settings")
                      )}
                    </Select>
                  </Col>
                </Row>
                {/* </div> */}
              </Col>
            ) : (
              <p>
                Choose an Exit Pop to appear when a recipient signals exit
                intent while viewing the KDF with this LaserLINK.
              </p>
            )}
          </>
        </Row>
      </div>
    </>
  );
};
export default PopupOptionsTab;
