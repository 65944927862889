import React, { useContext, useEffect, useRef, useState } from "react";
import { EditorInfoContext } from "../../../../../../../utis/userInfoContext";
import KdfActionModal from "../../../../../../Modals/EditorModals/KdfActionModal";
import { networkService } from "../../../../../../config/axios";
const ClickActionSection = ({
  tool,
  handleChange,
  totalPages,
  currentDocId,
  setTool,
  errorState
}) => {
  const [openKdfModal, setOpenKdfModal] = useState(false);
  const [kdfsOptions, setKdfsOptions] = useState({
    kdfList: [],
    selected: false,
  });
  const [clickOption, setClickOption] = useState([]);
  const [laserlinxOptions, setLaserLinxOptions] = useState({
    laserLinxs: [],
    selected: false
  })
  const { elements } = useContext(EditorInfoContext);
  const arrayOfElements = Object.values(
    Object.entries(elements).length !== 0 && elements
  );
  const hasOpenedModalRef = useRef(tool?.value?.kdf_title?.length > 0);

  useEffect(() => {
    if (tool?.action === "kdf" && !hasOpenedModalRef.current) {
      setOpenKdfModal(true);
      hasOpenedModalRef.current = true;
    }
  }, [tool]);

  useEffect(() => {
    if (
      window?.objInit?.lightBoxes?.click &&
      Object.keys(window?.objInit?.lightBoxes?.click).length > 0
    ) {
      setClickOption(Object.values(window?.objInit?.lightBoxes?.click));
    }
  }, []);

  const fetchlaserlinxs = async (docId) => {
    const res = await networkService(
      `links/get_doc_links.json?title=${tool?.value?.link_title}&doc_id=${docId}`,
      "GET"
    );
    setLaserLinxOptions({ ...laserlinxOptions, laserLinxs: res?.data?.tracking_links })
  }

  useEffect(() => {
    const fetchKdfs = async () => {
      const response = await networkService(
        `kdfs.json?title=${tool?.value?.kdf_title}`,
        "GET"
      );
      setKdfsOptions({
        ...kdfsOptions,
        kdfList: response?.data?.filter(
          (val) =>
            val?.id !== currentDocId &&
            val.tracking_link !== null &&
            val.tracking_link.kdf_action_link
        )
      }

      );
    };
    if (tool?.value?.kdf_title) {
      fetchKdfs();
    }
    if (tool?.value?.link_title && !tool?.id) {
      fetchlaserlinxs(tool?.value?.kdf)
    }
    if (tool?.value?.link_title) {
      setLaserLinxOptions({ ...laserlinxOptions, selected: false })
    }

  }, [tool]);

  const linkToKdf = (kdf) => {
    setTool({
      ...tool,
      kdf: kdf?.title,
      value: {
        ...tool?.value,
        kdf: kdf?.id,
        kdf_title: kdf?.title_truncated,
        link: kdf?.tracking_link?.id,
        url: `${kdf?.tracking_link?.domain}/${kdf?.tracking_link?.token}/${kdf?.tracking_link?.language}`,
        timerField: "ViewPort",
        page_link: null
      },
    });

    setKdfsOptions({
      ...kdfsOptions,
      selected: true,
    });

    setLaserLinxOptions({
      ...laserlinxOptions,
      selected: false
    })

    fetchlaserlinxs(kdf?.id)
  };

  const linkToLaserLink = (linkTitle) => {
    setTool({
      ...tool,
      value: {
        ...tool?.value,
        link_title: linkTitle
      },
    });
    setLaserLinxOptions({ laserLinxs: [], selected: true })
  }

  return (
    <>
      {
        <KdfActionModal
          openKdfModal={openKdfModal}
          closeModal={setOpenKdfModal}
        />
      }
      <div className="animation-select viewport-main pb-4">
        <h5>ACTIONS (WHEN CLICKING ON ELEMENT {tool?.id})</h5>
        <div className="d-flex viewport-main mt-2">
          <div className="element-actions__option no-act mt-2 d-flex align-items-center">
            <div className="d-flex">
              <input
                className="me-2"
                type="radio"
                name="action"
                value="none"
                checked={tool?.action === "none"}
                onChange={handleChange}
              />
              <label className='img-lab-clr' htmlFor="no-action">No action</label>
            </div>
          </div>
          <div className="element-actions__option mt-2 d-flex align-items-center">
            <div className="d-flex">
              <input className="me-2" type="checkbox" name="scrolling" />
              <label className='img-lab-clr' htmlFor="reactivate-scrolling">Reactivate Scrolling</label>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="element-actions__option mt-3 ">
            <div className="d-flex mb-1">
              <input
                type="radio"
                name="action"
                value="desiredaction"
                checked={
                  tool?.action === "desiredaction" || (tool?.dropdown_data && Object.keys(tool.dropdown_data).length > 0)
                }
                onChange={handleChange}
              />
              <label className='img-lab-clr' htmlFor="hide-reveal">
                Hide / Reveal<span title="Tooltip content">?</span>
              </label>
            </div>
            <select
              className="animation-selector"
              name="desiredaction"
              onChange={handleChange}
              defaultValue={"hide"}
              value={tool?.dropdown_data?.desiredaction}
            >
              <option
                value="hide"
              >
                Hide
              </option>
              <option
                value="reveal"
              >
                Reveal
              </option>
              <option
                value="toggleOpacity"
              >
                Toggle Opacity
              </option>
            </select>
          </div>
          <div className="element-actions__option mt-3 ">
            <div className="d-flex mb-1">
              <label className='img-lab-clr'>Target Element</label>
            </div>
            <select
              className="animation-selector"
              name="thisElement"
              onChange={handleChange}
              value={tool?.dropdown_data?.thisElement}
            >
              <option value="thisElement">
                {"P" + tool?.page} This Element
              </option>
              {arrayOfElements?.map((element, index) => (
                element?.id != tool?.id &&
                <option
                  value={element?.id}
                  key={index}
                  defaultValue={element?.id == tool?.dropdown_data?.thisElement}
                >
                  {"P" +
                    element.page +
                    " " +
                    element.element_type_name +
                    " " +
                    element.id}
                </option>
              ))}
            </select>
          </div>
          <div className="element-actions__option mt-3 ">
            <div className="d-flex mb-1">
              <label className='img-lab-clr' htmlFor="hide-reveal">
                Frequency <span title="Tooltip content">?</span>
              </label>
            </div>
            <select
              className="animation-selector"
              name="frequency_animation"
              onChange={handleChange}
              value={tool?.dropdown_data?.frequency}
            >
              <option
                value="oncePerKDF"
              >
                Once per KDF
              </option>
              <option
                value="oncePerLaserLINK"
              >
                Once per LaserLINK
              </option>
              <option
                value="oncePerVisit"
              >
                Once per Visit
              </option>
              <option
                value="OnEveryClick"
              >
                On Every click
              </option>
            </select>
          </div>
          {/* desiredActionLightBoxDropdown */}
        </div>
        <div className="element-actions__option d-flex align-items-center mt-2">
          <div className="d-flex link-to-jumpto">
            <input
              className="me-2"
              type="radio"
              name="action"
              value="page"
              checked={tool?.action === "page" || !!tool?.value.page_link}
              onChange={handleChange}
            />
            <label className='img-lab-clr'>Jump to page:</label>
          </div>
          <input
            type="number"
            name="jump_to_page"
            value={tool?.jump_to_page ?? tool?.value?.page_link}
            onChange={handleChange}
            min={1}
          />
          <span> / {totalPages}</span>
          <p className="text-danger text-end">{errorState?.pageErr}</p>
        </div>
        <div className="element-actions__option d-flex align-items-center mt-2">
          <div className="link-to-editor">
            <input
              type="radio"
              name="action"
              value="href"
              onChange={handleChange}
              checked={tool?.action === "href" || !!tool?.value?.url}
            />
            <label className='img-lab-clr'>Link to:</label>
          </div>

          <input
            className="url-inp w-100"
            value={tool?.value?.kdf ? tool?.href : tool?.value?.url}
            onChange={handleChange}
            name="href"
            placeholder="Enter URL,E-Mail or Phone"
          />
        </div>
        <div className="element-actions__option d-flex align-items-center mt-2">
          <div className="link-to-Reveal">
            <input type="radio"
              name="action"
              checked={tool?.action === "on"}
              onChange={handleChange}
            />
            <label className='img-lab-clr'>Reveal ClickPop:</label>
          </div>
          <select
            className="element-actions-select sel-clickpop w-100"
            name="light_box"
            disabled={clickOption.length < 1}
            value={tool?.value?.light_box}
            onChange={handleChange}
          >
            <option value="">Select a ClickPop lightbox</option>
            {clickOption?.map((click) => (
              <option key={click?.id} value={click?.id}>
                {click?.name}
              </option>
            ))}
          </select>
        </div>
        <div className="element-actions__option d-flex align-items-center mt-2">
          <div className="link-to-Reveal">
            <input
              type="radio"
              name="action"
              value="kdf"
              checked={tool?.action === "kdf" || tool?.value?.kdf_title?.length > 0}
              onChange={handleChange}
            />
            <label className='img-lab-clr'>Link to KDF:</label>
          </div>
          <div className="position-relative w-100">
            <input
              className="url-inp w-100"
              placeholder="Enter Kdf title here"
              name="kdf_title"
              value={tool?.value?.kdf_title}
              onChange={handleChange}
            />
            {
              !kdfsOptions?.selected && kdfsOptions?.kdfList?.length > 0 &&
              <ul className="kdf-title-drop-ul">
                {tool?.action == 'kdf' && kdfsOptions?.kdfList?.map((elem, index) => {
                  return (
                    <li
                      key={index}
                      className="cursor-pointer kdf-title-drop-li"
                      onClick={() => linkToKdf(elem)}
                    >
                      {elem?.title}
                    </li>
                  );
                })}
              </ul>
            }
          </div>
        </div>
        {
          kdfsOptions?.selected &&
          <div className="d-flex justify-content-end mt-2">
            <div className="position-relative laserlink-input text-end">
              <input
                className="url-inp w-100"
                placeholder="Enter Laser link title here"
                name="link_title"
                value={tool?.value?.link_title}
                onChange={handleChange}
              />
              {(!tool?.value?.link_title && !laserlinxOptions?.selected) && laserlinxOptions?.laserLinxs?.length > 0 &&
                <ul className="kdf-title-drop-ul">
                  {laserlinxOptions?.laserLinxs?.map((elem, index) => {
                    return (
                      <li
                        key={index}
                        className="cursor-pointer kdf-title-drop-li"
                        onClick={() => linkToLaserLink(elem?.name)}
                        dangerouslySetInnerHTML={{
                          __html: elem?.name_with_language ?? "Laserlink name",
                        }}
                      >
                      </li>
                    );
                  })}
                </ul>
              }
            </div>
          </div>
        }
      </div>
    </>
  );
};
export default ClickActionSection;
