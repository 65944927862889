import React, { useState, useRef, useContext, useEffect } from "react";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import TagEditorSection from "../TagEditorSection";
import { EditorInfoContext } from "../../../../../utis/userInfoContext";
import useStatHook from "../../../Stats/StatsHook/useStatHook";
import { dispatchEvent } from "../../../../../utis/helper";
import PdfCanvas from "./PdfCanvas";
import PdfElementsList from "./PdfElementsList";

const PdfViewer = ({
  pdfFile,
  zoom,
  docFileId,
  docId,
  editortype,
  pageNum,
  docFiles,
  timerOnDoc,
  onLoadSuccess,
  showToolbar,
}) => {
  const { editEnabled, setRectangles, setPageDimensions } =
    useContext(EditorInfoContext);
  const { sendScrollInfo } = useStatHook() || {};
  const containerRef = useRef(null);
  const canvasRefs = useRef([]);
  const [numPages, setNumPages] = useState(null);
  const [renderedPages, setRenderedPages] = useState(0);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setRenderedPages(0);
    setRectangles(new Array(numPages).fill([]));
  };

  const onPageRenderSuccess = () => {
    setRenderedPages((prev) => prev + 1);
  };

  const onScroll = () => {
    const wrapper = containerRef.current;
    const pageHeight = wrapper.scrollHeight / numPages;
    const scrollPosition = wrapper.scrollTop + wrapper.clientHeight / 2;
    const newPage = Math.ceil(scrollPosition / pageHeight);
    if (newPage > 0 && newPage <= numPages) {
      const input = document.getElementById("pageNumber");
      if (newPage != input.value) {
        sendScrollInfo(newPage);
      }
      dispatchEvent("change", "pageNumber");
      input.value = newPage;
    }
  };

  useEffect(() => {
    if (renderedPages === numPages && onLoadSuccess) {
      onLoadSuccess();
    }
  }, [renderedPages, numPages]);

  useEffect(() => {
    const wrapper = containerRef.current;
    if (wrapper && numPages > 1) {
      wrapper.addEventListener("scroll", onScroll);
    }

    return () => {
      if (wrapper) {
        wrapper.removeEventListener("scroll", onScroll);
      }
    };
  }, [numPages]);

  useEffect(() => {
    setPageDimensions({
      height: canvasRefs?.current[0]?.height,
      width: canvasRefs?.current[0]?.width,
    });
  }, [zoom]);

  return (
    <div
      className="parent_doc_wrapper"
      id="parent_doc_wrapper"
      style={{
        height: `${showToolbar == false ? "100vh" : "calc(100vh - 65px)"}`,
        overflowY: "scroll",
      }}
      ref={containerRef}
    >
      <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <div
            className={`d-flex mb-2 justify-content-center`}
            key={index + 1}
            style={{ ...(editortype !== "editor" && { display: "flex" }) }}
          >
            {editortype === "editor" && (
              <TagEditorSection
                docFileId={docFileId}
                numPages={numPages}
                docId={docId}
                docFiles={docFiles}
                currentPage={index + 1}
              />
            )}
            <div
              style={{
                position: "relative",
                cursor: `${editEnabled ? "crosshair" : "default"}`,
              }}
            >
              <Page
                id={`'pageContainer'${+index + 1}`}
                pageNumber={index + 1}
                scale={parseFloat(zoom?.value)}
                onRenderSuccess={onPageRenderSuccess}
                onLoadSuccess={({ width, height }) => {
                  let canvas = canvasRefs.current[index];
                  canvas.width = width;
                  canvas.height = height;
                }}
                inputRef={(ref) => {
                  if (ref && pageNum === index + 1 && !editEnabled) {
                    ref.scrollIntoView({ behavior: "instant" });
                  }
                }}
              />
              <PdfCanvas canvasRefs={canvasRefs} index={index} />
              <PdfElementsList
                timerOnDoc={timerOnDoc}
                zoom={zoom}
                docFileId={docFileId}
                index={index}
                canvasRefs={canvasRefs}
                editortype={editortype}
              />
            </div>
          </div>
        ))}
      </Document>
    </div>
  );
};

export default PdfViewer;
