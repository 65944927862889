import React, { useContext, useEffect, useRef, useState } from "react";
import CustomTooltip from "../../Elements/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faPen,
  faPenToSquare,
  faShuffle,
  faTrashCan,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Accordion, Col, Row, Table } from "react-bootstrap";
import Switch from "../../Elements/Form/Switch";
import styles from "./links.module.css";
import getRandomString from "../../../utis/getRandomString";
import Select from "../../Elements/Select";
import Input from "../../Elements/Input";
import TextArea from "../../Elements/Form/TextArea";
import Button from "../../Elements/Button";
import customAPIRequest from "../../../utis/customAPIRequest";
import CommonModal from "../../Modals/CommonModal";
import { networkService } from "../../config/axios";
import TextEditorSection from "../../Pages/Kdf/EditKdf/elementModal/elementSections/textEditorSection/TextEditorSection";

const AccessGateOptionsTab = ({
  editLaserLinkItem,
  updateEditLaserLinkItem,
  time_zone_list,
  getDocFiles,
  defaultLinkTexts,
  updateDefaultLink,
  validationTrigger,
  updateErrorStatus,
  errorListData,
  getProfile
}) => {
  const [individualCodeStatus, setIndividualCodeStatus] = useState(false);
  const [expirationDateStatus, setExpirationDateStatus] = useState(false);
  const [editTextMessage, setEditTextMessage] = useState(false);
  const [deleteTextMessage, setDeleteTextMessage] = useState(null);
  const [accordinActiveKey, setAccordinActiveKey] = useState(null);
  const [customTextScope, setCustomTextScope] = useState('initial');
  const [editTextMessageData, setEditTextMessageData] = useState(null);
  // const { userInfoData } = useContext(UserInfoContext);
  // const [defaultTextData, setDefaultTextData] = useState(default_link_texts ?? []);
  useEffect(() => {
    if (editLaserLinkItem?.password != null) {
      setIndividualCodeStatus(true);
    }
    if (editLaserLinkItem?.expiry != null) {
      setExpirationDateStatus(true)
    }
  }, [])

  useEffect(() => {
    updateEditLaserLinkItem({
      ...editLaserLinkItem,
      accessGateOptionStatus: (individualCodeStatus || expirationDateStatus),
    });
    //has_optin
  }, [individualCodeStatus, expirationDateStatus])

  const validateInnerData = () => {
    let errorToUpdate = JSON.parse(JSON.stringify(errorListData));
    updateErrorStatus([...errorToUpdate]);
  }

  useEffect(() => {
    if (validationTrigger == true) {
      validateInnerData();
    }
  }, [validationTrigger])

  const updateTestMessageData = async () => {
    let editTextMessageDataCopy = JSON.parse(JSON.stringify(editTextMessageData));
    delete editTextMessageDataCopy.errors;
    let updateMessage = await customAPIRequest('links/default_text.json', 'POST', editTextMessageDataCopy);
    if (updateMessage?.data != undefined) {
      updateDefaultLink(updateMessage?.data?.default_link_texts);
      setEditTextMessageData(null)
    }
  }

  const handeUpdateTextMessage = () => {
    if (editTextMessageData != null) {
      let errorsData = [];
      if (
        editTextMessageData?.title == "" ||
        editTextMessageData?.title == undefined
      ) {
        errorsData.push("title");
      }
      if (
        editTextMessageData?.message == "" ||
        editTextMessageData?.message == undefined
      ) {
        errorsData.push("message");
      }
      setEditTextMessageData((prevState) => ({
        ...prevState,
        errors: errorsData,
      }));
      if (errorsData?.length == 0) {
        updateTestMessageData();
      }
    } else {
      setEditTextMessageData((prevState) => ({
        ...prevState,
        errors: ["title", "message"],
      }));
    }
  };

  const handleDeleteTextMessageAction = async () => {
    let deleteMessageAction = await networkService(
      `links/delete_default_text/${deleteTextMessage?.id}.json`,
      "DELETE"
    );
    if (deleteMessageAction?.data.status == "ok") {
      updateDefaultLink(deleteMessageAction?.data?.default_link_texts);
      setEditTextMessageData(null);
    }
  };

  const handleSetDefaultTest = async (itemID) => {
    let setDefaultText = await networkService(
      `links/default_text.json`,
      "POST",
      { is_def_txt: true, id: itemID }
    );
    if (setDefaultText?.data.status == "ok") {
      updateDefaultLink(setDefaultText?.data?.default_link_texts);
      setEditTextMessageData(null);
    }
  };
  if (editTextMessage) {
    return (
      <>
        <CommonModal
          title={"Delete Text Message"}
          modalStatus={deleteTextMessage != null}
          changeActiveStatus={() => setDeleteTextMessage(null)}
          centered
          classList={"alert-modal"}
          footer={
            <div className="d-flex m-auto">
              <Button
                text={"No"}
                classList={"alert delete-btn"}
                addonClass={"my-2 mx-2"}
                onClick={() => setDeleteTextMessage(null)}
              />
              <Button
                text={"Yes"}
                classList={"success delete-btn"}
                addonClass={"my-2 mx-2"}
                onClick={handleDeleteTextMessageAction}
              />
            </div>
          }
        >
          <p style={{ fontSize: "14px" }}>Are you sure?</p>
        </CommonModal>
        <div className="">
          <p
            className="custom-link-success cursor-pointer back-pop"
            onClick={() => {
              setEditTextMessage(false);
              setEditTextMessageData(null);
            }}
          >
            <FontAwesomeIcon icon={faAngleLeft} /> Back
          </p>
          <Accordion defaultActiveKey="0" activeKey={`${accordinActiveKey}`}>
            <Accordion.Item className="mb-2" eventKey="0">
              <Accordion.Header
                id="cross-drop"
                className="bg-light insert-drop"
              >
                <span onClick={() => setAccordinActiveKey(0)}>
                  Create a New Text Message
                </span>
                <FontAwesomeIcon onClick={() => setAccordinActiveKey(null)} icon={faXmark} />
                {/* <i class="fa-solid fa-xmark"></i> */}
              </Accordion.Header>
              <div className="row">
                <div className="col-md-6">
                  <Accordion.Body className="bg-white message-title-popup">
                    <Input
                      type={"text"}
                      placeholder={"Please enter text title here"}
                      label={"Title:"}
                      value={editTextMessageData?.title ?? ""}
                      onChange={(element) =>
                        setEditTextMessageData((prevState) => ({
                          ...prevState,
                          title: element.target.value,
                        }))
                      }
                      errorMessageStatus={editTextMessageData?.errors?.includes(
                        "title"
                      )}
                      errorMessage={
                        editTextMessageData?.errors?.includes("title") &&
                        "Title can't be blank"
                      }
                    />
                    <TextArea
                      placeholder={"Please enter text message here"}
                      label={"Message:"}
                      value={editTextMessageData?.message ?? ""}
                      onChange={(element) =>
                        setEditTextMessageData((prevState) => ({
                          ...prevState,
                          message: element.target.value,
                        }))
                      }
                      errorMessageStatus={editTextMessageData?.errors?.includes(
                        "message"
                      )}
                      errorMessage={
                        editTextMessageData?.errors?.includes("message") &&
                        "Message can't be blank"
                      }
                    />
                    <Button
                      classList={"laser-link-btn"}
                      className="mb-2 mt-2 save-text-btn"
                      text={"Save This Text"}
                      onClick={handeUpdateTextMessage}
                    />
                  </Accordion.Body>
                </div>
              </div>
            </Accordion.Item>
            <Accordion.Item className="mb-2" eventKey="1">
              <Accordion.Header
                id="cross-drop"
                className="bg-light insert-drop"
              >
                <span onClick={() => setAccordinActiveKey(1)}>
                  Existing Text Messages
                </span>
                <FontAwesomeIcon onClick={() => setAccordinActiveKey(null)} icon={faXmark} />
                {/* <i class="fa-solid fa-xmark"></i> */}

              </Accordion.Header>
              <Accordion.Body className="bg-white">
                <Table className="kdf-set-table Existing-text" bordered>
                  <thead>
                    <tr>
                      <th>Set Default</th>
                      <th>Nr.</th>
                      <th>Title</th>
                      <th>Message</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {defaultLinkTexts?.length > 0 ? (
                      defaultLinkTexts?.map((item, index) => (
                        <tr key={item.id}>
                          <td>
                            <input
                              type="radio"
                              name="is_default"
                              value={item.id}
                              chacked={item?.is_default ?? false}
                              onClick={(element) =>
                                handleSetDefaultTest(element.target.value)
                              }
                            />
                          </td>
                          <td>{index + 1}</td>
                          <td>{item.title}</td>
                          <td>{item.message}</td>
                          <td>
                            <FontAwesomeIcon
                              icon={faPen}
                              className="cursor-pointer"
                              onClick={() => {
                                setEditTextMessageData(item);
                                setAccordinActiveKey(0);
                              }}
                            />{" "}
                            |{" "}
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              className="cursor-pointer cancel"
                              onClick={() => setDeleteTextMessage(item)}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>No data found</tr>
                    )}
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </>
    );
  }
  return (
    <div className="laser-popup-pages">
      <div className="laser-top">
        <Row className="laser-email-main mt-3">
          <Col md={3}>
            <CustomTooltip
              title={<p className="p-1 text-light">Use this option to protect your KDF with a password</p>}
            >
              <p>Invitation code</p>
            </CustomTooltip>
          </Col>
          <Col md={9}>
            <div className="d-flex switchs">
              <Switch
                checked={individualCodeStatus}
                onChange={(element) => {
                  if (individualCodeStatus == true) {
                    updateEditLaserLinkItem({
                      ...editLaserLinkItem,
                      password: null,
                    });
                  }
                  setIndividualCodeStatus(!individualCodeStatus);
                }}
              />
              {individualCodeStatus && (
                <>
                  <CustomTooltip
                    title={
                      <>
                        Invitation code (click{" "}
                        <FontAwesomeIcon icon={faShuffle} /> to generate)
                      </>
                    }
                  >
                    <input
                      type={"text"}
                      placeholder={"Enter your invitation code here"}
                      value={editLaserLinkItem?.password ?? ""}
                      onChange={(element) => {
                        updateEditLaserLinkItem({
                          ...editLaserLinkItem,
                          password: element.target.value,
                        });
                      }}
                      className="form-control stuffle-inp"
                    />
                  </CustomTooltip>
                  <FontAwesomeIcon
                    icon={faShuffle}
                    className={`btn ${styles.invitationButton}`}
                    onClick={() => {
                      updateEditLaserLinkItem({
                        ...editLaserLinkItem,
                        password: getRandomString(8),
                      });
                    }}
                  />
                </>
              )}
            </div>
          </Col>
        </Row>
        <Row className="laser-email-main mt-3">
          <Col md={3}>
            <p>Expiration date</p>
          </Col>
          <Col md={9} className="d-flex align-items-center">
            <Switch
              checked={expirationDateStatus}
              onChange={(element) => {
                setExpirationDateStatus((prevState) => !prevState);
              }}
            />
            {expirationDateStatus && (
              <div
                id="access-calander"
                className={styles.expirationDateTimeWrapper}
              >
                <CustomTooltip title={<p className="p-1 text-light">Expires on</p>}>
                  {/* <input type="text" value={editLaserLinkItem?.expiry ?? ''} disabled={true} onChange={() => {}} /> */}
                  <input
                    type="date"
                    value={
                      editLaserLinkItem?.expiry != undefined
                        ? new Date(editLaserLinkItem?.expiry)
                          .toISOString()
                          .split("T")[0]
                        : ""
                    }
                    onChange={(element) => {
                      updateEditLaserLinkItem({
                        ...editLaserLinkItem,
                        expiry: `${new Date(element.target.value)
                          .toISOString()
                          .split("T")[0]
                          }T18:30:00.000Z`,
                        'expire_time': (editLaserLinkItem?.expire_time ?? '0:0')
                      });
                    }}
                    min={new Date().toISOString().split('T')[0]}
                  />
                </CustomTooltip>
                <div className="d-flex align-items-center ms-3 laser-inp">
                  <input
                    className="edit-value-inp ms-1 me-1"
                    value={
                      editLaserLinkItem?.expire_time != null
                        ? editLaserLinkItem?.expire_time?.split(":")[0]
                        : "00"
                    }
                    onChange={(element) => {
                      let valueToAdd =
                        element?.target?.value != undefined &&
                        (Number(element?.target?.value) <= 0
                          ? "0"
                          : Number(element?.target?.value) >= 23
                            ? "23"
                            : element.target.value);
                      let newValue = `${valueToAdd}:${editLaserLinkItem?.expire_time != null
                        ? editLaserLinkItem?.expire_time?.split(":")[1]
                        : "00"
                        }`;
                      updateEditLaserLinkItem({
                        ...editLaserLinkItem,
                        expire_time: newValue,
                      });
                    }}
                  />
                  <p>:</p>
                  <input
                    className="edit-value-inp ms-1 me-1"
                    value={
                      editLaserLinkItem?.expire_time != null
                        ? editLaserLinkItem?.expire_time?.split(":")[1]
                        : "00"
                    }
                    onChange={(element) => {
                      let valueToAdd =
                        element?.target?.value != undefined &&
                        (Number(element?.target?.value) <= 0
                          ? "0"
                          : Number(element?.target?.value) >= 59
                            ? "59"
                            : element.target.value);
                      let newValue = `${editLaserLinkItem?.expire_time != null
                        ? editLaserLinkItem?.expire_time?.split(":")[0]
                        : "00"
                        }:${valueToAdd}`;
                      updateEditLaserLinkItem({
                        ...editLaserLinkItem,
                        expire_time: newValue,
                      });
                    }}
                  />
                  <Select
                    optionList={time_zone_list?.map((item) => ({
                      ...item,
                      title: item.name,
                      value: item.name,
                    }))}
                    onChange={(element) =>
                      updateEditLaserLinkItem({
                        ...editLaserLinkItem,
                        expire_time_zone: element.target.value,
                      })
                    }
                    defaultValue={editLaserLinkItem?.expire_time_zone ?? ""}
                  />
                </div>
              </div>
            )}
          </Col>
        </Row>
        <Row className="laser-email-main mt-3">
          <Col md={3}>
            <p>Enable opt-in</p>
          </Col>
          <Col md={9} className="d-flex justify-content-between">
            <Switch
              checked={editLaserLinkItem?.has_optin ?? false}
              onChange={(element) =>
                updateEditLaserLinkItem({
                  ...editLaserLinkItem,
                  has_optin: !!!editLaserLinkItem?.has_optin,
                })
              }
            />
            {!!editLaserLinkItem?.has_optin && (
              <div className="d-flex">
                <div className="d-flex">
                  <label className="mx-2">Full name</label>
                  <Switch
                    checked={editLaserLinkItem?.opt_in?.ask_name ?? false}
                    onChange={(element) =>
                      updateEditLaserLinkItem({
                        ...editLaserLinkItem,
                        opt_in: {
                          ...editLaserLinkItem?.opt_in,
                          ask_name: !!!editLaserLinkItem?.opt_in?.ask_name,
                        },
                      })
                    }
                  />
                </div>
                <div className="d-flex">
                  <label className="mx-2">Tel nr.</label>
                  <Switch
                    checked={
                      editLaserLinkItem?.opt_in?.ask_telephone_number ?? false
                    }
                    onChange={(element) =>
                      updateEditLaserLinkItem({
                        ...editLaserLinkItem,
                        opt_in: {
                          ...editLaserLinkItem?.opt_in,
                          ask_telephone_number:
                            !!!editLaserLinkItem?.opt_in?.ask_telephone_number,
                        },
                      })
                    }
                  />
                </div>
              </div>
            )}
          </Col>
        </Row>
      </div>
      {!!editLaserLinkItem?.has_optin && (
        <Row>
          <p className="mb-2 mt-3">OPT-IN settings:</p>
          <div className="d-flex">
            <Switch
              className="apper-page"
              checked={editLaserLinkItem?.optin_2nd_page_viewed == true}
              onClick={(element) =>
                editLaserLinkItem?.optin_2nd_page_viewed == false &&
                updateEditLaserLinkItem({
                  ...editLaserLinkItem,
                  optin_2nd_page_viewed: true,
                })
              }
              formLabel={
                <>
                  Appear on {getDocFiles[0]?.num_pages <= 2 ? getDocFiles[0]?.num_pages : 2}
                  <sup>nd</sup> viewed page
                </>
              }
              type="radio"
              name="optin_page"
              id="optin_page_2nd"
            />
          </div>
          <div className="d-flex">
            {/* <label className="mx-2">Tel nr.</label> */}
            <Switch
              className="apper-after"
              checked={editLaserLinkItem?.optin_2nd_page_viewed == false}
              onClick={(element) =>
                editLaserLinkItem?.optin_2nd_page_viewed == true &&
                updateEditLaserLinkItem({
                  ...editLaserLinkItem,
                  optin_2nd_page_viewed: false,
                })
              }
              type="radio"
              name="optin_page"
              id='notify-kdf-radio-after_seconds'
              formLabel={
                <span className="cursor-pointer">
                  Appear on page:
                  <CustomTooltip
                    title={<p className="p-1 text-light">Please enter a page between 1 and {getDocFiles[0]?.num_pages ?? 0
                    }</p>}
                  >
                    <input
                      className="edit-box-input"
                      type="number"
                      value={editLaserLinkItem?.opt_in?.show_on_page}
                      onChange={(element) => {
                        if (element.target.value <= getDocFiles[0]?.num_pages) {
                          updateEditLaserLinkItem({
                            ...editLaserLinkItem,
                            opt_in: {
                              ...editLaserLinkItem?.opt_in,
                              show_on_page: element.target.value,
                            },
                          });
                        }
                      }}
                    />
                  </CustomTooltip>
                  / {getDocFiles[0]?.num_pages ?? 0} after
                  <CustomTooltip title={<p className="p-1 text-light">Please enter the time in seconds</p>}>
                    <input
                      className="edit-box-input"
                      type="number"
                      value={editLaserLinkItem?.opt_in?.show_delay}
                      onChange={(element) => {
                        // if (element.target.value > 0) {
                        updateEditLaserLinkItem({
                          ...editLaserLinkItem,
                          opt_in: {
                            ...editLaserLinkItem?.opt_in,
                            show_delay: element.target.value,
                          },
                        });
                        // }
                      }}
                      onBlur={() => {
                        const updatedValue = editLaserLinkItem?.opt_in?.show_delay || 1;
                        updateEditLaserLinkItem({
                          ...editLaserLinkItem,
                          opt_in: {
                            ...editLaserLinkItem?.opt_in,
                            show_delay: updatedValue,
                          },
                        });
                      }}

                    />
                  </CustomTooltip>
                  sec
                </span>
              }
            />
          </div>
          <Col md={7} className="my-2">
            <div className="d-flex align-items-center edit-msg-access mb-3">
              {/* {JSON.stringify(defaultLinkTexts)} */}
              <Select
                placeholder={
                  "Click here for alternative pre-defined text examples"
                }
                optionList={defaultLinkTexts}
                defaultValue={
                  !!editLaserLinkItem?.opt_in?.custom_text
                    ? defaultLinkTexts?.find(
                      (item) =>
                        editLaserLinkItem?.opt_in?.custom_text?.includes(item.message)
                    )?.title
                    : ""
                }
                onChange={(element) => {
                  let valueToUpdate = defaultLinkTexts?.find(
                    (item) => item.title == element.target.value
                  );
                  updateEditLaserLinkItem({
                    ...editLaserLinkItem,
                    opt_in: {
                      ...editLaserLinkItem?.opt_in,
                      ...valueToUpdate,
                      custom_text: valueToUpdate?.message,
                    },
                  });
                }}
              />
              <CustomTooltip title={<p className="p-1 text-light">Add / Edit text message</p>}>
                <FontAwesomeIcon
                  icon={faPenToSquare}
                  className="cursor-poiner"
                  onClick={() => setEditTextMessage(true)}
                />
              </CustomTooltip>
            </div>
            <div className="d-flex">
              <Button
                className="initial-btn-access me-2"
                text={"Initial"}
                classList={`success ${customTextScope != "initial" ? "cursor-pointer" : ""
                  } me-2`}
                onClick={() =>
                  customTextScope != "initial" && setCustomTextScope("initial")
                }
              />
              <Button
                className="subsequent-btn-access"
                text={"Subsequent"}
                classList={`success ${customTextScope != "subsequent" ? "cursor-pointer" : ""
                  }`}
                onClick={() =>
                  customTextScope != "subsequent" &&
                  setCustomTextScope("subsequent")
                }
              />
            </div>
            {/* <TextArea
                            value={!!editLaserLinkItem?.opt_in?.custom_text ? editLaserLinkItem?.opt_in?.custom_text : ''}
                            onChange={(element) =>
                                updateEditLaserLinkItem(
                                    {...editLaserLinkItem, 
                                        "opt_in" : {...editLaserLinkItem?.opt_in, 'custom_text': element.target.value}
                                    }
                                )
                            }
                        /> */}
            <div className="option-editor-wrapper">
              <TextEditorSection
                html_text={customTextScope == 'initial' ? editLaserLinkItem?.opt_in?.custom_text : editLaserLinkItem?.opt_in?.custom_subsequent_text}
                handleTextChange={(e) =>
                  updateEditLaserLinkItem({
                    ...editLaserLinkItem,
                    opt_in: { ...editLaserLinkItem?.opt_in, [`${customTextScope == 'initial' ? 'custom_text' : 'custom_subsequent_text'}`]: e },
                  })
                }
              />
            </div>
            <Switch
              className="checkbox-access-box mt-2 align-items-center"
              formLabel={`This info will only be shared with ${getProfile?.name ?? ''} from ${getProfile?.company_name ?? ''}`}
              type="checkbox"
              value="show_info_share_with"
              onChange={(element) =>
                updateEditLaserLinkItem({
                  ...editLaserLinkItem,
                  opt_in: {
                    ...editLaserLinkItem?.opt_in,
                    show_info_share_with:
                      !editLaserLinkItem?.opt_in?.show_info_share_with,
                  },
                })
              }
            />
            <div className="d-flex e-btn-text align-items-center">
              <span className="me-3">Enter button text: </span>
              <Input
                value={editLaserLinkItem?.opt_in?.custom_btn_text ?? ""}
                onChange={(element) =>
                  updateEditLaserLinkItem({
                    ...editLaserLinkItem,
                    opt_in: {
                      ...editLaserLinkItem?.opt_in,
                      custom_btn_text: element.target.value,
                    },
                  })
                }
              />
            </div>
          </Col>
          <Col md={5}>
            <p>Preview</p>
            <div className="permission-top">
              <p>Your permission is required to continue</p>
            </div>
            <div className="permission-para">
              <div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: editLaserLinkItem?.opt_in?.custom_text ?? "To continue reading, please provide your:",
                  }}
                ></p>
              </div>
              {editLaserLinkItem?.opt_in?.ask_name && (
                <Row>
                  <Col md={6}>
                    <Input readOnly={true} placeholder={"First name"} />
                  </Col>
                  <Col md={6}>
                    <Input readOnly={true} placeholder={"Last name"} />
                  </Col>
                </Row>
              )}
              <Input readOnly={true} placeholder={"Email"} />
              {editLaserLinkItem?.opt_in?.ask_telephone_number && (
                <Input readOnly={true} placeholder={"Tel nr."} />
              )}
              <div className="permission-paragraph mt-2 mb-2">
                {editLaserLinkItem?.opt_in?.show_info_share_with && (
                  <p>This info will only be shared with {getProfile?.name ?? ''} from {getProfile?.company_name ?? ''}</p>
                )}
                <p>
                  You consent to us processing your personal data. Unsubscribe
                  anytime. See our{" "}
                  <span className="privacy">
                    <a href="/privacy-policy"> Privacy Notice</a>
                  </span>
                </p>
              </div>

              <Button
                className="access-ok-btn"
                text={
                  !!editLaserLinkItem?.opt_in?.custom_btn_text
                    ? editLaserLinkItem?.opt_in?.custom_btn_text
                    : "OK"
                }
                classList={"outline-success"}
                addonClass={"text-end"}
              />
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
}
export default AccessGateOptionsTab;
