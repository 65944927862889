import React, { useContext } from "react"
import TermsKdfContent from "../../utis/TermsKdfContent"
import TermsAndPolicyContent from "../../utis/TermsAndPolicyContent"
import TermsAndPolicyModal from "./TermsAndPolicyModal"
import { networkService } from "../config/axios"
import UserInfoContext from "../../utis/userInfoContext"
import { csrfToken } from "../../utis/csrfTokenUtils"
import Button from "../Elements/Button"

const TermsModals = ({ openTerms, setOpenTerms, refetch }) => {
    const { userInfoData, showCreateTerms, setShowCreateTerms, setShowNotifications } = useContext(UserInfoContext)

    //accept the terms while creating first kdf 
    const handleAcceptTerms = async () => {
        const data = {
            authenticity_token: csrfToken(),
        };
        await networkService(`update_tos.json`, "POST", data)
            .then((response) => {
                if (response.status == 200) {
                    refetch()
                    setShowNotifications({
                        showToast: true,
                        toastType: "success",
                        toastMessage: "Terms accepted",
                    });
                }
                setShowCreateTerms(false)
            })
            .catch((error) => {
                setShowNotifications({
                    showToast: true,
                    toastType: "danger",
                    toastMessage:
                        error?.response?.data?.errors?.email[0] ?? "Something went wrong",
                });
            });
    };

    //accept the terms policy
    const handleAcceptPolicyTerms = async () => {
        const data = {
            authenticity_token: csrfToken(),
            id: userInfoData?.id,
            user: {
                privacy_accepted: true
            },
        };
        await networkService(`admin/users/${userInfoData?.id}.json`, "PATCH", data)
            .then((response) => {
                if (response.status == 200) {
                    refetch()
                    setShowNotifications({
                        showToast: true,
                        toastType: "success",
                        toastMessage: `Terms acccepted`,
                    });
                }
                setOpenTerms(false)
            })
            .catch((error) => {
                setShowNotifications({
                    showToast: true,
                    toastType: "danger",
                    toastMessage:
                        error?.response?.data?.errors?.email[0] ?? "Something went wrong",
                });
            });
    };

    return (
        <>
            {/* <MyProfileModal
                profileModalStaus={profileModalStaus}
                setProfileModalStaus={setProfileModalStaus}
                setOpenTerms={setOpenTerms}
            /> */}
            <TermsAndPolicyModal
                modalStatus={openTerms}
                modalActiveStatus={setOpenTerms}
                title="Privacy Notice"
                showCloseButton={false}
                footer={
                    <div className="d-flex justify-content-end">
                        <Button
                            classList={'success'}
                            onClick={() => handleAcceptPolicyTerms()}
                            text='I Agree'
                        />
                    </div>
                }
            >
                <TermsAndPolicyContent setOpenTerms={setOpenTerms} />
            </TermsAndPolicyModal>
            <TermsAndPolicyModal
                modalStatus={showCreateTerms}
                modalActiveStatus={() => setShowCreateTerms(false)}
                title="Before we can allow full access, please accept our Terms of Service"
                showCloseButton={false}
                footer={
                    <div className="d-flex justify-content-end">
                        <Button
                            classList={'success'}
                            onClick={() => handleAcceptTerms()}
                            text='I Agree'
                        />
                    </div>
                }
            >
                <TermsKdfContent setOpenTerms={setOpenTerms} />
            </TermsAndPolicyModal>
        </>
    )
}
export default TermsModals