import React, { useContext, useEffect, useState } from "react";
import Breadcrumb from "../../../CommonComponents/BreadCrumb/BreadCrumb";
import CustomTable from "../../../CommonComponents/Table/CustomTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import DataPerPage from "../../../CommonComponents/Pagination/DataPerPage";
import SearchComponent from "../../../CommonComponents/SearchComponent/SearchComponent";
import Button from "../../../Elements/Button";
import { networkService } from "../../../config/axios";
import DeleteOrgModal from "../../../Modals/Admin/DeleteOrgModal";
import UserInfoContext from "../../../../utis/userInfoContext";
import AdminPagination from "../../../CommonComponents/Pagination/AdminPagination";

const AdminPotentialBotsPage = () => {
    const { setShowNotifications } = useContext(UserInfoContext)
    const [potentialId, setPotentialId] = useState(null)
    const [potentialBots, setPotentialBots] = useState([])
    const [currentPageData, setCurrentPageData] = useState([]);
    const [dataPerPage, setDataPerPage] = useState(10)
    const totalData = potentialBots?.length;

    const handlePageChange = (pageNumber) => {
        const indexOfLastData = pageNumber * dataPerPage;
        const indexOfFirstData = indexOfLastData - dataPerPage;
        setCurrentPageData(potentialBots?.slice(indexOfFirstData, indexOfLastData));
    };

    const handleDeleteBot = (id) => {
        const url = `admin/potential_bots/${id}.json`;
        networkService(url, "DELETE")
            .then((response) => {
                if (response.status = 204) {
                    setPotentialId(null)
                    setShowNotifications({
                        showToast: true,
                        toastType: "success",
                        toastMessage: "Potential bot deleted",
                    })
                }
            })
            .catch((error) => {
                setShowNotifications({
                    showToast: true,
                    toastType: 'danger',
                    toastMessage: "Something went wrong"
                })
            });
    }

    useEffect(() => {
        const fetchBots = async () => {
            const response = await networkService("admin/potential_bots.json", "GET");
            setPotentialBots([...response.data?.potential_bots]);
        };
        fetchBots();
    }, [potentialId]);


    useEffect(() => {
        handlePageChange(1);
    }, [potentialBots])


    const columns = React.useMemo(() => {
        return [
            {
                Header: 'IP Address',
                accessor: row => row?.ip_data?.ip_address,
                Cell: ({ value }) => (value ? value : 'No IP Address'),
            },
            {
                Header: 'Users',
                accessor: 'users',
                Cell: ({ row: { original } }) => {
                    const userName = original?.ip_data?.user_id[0]?.name;
                    return <>{userName ?? 'User Name'}</>
                },
            },
            {
                Header: 'Counts',
                accessor: row => row?.ip_data?.count
            },
            {
                Header: 'Confirm',
                accessor: 'confirm',
                Cell: () => ('Confirmed'),
            },
            {
                Header: 'Reject',
                accessor: 'reject',
                Cell: ({ row }) => (
                    <div
                        className="cursor-pointer"
                        onClick={() => setPotentialId(row?.original?.id)}>
                        Reject
                    </div>
                )
            },
            {
                Header: 'Actions',
                accessor: 'action',
                Cell: ({ row }) => (
                    <>
                        <FontAwesomeIcon
                            icon={faTrashCan}
                            className='cancel cursor-pointer'
                            onClick={() => setPotentialId(row?.original?.id)}
                        />
                    </>
                )
            },
        ];
    }, [currentPageData]);

    return (
        <>
            <DeleteOrgModal
                title={'Delete Potential Bot'}
                openModal={potentialId !== null}
                closeModal={() => setPotentialId(null)}
                name={'delete Potential bot'}
                handleDelete={() => handleDeleteBot(potentialId)}
            />
            <Breadcrumb string={'Admin/Potential Bots'} />
            <div className="container">
                <div className="show-select1 d-flex mb-2">
                    <div className="mb-2">
                        <DataPerPage dataPerPage={dataPerPage} setDataPerPage={setDataPerPage} />
                        <SearchComponent
                            data={potentialBots}
                            setCurrentPageData={setCurrentPageData}
                        />
                    </div>
                    <Button
                        classList='success'
                        text={"Add Bot"}
                        onClick={() => window.location.href = "/admin/potential_bots/new"}
                        type='button'
                    />
                </div>
                <div className="top-parent">
                    <CustomTable data={currentPageData} columnData={columns} />
                </div>
                <AdminPagination
                    dataPerPage={dataPerPage}
                    totalData={totalData}
                    onPageChange={handlePageChange}
                />
            </div>
        </>
    )
}
export default AdminPotentialBotsPage;
