import React, { useContext, useState } from 'react';
import kz_logo from '../../../../assets/images/kz_logo.png';
import SupportModal from '../../Modals/SupportModal';
import { Navbar, Nav, Container, Accordion } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import UserInfoContext from '../../../utis/userInfoContext';


const SideBar = () => {
  const [openSupport, setOpenSupport] = useState(false);
  const { pathname, hash } = useLocation();
  const { userInfoData } = useContext(UserInfoContext);
  const isKdf = pathname?.includes('kdf-stats');
  const isLink = pathname?.includes('tl-stats');

  return (
    <>
      {openSupport && <SupportModal openSupport={openSupport} setOpenSupport={setOpenSupport} />}
      <Navbar expand="lg" className="bg-body-tertiary p-0 d-block">
        <Container fluid className='bg-white p-0'>
          <Navbar.Brand className='m-auto cursor-pointer'><img src={kz_logo} alt="kz_logo" className='img-fluid' /></Navbar.Brand>
        </Container>
        <Container className='p-0 m-0'>
          <Nav defaultActiveKey="/home" className="flex-column w-100 sidebar-nav">
            {
              (userInfoData?.user_type == 1 || userInfoData?.user_type == 2) && <>
                <Accordion defaultActiveKey="">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className={`menu-sidebar ${pathname.includes('admin') ? 'active' : ''}`}>Admin</Accordion.Header>
                    <Accordion.Body className='p-0'>
                      <Nav.Link href="/admin/orgs">Organizations</Nav.Link>
                      <Nav.Link href="/admin/users"> Users</Nav.Link>
                      <Nav.Link href="/admin/subscriptions">Subscriptions</Nav.Link>
                      <Nav.Link href="/admin/potential_bots">Potential Bots</Nav.Link>
                      <Nav.Link href="/admin/customization_log">Customization Logs</Nav.Link>
                      <Nav.Link href="/admin/get_domain_users">Domain Manager</Nav.Link>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </>
            }
            <Nav.Link className={`${pathname.includes('kdfs') ? 'active-nav' : ''}`} href="/kdfs">KDFs</Nav.Link>
            <Nav.Link className={`${pathname.includes('recipients') ? 'active-nav' : ''}`} href="/recipients"> Recipients</Nav.Link>
            <Nav.Link className={`${pathname.includes('links') ? 'active-nav' : ''}`} href="/links"> LaserLINX</Nav.Link>
            {
              (isKdf || isLink) &&
              <Accordion defaultActiveKey="tl-stats">
                <Accordion.Item eventKey="tl-stats">
                  <Accordion.Header className={`menu-sidebar ${(pathname?.includes('tl-stats') || pathname?.includes('kdf-stats')) ? 'active' : ''}`}>{isKdf && 'KDF Statistics'}{isLink && 'INSIGHT'}</Accordion.Header>
                  <Accordion.Body className='p-0'>
                    <Nav.Link href={`#/at-a-glance`} className={hash.includes('/at-a-glance') ? 'active' : ''}>At a glance</Nav.Link>
                    <Nav.Link href={`#/page-by-page`} className={hash.includes('/page-by-page') ? 'active' : ''}>Page by page</Nav.Link>
                    <Nav.Link href={`#/views`} className={hash.includes('/views') ? 'active' : ''}>Visits</Nav.Link>
                    <Nav.Link href={`#/viewers`} className={hash.includes('/viewers') ? 'active' : ''}>Unique Recipients</Nav.Link>
                    {hash.includes('#/session/') && <Nav.Link className={`cursor-auto ${hash.includes('#/session/') ? 'active' : ''}`}>Viewer Journey</Nav.Link>}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            }
            <Nav.Link onClick={() => setOpenSupport(true)}> Support</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default SideBar;
