
import React, { useCallback, useState } from "react"
import { debounce } from "../../../../../../../hooks/utils";

const TransparencySection = ({ tool, handleChange }) => {
    const [sliderValue, setSliderValue] = useState(tool?.transparency * 100 || 100);

    const debouncedHandleChange = useCallback(
        debounce((value) => {
            handleChange({ target: { name: 'transparency', value: value / 100 } });
        }, 300),
        [handleChange]
    );

    const onSliderChange = (event) => {
        const value = event.target.value;
        setSliderValue(value);
        debouncedHandleChange(value);
    };


    return (
        <div className="transparency-section justify-content-between d-flex">
            <div className="">
                <p>TRANSPARENCY</p>
            </div>
            <div className="range-slider ">
                <input
                    id="slider_input"
                    className="cursor-pointer custom-slider"
                    type="range"
                    min="0"
                    max="100"
                    name='transparency'
                    value={sliderValue}
                    onChange={onSliderChange}
                />
            </div>
        </div>
    )
}
export default TransparencySection