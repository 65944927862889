import React, { useEffect, useState } from "react";
import { Container, Form, InputGroup } from "react-bootstrap";
import styles from './passwordProtected.module.css';
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../Elements/Button";
import { csrfToken } from "../../../../utis/csrfTokenUtils";
import ToastNotification from "../../../CommonComponents/Toasts/ToastNotification";

const PasswordProtectedKDF = ({ alert }) => {
    const { pathname } = useLocation();
    const [showToast, setShowToast] = useState({ showToast: false, toastType: 'success', toastMessage: "" })
    useEffect(() => {
        if (alert?.length > 0) {
            setShowToast(prev => ({ ...prev, showToast: true, toastType: alert[0][0], toastMessage: alert[0][1] }))
        }
    }, [alert])
    return (
        <div className={styles.wrapper}>
            <ToastNotification
                showToast={showToast}
                setShowToast={setShowToast}
            />
            <form
                className={styles.formWrapper}
                action={pathname}
                method="POST"
                name="codeForm"
                target="_self"
                autocomplete="off"
            >
                <input type="hidden" value={csrfToken()} name="authenticity_token" />
                <Form.Label className="text-center" htmlFor="invitation-title">Congratulations</Form.Label>
                <InputGroup className={`${styles.passwordInputGroup}`}>
                    <InputGroup.Text id="invitation-code">
                        <FontAwesomeIcon icon={faLock} />
                    </InputGroup.Text>
                    <Form.Control
                        type="password"
                        placeholder="Enter your personal invitation code here"
                        aria-label="invitationURL"
                        aria-describedby="invitation-code"
                        name="invitation_code"
                    />
                </InputGroup>
                <Button text={'Confirm'} classList={'success'} addonClass={'text-center'} type={'submit'} />
            </form>
        </div>
    )
}
export default PasswordProtectedKDF;