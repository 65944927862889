import React, { useContext, useState } from "react";
import CommonModal from "../../Modals/CommonModal";
import { Form } from "react-bootstrap";
import "./HandleUiModal.css";
import CustomInput from "../../CommonComponents/CustomForm/CustomInputField";
import PreviewUi from "./PreviewUi";
import Button from "../../Elements/Button";
import { csrfToken } from "../../../utis/csrfTokenUtils";
import { networkService } from "../../config/axios";
import UserInfoContext from "../../../utis/userInfoContext";

const HandleUiModal = ({ openModal, closeModal, customizationId, isEdit }) => {
  const HandleUiModalBody = () => {
    const { setShowNotifications } = useContext(UserInfoContext);
    const [fieldsData, setFieldsData] = useState({
      pp_text: `This website may require cookies to function properly. We request your consent to use optional cookies to display video, audio, forms and other interactive elements`,
      modal_details: {
        border_weight: 2,
        bg_color: "white",
        border_color: "black",
      },
      link_details: {
        link_text: "Privacy Notice.",
        link_url: "https://kzine.com/privacy-policy",
      },
      value: {
        btn1_bg_color: "#294561",
        btn1_text_color: "White",
        btn1_border_color: "#787878",
        btn2_text_color: "White",
        btn2_bg_color: "#294561",
        btn2_border_color: "#787878",
      },
    });

    const handleChange = ({ target: { value, name } }) => {
      const sections = ["modal_details", "value", "link_details"];
      const targetKey = sections.find((key) => name in fieldsData[key]);

      if (targetKey) {
        setFieldsData({
          ...fieldsData,
          [targetKey]: {
            ...fieldsData[targetKey],
            [name]: value,
          },
        });
      } else {
        setFieldsData({
          ...fieldsData,
          [name]: value,
        });
      }
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      const data = {
        approved: isEdit,
        id: customizationId,
        authenticity_token: csrfToken(),
        fieldsData,
      };

      await networkService(`update_text/${customizationId}.json`, "PATCH", data)
        .then((response) => {
          if (response.status == 200 || response.status == 201) {
            closeModal(false);
            setShowNotifications({
              showToast: true,
              toastType: "success",
              toastMessage: "Customization Request sent successfully",
            });
          }
        })
        .catch((error) => {
          setShowNotifications({
            showToast: true,
            toastType: "danger",
            toastMessage: "Something went wrong",
          });
        });
    };

    return (
      <div>
        <div className="domain-main">
          <div className="customise-wrap-main">
            <div className="custo-cook">
              <h3>
                Customize the cookie consent text (maximum 500 characters)
              </h3>
            </div>
            <div className="custo-textarea-wrap">
              <Form.Control
                as="textarea"
                placeholder="Add the text here"
                name='pp_text'
                value={fieldsData?.pp_text}
                onChange={handleChange}
                style={{ height: "100px" }}
              />
            </div>
            <div className="row border-const-pre">
              <div className="col-6 border-constent">
                <div>
                  <h6 className="m-0">
                    <b>Border</b>
                  </h6>
                </div>
                <div className="row">
                  <div className="d-flex customise-main-inp align-items-center gap-2">
                    <label>Weight</label>
                    <CustomInput
                      placeholder={"border weight"}
                      value={fieldsData?.modal_details?.border_weight}
                      name={"border_weight"}
                      onChange={handleChange}
                      type={"number"}
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                    />
                  </div>
                </div>
                <div className="">
                  <div className="d-flex customise-main-inp align-items-center gap-2">
                    <label>Background color</label>
                    <CustomInput
                      placeholder={"background color"}
                      value={fieldsData?.modal_details?.bg_color}
                      name={"bg_color"}
                      onChange={handleChange}
                      type={"text"}
                    />
                  </div>
                </div>

                <div className="">
                  <div className="d-flex customise-main-inp align-items-center gap-2">
                    <label>Border color</label>
                    <CustomInput
                      placeholder={"border color"}
                      value={fieldsData?.modal_details?.border_color}
                      name={"border_color"}
                      onChange={handleChange}
                      type={"text"}
                    />
                  </div>
                </div>
                <div className="mt-1 laser-set-h">
                  <h6>
                    <b>Link Settings</b>
                  </h6>
                  <div className="d-flex  row">
                    <div className="col-12">
                      <div className="d-flex align-items-center">
                        <div className="d-flex customise-main-inp align-items-center gap-2">
                          <label>Text</label>
                          <CustomInput
                            placeholder={"privacy notice"}
                            value={fieldsData?.link_details?.link_text}
                            name={"link_text"}
                            onChange={handleChange}
                            type={"text"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-flex align-items-center">
                        <div className="d-flex customise-main-inp align-items-center mt-1 gap-2 w-100">
                          <label>URL</label>
                          <CustomInput
                            className="w-100"
                            placeholder={"privacy notice"}
                            value={fieldsData?.link_details?.link_url}
                            name={"link_url"}
                            onChange={handleChange}
                            type={"text"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 border-constent">
                <PreviewUi fieldsData={fieldsData} />
              </div>
            </div>


          </div>
          <div className="customise-wrap-main mt-3">
            <div className="color-settings">
              <div>
                <h3>
                  <b> Button 1 Colors</b>
                </h3>
              </div>
              <div className="color-row">
                <div className="color-input d-flex">
                  <label htmlFor="text-color-1">Text</label>
                  <CustomInput
                    placeholder={"text color"}
                    value={fieldsData?.value?.btn1_text_color}
                    name={"btn1_text_color"}
                    onChange={handleChange}
                    type={"text"}
                  />
                </div>
                <div className="color-input d-flex justify-content-center">
                  <label htmlFor="background-color-1">Background</label>
                  <CustomInput
                    placeholder={"text color"}
                    value={fieldsData?.value?.btn1_bg_color}
                    name={"btn1_bg_color"}
                    onChange={handleChange}
                    type={"text"}
                  />
                </div>
                <div className="color-input d-flex justify-content-end">
                  <label htmlFor="border-color-1">Border</label>
                  <CustomInput
                    placeholder={"border color"}
                    value={fieldsData?.value?.btn1_border_color}
                    name={"btn1_border_color"}
                    onChange={handleChange}
                    type={"text"}
                  />
                </div>
              </div>
              <div>
                <h3>
                  <b> Button 2 Colors</b>
                </h3>
              </div>
              <div className="color-row">
                <div className="color-input d-flex">
                  <label htmlFor="text-color-1">Text</label>
                  <CustomInput
                    placeholder={"text color"}
                    value={fieldsData?.value?.btn2_text_color}
                    name={"btn2_text_color"}
                    onChange={handleChange}
                    type={"text"}
                  />
                </div>
                <div className="color-input d-flex justify-content-center">
                  <label htmlFor="background-color-1">Background</label>
                  <CustomInput
                    placeholder={"text color"}
                    value={fieldsData?.value?.btn2_bg_color}
                    name={"btn2_bg_color"}
                    onChange={handleChange}
                    type={"text"}
                  />
                </div>
                <div className="color-input d-flex justify-content-end">
                  <label htmlFor="border-color-1">Border</label>
                  <CustomInput
                    placeholder={"border color"}
                    value={fieldsData?.value?.btn2_border_color}
                    name={"btn2_border_color"}
                    onChange={handleChange}
                    type={"text"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <Button
            text={"Save"}
            classList={"success"}
            type="button"
            onClick={(e) => handleSubmit(e)}
          />
        </div>
      </div>
    );
  };

  return (
    <CommonModal
      title={"Consent Modal Options"}
      modalStatus={openModal}
      changeActiveStatus={() => closeModal(false)}
      centered
    >
      <HandleUiModalBody />
    </CommonModal>
  );
};
export default HandleUiModal;
