import React, { useRef } from 'react';
import './input.css';

const Input = ({classList,required = false, errorMessage, errorMessageStatus, placeholder, children, label, type = null, forRef = null, autoFocus, ...restProps}) => {
    const inputRef = useRef(null);
    let formID = `${{...restProps}?.name}-${Math.floor(Math.random() * 1000)}`
    return(
        <div className={`${ type == 'checkbox' ? 'form-check' : 'input-groups'} ${classList != undefined ? classList : ''}`}>
            <div className={`${type != 'checkbox' ? 'form-input' : 'd-flex'}`}>
                {label && <label className="control-label" htmlFor={formID}>{ required && '*' } {label}</label>}
                <input
                    type={type != null ? type : 'text'}
                    placeholder={placeholder ?? ''}
                    required={required}
                    className={`${type == 'checkbox' ? 'form-check-input' : 'form-input'} ${ !!errorMessageStatus ? 'has-error': ''}`}
                    id={formID}
                    ref={forRef ?? inputRef}
                    {...restProps}
                    autoFocus={autoFocus}
                />
                {children}
                <div className='input-messages'>
                    {errorMessage}
                </div>
            </div>
        </div>
    )
}
export default Input;