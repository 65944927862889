import React, { useEffect, useState } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import './ToastNotification.css';

const ToastNotification = ({ showToast, setShowToast }) => {
    return (
        <>
            <ToastContainer position="top-end" >
                <Toast
                    show={showToast?.showToast}
                    onClose={() => setShowToast({
                        ...showToast,
                        showToast: false
                    })}
                    delay={3000}
                    autohide
                    className={`${showToast?.toastType == 'success' ? 'toast-custom-green' : showToast?.toastType == 'danger' ? 'toast-custom-red' : showToast?.toastType == 'secondary' && 'toast-custom-blue'} text-white toast-notify`}
                >
                    <Toast.Body>{showToast?.toastMessage ?? ''}</Toast.Body>
                </Toast>
            </ToastContainer>

        </>
    );
};

export const ToasterNotification = ({ message, variant = null }) => {
    const [showToast, setShowToast] = useState(false);
    useEffect(() => {
        if (message != '') {
            setShowToast(true);
        }
        return () => {
            setShowToast(false);
        }
    }, [message])
    return (
        <>
            <ToastContainer position="top-end" >
                <Toast
                    show={showToast}
                    onClose={() => setShowToast(false)}
                    delay={3000}
                    autohide
                    className={`${variant == 'success' ? 'toast-custom-green' : variant == 'danger' ? 'toast-custom-red' : variant == 'secondary' && 'toast-custom-blue'} text-white toast-notify`}
                >
                    <Toast.Body>{message ?? ''}</Toast.Body>
                </Toast>
            </ToastContainer>
        </>
    );
};


export default ToastNotification;
