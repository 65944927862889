import React from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Form } from "react-bootstrap";
import { SubscriptionPeriodArr } from "../../../../hooks/utils";

const Donut3DChart = ({ data, name }) => {

    const graphData = data?.map(item => ({
        name: item?.[name] ?? name,
        y: item?.hits
    }));

    const donutChartOptions = {
        chart: {
            type: 'pie',
            options3d: {
                enabled: true,
                alpha: 45, // Tilt
                beta: 0,   // Rotation
                depth: 50, // Depth of the chart
            },
        },
        title: {
            text: '',
        },
        tooltip: {
            pointFormat: '{point.y} Visits ({point.percentage:.1f}%)',
        },
        plotOptions: {
            pie: {
                innerSize: 100,
                depth: 45,
                dataLabels: {
                    enabled: true,
                    format: '{point.name}',
                },
            },
        },
        series: [
            {
                name: name,
                data: graphData,
            },
        ],
    };

    return (
        <>
            <HighchartsReact highcharts={Highcharts} options={donutChartOptions} />
        </>
    )
}
export default Donut3DChart