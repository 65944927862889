import React, { useContext, useEffect, useState } from "react";
import AddOrganisation from "../../../Modals/Admin/AddOrganisationModal";
import SearchComponent from "../../../CommonComponents/SearchComponent/SearchComponent";
import DataPerPage from "../../../CommonComponents/Pagination/DataPerPage";
import Breadcrumb from "../../../CommonComponents/BreadCrumb/BreadCrumb";
import { networkService } from "../../../config/axios";
import ManageDomain from "../../../Modals/Admin/ManageDomainModal";
import ManagePayment from "../../../Modals/Admin/ManagePaymentModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCircle,
  faCircleInfo,
  faCoins,
  faEnvelope,
  faPen,
  faTrashCan,
  faUsers,
  faUserSlash,
} from "@fortawesome/free-solid-svg-icons";
import CustomTable from "../../../CommonComponents/Table/CustomTable";
import CustomTooltip from "../../../Elements/Tooltip";
import DeleteOrgModal from "../../../Modals/Admin/DeleteOrgModal";
import OrgUsersModal from "../../../Modals/Admin/OrgUsersModal";
import OrgBannedUserModal from "../../../Modals/Admin/OrgBannedUserModal";
import AdminPagination from "../../../CommonComponents/Pagination/AdminPagination";
import Button from "../../../Elements/Button";
import UserInfoContext from "../../../../utis/userInfoContext";

const Organizations = () => {
  const { setShowNotifications, userInfoData } = useContext(UserInfoContext)
  const [orgs, setOrgs] = useState([]);
  const [openModals, setOpenModals] = useState(null)
  const [orgData, setOrgData] = useState({})
  const [currentPageData, setCurrentPageData] = useState([]);
  const [dataPerPage, setDataPerPage] = useState(10)
  const totalData = orgs?.length;

  const handlePageChange = (pageNumber) => {
    const indexOfLastData = pageNumber * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    setCurrentPageData(orgs?.slice(indexOfFirstData, indexOfLastData));
  };

  useEffect(() => {
    const fetchOrgs = async () => {
      const response = await networkService("admin/orgs.json", "GET");
      setOrgs([...response.data]);
    };
    fetchOrgs();
  }, [openModals]);

  useEffect(() => {
    handlePageChange(1);
  }, [orgs])

  const handleDeleteOrg = () => {
    const url = `admin/orgs/${orgData?.id}.json`;
    networkService(url, "DELETE")
      .then((response) => {
        if (response.status == 204) {
          setOpenModals(null)
          setShowNotifications({
            showToast: true,
            toastType: "success",
            toastMessage: "Organization deleted",
          })
        }
      })
      .catch((error) => {
        setShowNotifications({
          showToast: true,
          toastType: 'danger',
          toastMessage: "Something went wrong"
        })
      });
  }

  const columns = React.useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row }) => (
          <>
            {row.original?.name}
            <CustomTooltip
              title={
                <div
                  className="p-2"
                  dangerouslySetInnerHTML={{
                    __html: row?.original?.creator_modifier ?? "",
                  }}
                />
              }
              light={true}
              placement={"top"}
            >
              <FontAwesomeIcon icon={faCircleInfo} className="ms-2" />
            </CustomTooltip>
          </>
        ),
      },
      {
        Header: "Type",
        accessor: "type_name",
        Cell: ({ row }) => (
          <>
            {row.original.type_name}
            <CustomTooltip
              title={
                <div
                  className="p-2"
                  dangerouslySetInnerHTML={{
                    __html: row?.original?.info ?? "",
                  }}
                />
              }
              placement={"top"}
              light={true}
            >
              <FontAwesomeIcon icon={faCircleInfo} className="ms-2" />
            </CustomTooltip>

            <CustomTooltip
              title={
                <div
                  className="p-2"
                  dangerouslySetInnerHTML={{
                    __html: row?.original?.domains_text ?? "",
                  }}
                />
              }
              light={true}
              placement={"top"}
            >
              <FontAwesomeIcon className="ms-2" icon={faEnvelope} />
            </CustomTooltip>
          </>
        ),
      },
      {
        Header: "Trial",
        accessor: "trial",
        Cell: ({ row }) => <>{row.original.trial} Days</>,
      },
      {
        Header: "Last Login",
        accessor: "last_signed_username",
      },
      {
        Header: "Date Time",
        accessor: "last_signed_time",
      },
      {
        Header: "Users",
        accessor: "num_online_users",
        Cell: ({ row }) => (
          <div
            onClick={() => {
              setOpenModals("userManagement");
              setOrgData(row.original)
            }}
            className="hov-clr cursor-pointer"
          >
            <FontAwesomeIcon className="me-2" icon={faUsers} />
            {row.original.num_users} User
            {row.original.num_users > 1 ? "s " : " "}
            {row.original.num_online_users > 0 && (
              <span>
                {row.original.num_online_users} User
                {row.original.num_online_users > 1 ? "s" : ""} Online
              </span>
            )}
            {
              row?.original?.num_online_users > 0 ?
                <FontAwesomeIcon icon={faCircle} className="ms-2 user-active" /> :
                <></>
            }
          </div>
        ),
      },
      {
        Header: "Banned Users",
        accessor: "num_banned_users",
        Cell: ({ row }) => (
          <div
            onClick={() => {
              setOpenModals("bannedUser");
              setOrgData(row.original)
            }}
            className="hov-clr cursor-pointer"
          >
            <FontAwesomeIcon className="me-2" icon={faUserSlash} />
            {row.original.num_banned_users} Banned User
            {row.original.num_banned_users > 1 ? "s " : " "}
          </div>
        ),
      },
      {
        Header: 'Actions',
        accessor: "action",
        Cell: ({ row }) => (
          <div className="org-icon hov-clr d-flex">
            <CustomTooltip
              title={<p className="p-1 text-light">Edit</p>}
              placement={"top"}
            >
              <FontAwesomeIcon
                className="me-2 cursor-pointer success success-green"
                icon={faPen}
                onClick={() => {
                  setOrgData(row?.original)
                  setOpenModals('org');
                }}
              />
            </CustomTooltip>
            <CustomTooltip
              title={<p className="p-1 text-light">Email Domains</p>}
              placement={"top"}
            >
              <FontAwesomeIcon
                icon={faEnvelope}
                className="me-2 cursor-pointer"
                onClick={() => {
                  setOpenModals('domain');
                  setOrgData(row?.original)
                }}
              />
            </CustomTooltip>
            <CustomTooltip
              title={<p className="p-1 text-light">Payment</p>}
              placement={"top"}
            >
              <FontAwesomeIcon
                icon={faCoins}
                className="me-2 cursor-pointer"
                onClick={() => {
                  setOpenModals('payment');
                  setOrgData(row?.original)
                }}
              />
            </CustomTooltip>
            {
              userInfoData?.company?.id !== row?.original?.id &&
              <CustomTooltip
                title={<p className="p-1 text-light">Delete</p>}
                placement={"top"}
              >
                <FontAwesomeIcon
                  icon={faTrashCan}
                  className="cursor-pointer cancel"
                  onClick={() => {
                    setOpenModals('delete');
                    setOrgData(row?.original)
                  }}
                />
              </CustomTooltip>
            }
          </div>
        ),
      },
    ];
  }, [currentPageData]);

  const handleCloseModal = () => {
    setOpenModals(null)
    setOrgData({})
  }

  const OrgModals = () => {
    switch (openModals) {
      case "org":
        return (
          <AddOrganisation
            openModal={openModals === 'org'}
            closeModal={handleCloseModal}
            orgData={orgData}
            setOrgData={setOrgData}
          />
        );
      case "bannedUser":
        return (
          <OrgBannedUserModal
            openModal={openModals === "bannedUser"}
            closeModal={handleCloseModal}
            companyId={orgData?.id}
            title={orgData?.name}
            type={'bannedUser'}
          />
        );
      case "domain":
        return (
          <ManageDomain
            openModal={openModals === 'domain'}
            closeModal={handleCloseModal}
            companyId={orgData?.id}
          />
        );
      case "payment":
        return (
          <ManagePayment
            openModal={openModals === 'payment'}
            closeModal={handleCloseModal}
            companyId={orgData?.id}
          />
        );
      case "delete":
        return (
          <DeleteOrgModal
            title={'Delete Organization'}
            openModal={openModals === "delete"}
            closeModal={handleCloseModal}
            name={'delete ' + orgData?.name}
            handleDelete={handleDeleteOrg}
          />
        );
      case "userManagement":
        return (
          <OrgUsersModal
            openModal={openModals === "userManagement"}
            closeModal={handleCloseModal}
            companyId={orgData?.id}
            title={orgData?.name}
            type={'userManagement'}
          />
        );

      default:
        return null;
    }
  }

  return (
    <>
      {OrgModals()}
      <Breadcrumb string={"Admin/Organizations"} />
      <div className="container">
        <div className="show-select1 d-flex  mb-2">
          <div className="mb-2">
            <DataPerPage setDataPerPage={setDataPerPage} dataPerPage={dataPerPage} />
            <SearchComponent
              data={orgs}
              setCurrentPageData={setCurrentPageData}
            />
          </div>
          <Button
            classList='success'
            text={<>
              <FontAwesomeIcon className="me-2" icon={faBuilding} /> Add
              Organization</>}
            onClick={() => {
              setOpenModals("org");
            }}
            type='button'
          />
        </div>
        <div className="top-parent">
          <CustomTable data={currentPageData} columnData={columns} />
        </div>
        <AdminPagination
          dataPerPage={dataPerPage}
          totalData={totalData}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default Organizations;
