// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".passwordProtected-module__wrapper___1z-AQ {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: #404040;\n    height: 100vh;\n    width: 100%;\n}\n\n.passwordProtected-module__passwordInputGroup___10_xy {\n    min-height: unset !important;\n}\n\n.passwordProtected-module__formWrapper___-XdY7 {\n    width: 100%;\n    height: 100%;\n    max-width: 600px;\n    max-height: 180px;\n    margin: auto;\n    background-color: #fff;\n    border-radius: 5px;\n    padding: 1px;\n}\n\n.passwordProtected-module__formWrapper___-XdY7 label {\n    background-color: #A1C1E0;\n    padding: 10px;\n    font-size: 25px;\n    font-weight: 500;\n}", "",{"version":3,"sources":["passwordProtected.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,yBAAyB;IACzB,aAAa;IACb,WAAW;AACf;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;IACjB,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,eAAe;IACf,gBAAgB;AACpB","file":"passwordProtected.module.css","sourcesContent":[".wrapper {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: #404040;\n    height: 100vh;\n    width: 100%;\n}\n\n.passwordInputGroup {\n    min-height: unset !important;\n}\n\n.formWrapper {\n    width: 100%;\n    height: 100%;\n    max-width: 600px;\n    max-height: 180px;\n    margin: auto;\n    background-color: #fff;\n    border-radius: 5px;\n    padding: 1px;\n}\n\n.formWrapper label {\n    background-color: #A1C1E0;\n    padding: 10px;\n    font-size: 25px;\n    font-weight: 500;\n}"]}]);
// Exports
exports.locals = {
	"wrapper": "passwordProtected-module__wrapper___1z-AQ",
	"passwordInputGroup": "passwordProtected-module__passwordInputGroup___10_xy",
	"formWrapper": "passwordProtected-module__formWrapper___-XdY7"
};
module.exports = exports;
