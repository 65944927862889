import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../CommonComponents/BreadCrumb/BreadCrumb";
import Button from "../../../Elements/Button";
import CustomTable from "../../../CommonComponents/Table/CustomTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import AddDomainModal from "../../../Modals/Admin/AddDomainModal";
import DataPerPage from "../../../CommonComponents/Pagination/DataPerPage";
import SearchComponent from "../../../CommonComponents/SearchComponent/SearchComponent";
import { networkService } from "../../../config/axios";
import AdminPagination from "../../../CommonComponents/Pagination/AdminPagination";

const DomainManagement = (props) => {
    const { user, available_domains } = props
    const [domains, setDomains] = useState([])
    const [domainData, setDomainData] = useState({})
    const [openModal, setOpenModal] = useState(false)
    const [currentPageData, setCurrentPageData] = useState([]);
    const [dataPerPage, setDataPerPage] = useState(10)
    const totalData = domains?.length;

    const handlePageChange = (pageNumber) => {
        const indexOfLastData = pageNumber * dataPerPage;
        const indexOfFirstData = indexOfLastData - dataPerPage;
        setCurrentPageData(domains?.slice(indexOfFirstData, indexOfLastData));
    };

    useEffect(() => {
        const fetchDomains = async () => {
            const response = await networkService("admin/get_domains/1.json", "GET");
            setDomains([...response.data?.domains]);
        };
        fetchDomains();
    }, [openModal]);

    useEffect(() => {
        handlePageChange(1);
    }, [domains])

    const columns = React.useMemo(() => {
        return [
            {
                Header: 'Domain',
                accessor: 'name',
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: ({ row: { original } }) => (<>{original?.status ? 'Active' : 'Inactive'}</>),
            },
            {
                Header: 'Default',
                accessor: 'is_default',
                Cell: ({ row: { original } }) => (<>{original?.is_default ? 'Yes' : 'No'}</>),
            },
            {
                Header: 'Actions',
                accessor: 'action',
                Cell: ({ row: { original } }) => (
                    <>
                        <FontAwesomeIcon
                            className="me-2 cursor-pointer success"
                            icon={faPen}
                            onClick={() => {
                                setDomainData(original)
                                setOpenModal(true)
                            }}
                        />
                    </>
                ),
            },
        ];
    }, [currentPageData]);

    return (
        <>
            {openModal &&
                <AddDomainModal
                    openModal={openModal}
                    closeModal={setOpenModal}
                    domainData={domainData}
                    availableDomains={available_domains}
                />
            }
            <Breadcrumb string={'Admin/Domain Manager'} />
            <div className="container ">
                <div className="show-select1 d-flex m-3">
                    <div className="mb-2">
                        <DataPerPage dataPerPage={dataPerPage} setDataPerPage={setDataPerPage} />
                        <SearchComponent
                            data={domains}
                            setCurrentPageData={setCurrentPageData}
                        />
                    </div>
                    <Button
                        classList='success'
                        text={<><FontAwesomeIcon className="me-2" icon={faPlus} /> Add Domains</>}
                        onClick={() => {
                            setOpenModal(true);
                        }}
                        type='button'
                    />
                </div>
                <div className="d-flex justify-content-between m-3">
                    <div>User Name : {user?.name}</div>
                    <div>User Email : {user?.email}</div>
                </div>
                <div className="container">
                    <CustomTable data={currentPageData} columnData={columns} showFilters={false} />
                    <AdminPagination
                        dataPerPage={dataPerPage}
                        totalData={totalData}
                        onPageChange={handlePageChange}
                    />
                </div>
            </div>
        </>
    )
}
export default DomainManagement;