import React, { useContext, useEffect, useRef, useState } from "react";
import { Form, Badge, CloseButton } from "react-bootstrap";
import './CustomTagsInput.css';
import UserInfoContext from "../../../utis/userInfoContext";
import { emailRegex } from "../../../hooks/utils";

const CustomTagsInput = ({ tags, handleAddTags = () => { }, handleRemoveTags = () => { }, triggerKeyDownEvent = () => { }, autoSubmit = false, placeholderText = null, limit = null, targetKey = 'name', isEmail = false }) => {
  const [tag, setTags] = useState(tags);
  const [inputValue, setInputValue] = useState("");
  const [inputError, setInputError] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(null);
  const { setShowNotifications } = useContext(UserInfoContext)

  const componentRef = useRef(null);
  const eventAddedRef = useRef(false);
  const tagInputRef = useRef(null);

  const addTag = (value = null) => {
    const trimmedValue = value || inputValue.trim();
    if (trimmedValue && !tags.includes(trimmedValue)) {
      if (isEmail) {
        const enteredEmail = tag?.find(elem => elem?.email == trimmedValue)
        if (emailRegex.test(trimmedValue) && enteredEmail == undefined) {
          setTags([...tag, trimmedValue]);
          handleAddTags(trimmedValue);
        } else {
          setShowNotifications({
            showToast: true,
            toastType: "danger",
            toastMessage: enteredEmail == undefined ? "Invalid email" : "email already exist",
          })
        }
      } else {
        setTags([...tag, trimmedValue]);
        handleAddTags(trimmedValue);
      }
    }
    setInputValue("");
    setHighlightedIndex(null);
  };

  const handleKeyDown = (e) => {
    if ((e.key === "Enter" || e.key === "Tab") && autoSubmit == false) {
      e.preventDefault();
      if (limit != null) {
        if (limit >= tag?.length) {
          setInputError(true);
          return '';
        }
      }
      addTag();
    }

    if (e.key === "Backspace" && inputValue === "") {
      e.preventDefault();
      if (highlightedIndex === null) {
        setHighlightedIndex(tag?.length - 1);
      } else if (highlightedIndex !== null) {
        const tagToRemove = tag[highlightedIndex];
        removeTag(tagToRemove);
        setHighlightedIndex(null);
      }
    }
  };

  const removeTag = (tagToRemove) => {
    const updatedTags = tag?.filter((tag) =>
      tagToRemove?.email ? tag?.email !== tagToRemove?.email : tag?.id !== tagToRemove?.id
    );
    setTags(updatedTags);
    handleRemoveTags(updatedTags);
  };

  const handleBlur = () => {
    addTag();
  };

  useEffect(() => {
    setTags(tags);
    if (JSON.stringify(tags) != JSON.stringify(tag)) {
      setInputValue('');
    }
  }, [tags]);

  const handleClickOutside = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      addTag(tagInputRef.current.value);
      if (eventAddedRef.current) {
        document.removeEventListener("mousedown", handleClickOutside);
        eventAddedRef.current = false;
      }
    }
  };

  const handleContainerClick = () => {
    if (!eventAddedRef.current) {
      document.addEventListener("mousedown", handleClickOutside);
      eventAddedRef.current = true;
    }
  };

  useEffect(() => {
    return () => {
      if (eventAddedRef.current) {
        document.removeEventListener("mousedown", handleClickOutside);
        eventAddedRef.current = false;
      }
    };
  }, []);

  return (
    <div
      className={`tags-input-container ${tag?.length > 0 ? "has-tags" : ""}`}
      ref={componentRef}
      onClick={handleContainerClick}
    >
      <div className="tags-display">
        {tag?.map((elem, index) => (
          <Badge
            key={index}
            className={`me-1 ${highlightedIndex === index ? "highlight" : ""}`}
          >
            <span className="text-light">{elem[`${targetKey}`] ?? elem}</span>
            <CloseButton
              variant="white"
              className="ms-2"
              onClick={() => removeTag(elem)}
            />
          </Badge>
        ))}
      </div>
      <Form.Control
        type="text"
        ref={tagInputRef}
        placeholder={placeholderText ?? 'Add a tag'}
        value={inputValue}
        onChange={(e) => (setInputValue(e.target.value), triggerKeyDownEvent(e.target.value))}
        onKeyDown={handleKeyDown}
        onBlur={() => autoSubmit == false && handleBlur()}
        className={`tags-input ${inputError == true ? 'tag-error' : ''}`}
      />
    </div>
  );
};

export default CustomTagsInput;
