import React, { useContext, useEffect, useRef, useState } from "react";
import CommonModal from "../../Modals/CommonModal";
import Input from "../Input";
import CustomTooltip from "../Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import Button from "../Button";
import { Tab, Tabs } from "react-bootstrap";
import LaserLINKSettingsTab from "./LaserLINKSettingsTab";
import AccessGateOptionsTab from "./AccessGateOptionsTab";
import PopupOptionsTab from "./PopupOptionsTab";
import UserInfoContext from "../../../utis/userInfoContext";
import apiGETRequest from "../../../utis/apiRequest";
import { networkService } from "../../config/axios";
import { defaultLaserLINKData, defaultPopupOptions } from "../../../hooks/utils";

const createEmbedIframeCode = (link) => {
    return `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="${link}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;background:transparent;" title="Sales Intro"></iframe></div>`
}
const LaserLinkModal = ({ laserItem = null, time_zone_list, docData, triggerCancelProcess = () => { }, batchStatus = null, updateSelectedKDFLink = () => { }, triggerUpdateList = () => { }, currentUser, language }) => {
    const { userInfoData, setShowNotifications } = useContext(UserInfoContext);
    const trackingLinkRef = useRef(null);

    const [editLaserLinkItem, setEditLaserLinkItem] = useState(null);
    const [getDocFiles, setDocFiles] = useState([]);
    const [getDomains, setDomains] = useState({});
    const [getProfile, setProfile] = useState({});
    const [popupOptions, setPopupOptions] = useState({ "enter_pop": {} });
    const [getDefault_link_texts, setDefault_link_texts] = useState(window?.default_link_texts);
    const [embedCode, setEmbedCode] = useState(null);
    const [getValidationStatus, setValidationStatus] = useState(false);
    const [getValiationTabMessage, setValiationTabMessage] = useState({});
    const [saveButtonStatus, setSaveButtonStatus] = useState(null);
    const [errorList, setErrorList] = useState({ 'settings': [], 'access': [], 'popup': [] });
    const [isError, setIsError] = useState(false)

    const updateDomainListEvent = async () => {
        if (userInfoData?.id || currentUser?.id) {
            let domainListGET = await apiGETRequest(`admin/get_domains/${userInfoData?.id ?? currentUser?.id}.json`)
            setDomains(domainListGET);
            return domainListGET;
        } else {
            return "Loading...";
        }
    }

    const updateProfileEvent = async () => {
        if (userInfoData?.id == undefined) {
            return "Loading...";
        }
        let userProfileGET = await apiGETRequest(`profiles/${userInfoData.id}.json`)
        setProfile(userProfileGET);
        return userProfileGET;
    }

    const handleOnchangeEvent = (e) => {
        setEditLaserLinkItem(
            prevState => ({
                ...prevState,
                [e.target.name]: e.target.value
            }));
        setErrorList({ 'settings': [], 'access': [], 'popup': [] })
    }

    const updateUserLightboxesDoc = async (item) => {
        let fetchUserLightBoxData = await apiGETRequest(`docs/all-user-lightboxes/doc.json?by_trigger=yes&doc_id=${item?.doc != undefined ? item?.doc?.id : item?.id}&language=${language ?? item?.doc != undefined ? item?.language : item?.doc_files_languages[0].language.locale}`);
        if (fetchUserLightBoxData?.error == undefined) {
            setPopupOptions(prevState => ({ ...prevState, 'enter_pop': fetchUserLightBoxData?.doc }))
        }
    }

    const updateUserLightboxesPage = async (item) => {
        let fetchUserLightBoxPage = await apiGETRequest(`docs/all-user-lightboxes/page.json?by_trigger=yes&doc_id=${item?.doc != undefined ? item?.doc?.id : item?.id}&language=${language ?? item?.doc != undefined ? item?.language : item?.doc_files_languages[0].language.locale}`);
        let flashPopData = {};
        if (Object.keys(fetchUserLightBoxPage)?.length > 0) {
            if (fetchUserLightBoxPage?.error == undefined) {
                // dummyPopTimedData
                flashPopData = Object.keys(fetchUserLightBoxPage?.page)?.map(item => fetchUserLightBoxPage?.page[item])?.reduce((acc, value) => { return { ...acc, ...value } }, {});
            }
        }
        setPopupOptions(prevState => ({ ...prevState, 'timed_pop': flashPopData }));
    }

    const updateUserExitPopData = async (item) => {
        let fetchOnExitPopData = await apiGETRequest(`docs/all-user-lightboxes/onexit.json?by_trigger=yes&doc_id=${item?.doc != undefined ? item?.doc?.id : item?.id}&language=${language ?? item?.doc != undefined ? item?.language : item?.doc_files_languages[0].language.locale}`);
        let exitPopData = {};
        if (Object.keys(fetchOnExitPopData)?.length > 0) {
            if (fetchOnExitPopData?.error == undefined) {
                exitPopData = fetchOnExitPopData?.onexit;
            }
        }
        setPopupOptions(prevState => ({ ...prevState, 'exit_pop': exitPopData }));
    }

    const updateLaserLinkData = async (itemId, itemStatus) => {
        if (itemId != undefined) {
            let fileDataGet = await apiGETRequest(`docs/${itemId}/files.json?active=${itemStatus}`);
            if (fileDataGet?.error == undefined) {
                setEditLaserLinkItem(prev => ({ ...prev, 'doc_files_languages': fileDataGet }));
                setDocFiles(fileDataGet)
            }
        }
    }

    const updateLaserLinkDataSingle = async (itemId) => {
        if (itemId != undefined) {
            let { data, status } = await networkService(`links/${itemId}.json?for_tl=true`, 'GET');
            if (status == 200) {
                setEditLaserLinkItem(prevData => ({ ...prevData, ...data, 'languages': data?.language }));
            }
        }
    }

    const handleLaserLINKSettingsModal = (e) => {
        updateDomainListEvent();
        updateProfileEvent();
        updateLaserLinkData(e?.doc?.id, e?.doc_file_status);
        updateUserLightboxesDoc(e);
        updateUserLightboxesPage(e);
        updateUserExitPopData(e);
        updateLaserLinkDataSingle(e?.id);
        setEditLaserLinkItem({ ...e, "doc_id": e?.doc?.id, "expire_time": (e?.doc?.expire_time?.split(' ')[1] ?? "0:0") });
    }

    const handleSaveLaserLINKData = async () => {
        try {
            if (saveButtonStatus == false) {
                setValidationStatus(true);
                if (batchStatus != true) {
                    if (editLaserLinkItem?.name?.trim() == '') {
                        if (!errorList?.settings?.includes('name')) {
                            setErrorList(prevState => ({ ...prevState, 'settings': [...prevState.settings, 'name'] }))
                        }
                    } else {
                        if (errorList?.settings?.includes('name')) {
                            setErrorList(prevState => ({ ...prevState, 'settings': [...prevState.settings?.filter(st => st != 'name')] }))
                        }
                    }
                }
            } else {
                await navigator.clipboard.writeText(editLaserLinkItem?.tracking_link ?? '');
                setEditLaserLinkItem(null);
                triggerCancelProcess(null);
                triggerUpdateList(true)
            }
        } catch (error) {
            console.warn('Error : ' + error)
        }
    }

    const handleSubmitLaserLINKDataEvent = async () => {
        setValidationStatus(false);
        if (Object.keys(errorList)?.filter(errKey => errorList[errKey]?.length > 0)?.length == 0 && getValidationStatus == true) {
            let editLaserLinkItemCopy = JSON.parse(JSON.stringify(editLaserLinkItem));
            if (editLaserLinkItem?.name == '' && batchStatus != true) {
                setValiationTabMessage(prev => ({ ...prev, 'settings': { "error": true } }))
                return "";
            }

            if (editLaserLinkItem.optin_2nd_page_viewed) {
                editLaserLinkItem.opt_in.show_delay = 1;
                editLaserLinkItem.opt_in.show_on_page = '';
            }

            let optionsPayload = { ...defaultPopupOptions, ...editLaserLinkItem.opt_in };
            let laserLinkPayload = batchStatus == true ? {
                ...editLaserLinkItem,
                "optin_options": { ...optionsPayload },

            } : editLaserLinkItem;
            let laserLINKURL = batchStatus == true ? 'create_batch_links.json' : editLaserLinkItem?.id != undefined ? `links/${editLaserLinkItem?.id}.json` : 'links.json';
            let submitLaserLINKData = await networkService(laserLINKURL, editLaserLinkItem?.id != undefined ? 'PATCH' : 'POST', laserLinkPayload);
            let linkOpitonsURL = editLaserLinkItem?.id != undefined ? `link_options/${submitLaserLINKData?.data?.tracking_link_option?.id}.json` : 'link_options.json';
            if (submitLaserLINKData.status == 201 || submitLaserLINKData.status == 200) {
                if (batchStatus != true) {
                    let submitLaserLINKOpitionsData = await networkService(linkOpitonsURL, editLaserLinkItem?.id != undefined ? 'PATCH' : 'POST', { ...optionsPayload, 'tracking_link_id': submitLaserLINKData.data.id });
                    setEditLaserLinkItem(prevData => ({ ...prevData, ...submitLaserLINKData.data, "opt_in": { ...submitLaserLINKOpitionsData.data }, "thumb": submitLaserLINKData?.data?.thumbnail, "expire_time": (submitLaserLINKData?.data?.expire_time?.split(' ')[1] ?? "0:0") }));
                } else {
                    setEditLaserLinkItem(prevData => ({ ...prevData, ...submitLaserLINKData.data, "thumb": submitLaserLINKData?.data?.thumbnail, "expire_time": (submitLaserLINKData?.data?.expire_time?.split(' ')[1] ?? "0:0") }));
                }
                setValidationStatus(false);
                if (batchStatus == true) {
                    setEditLaserLinkItem(null)
                } else {
                    setSaveButtonStatus(true);
                }
                setShowNotifications({
                    showToast: true,
                    toastType: "success",
                    toastMessage: submitLaserLINKData?.data?.message || 'LaserLINK saved',
                })
            }
        } else if (Object.keys(errorList)?.filter(errKey => errorList[errKey]?.length > 0)?.length > 0 && getValidationStatus == true) {
            setShowNotifications({
                showToast: true,
                toastType: "alert",
                toastMessage: 'Please review the errors',
            })
        }
    }

    const handleLaserLINKSCreateModal = async () => {
        let domainListData = await updateDomainListEvent();
        let profileData = await updateProfileEvent();
        let getUserData = await networkService(`get-users.json`, 'POST', { 'field': "current_user", 'value': true });
        let valueToUpdate = {
            ...defaultLaserLINKData,
            "doc_files_languages": docData.doc_files_languages,
            "doc_id": docData?.id,
            "domain": domainListData?.domains?.find(domain => domain?.is_default == true)?.name ?? '',
            "notification_emails": getUserData.data,
            "thumbnail": docData?.thumb,
            "thumb": docData?.thumb,
            "expire_time_zone": profileData?.time_zone ?? '',
            "expire_time": docData?.expire_time?.split(' ')[1] ?? "0:0",
            "opt_in": { ...defaultPopupOptions }
        };
        setEditLaserLinkItem(valueToUpdate);
        updateLaserLinkData(docData?.id, 'active');
        updateUserLightboxesDoc(docData);
        updateUserLightboxesPage(docData);
        updateUserExitPopData(docData);
    }

    useEffect(() => {
        handleSubmitLaserLINKDataEvent();
    }, [errorList])

    useEffect(() => {
        setSaveButtonStatus(null);
        if (laserItem != null && laserItem != true) {
            handleLaserLINKSettingsModal(laserItem)
        } else if (laserItem == true) {
            handleLaserLINKSCreateModal();
        }
    }, [laserItem]);

    useEffect(() => {
        if (editLaserLinkItem == null) {
            updateSelectedKDFLink(null)
        }
    }, [editLaserLinkItem])

    return (
        <>
            <CommonModal
                modalStatus={embedCode != null}
                title={'Embed'}
                footer={
                    <Button text={'Copy embed code </>'} classList={'success laser-link-btn'} addonClass={'my-2 mx-1'} onClick={async () => { await navigator.clipboard.writeText(embedCode ?? ''); setEmbedCode(null) }} />
                }
                changeActiveStatus={(status) => !status && setEmbedCode(null)}
                classList={'embed-modal'}
                centered
            >
                <textarea value={embedCode} onChange={em => setEmbedCode(prev => prev += em.target.value)} style={{ width: '100%' }} rows={10} />
            </CommonModal>
            <CommonModal
                className={'laserLinkModal'}
                title={
                    batchStatus == true ?
                        <strong>Batch Link Creation:</strong>
                        : <Input
                            value={editLaserLinkItem?.name}
                            placeholder="Insert LaserLINK title here"
                            name="name"
                            onChange={(e) => { handleOnchangeEvent(e); setSaveButtonStatus(false); }}
                            required={true}
                            autoFocus={true}
                            errorMessageStatus={errorList?.settings?.includes('name')}
                        />
                }
                changeActiveStatus={(status) => !status && (setEditLaserLinkItem(null), triggerCancelProcess(null))}
                modalStatus={editLaserLinkItem != null}
                centered
                footer={
                    <div className="d-flex justify-content-between w-100 align-items-center">
                        <div className="">
                            {editLaserLinkItem?.tracking_link &&
                                <>
                                    <a href={editLaserLinkItem?.tracking_link ?? '#'} target="_blank" ref={trackingLinkRef}>
                                        {editLaserLinkItem?.tracking_link ?? ''}
                                    </a>
                                    <CustomTooltip
                                        title={<p className="p-1">Copy LaserLINK</p>}
                                        light={true}
                                    >
                                        <FontAwesomeIcon
                                            icon={faClipboard}
                                            className="mx-2 cursor-pointer"
                                            onClick={async () => {
                                                await navigator.clipboard.writeText(editLaserLinkItem?.tracking_link ?? '');
                                                const range = document.createRange();
                                                const selection = window.getSelection();
                                                range.selectNodeContents(trackingLinkRef.current);
                                                selection.removeAllRanges();
                                                selection.addRange(range);
                                            }}
                                        />
                                    </CustomTooltip>
                                </>
                            }
                        </div>
                        <div className="d-flex align-end">
                            {editLaserLinkItem?.tracking_link &&
                                <Button text={'Embed'} classList={'primary laser-link-btn'} addonClass={'my-2 mx-1'} onClick={() => setEmbedCode(createEmbedIframeCode(editLaserLinkItem?.tracking_link))} />}
                            <Button
                                disabled={saveButtonStatus == null}
                                text={`${saveButtonStatus == true ? 'Copy LaserLINK and close' : (batchStatus != true ? 'Save changes' : 'Save and Close')}`}
                                classList={`success laser-link-btn ${(saveButtonStatus == null || !editLaserLinkItem?.name || isError) ? 'disabled' : ''}`}
                                addonClass={'my-2 mx-1'}
                                onClick={handleSaveLaserLINKData}
                            />
                        </div>
                    </div>
                }
            >
                <Tabs
                    defaultActiveKey="settings"
                    className={`linkTabs`}
                >
                    <Tab eventKey="settings"
                        className={`nnnnn`}
                        title={
                            <>
                                LaserLINK Settings {
                                    errorList?.settings?.length > 0 ?
                                        <FontAwesomeIcon
                                            icon={faTriangleExclamation}
                                            className="alert-icon"
                                        /> : ''
                                }
                            </>
                        }>
                        <LaserLINKSettingsTab
                            editLaserLinkItem={editLaserLinkItem}
                            handleOnchangeEvent={handleOnchangeEvent}
                            updateEditLaserLinkData={(value) => { setEditLaserLinkItem(value); setSaveButtonStatus(false); }}
                            getDomains={getDomains}
                            getDocFiles={getDocFiles}
                            validationTrigger={getValidationStatus}
                            errorListData={errorList?.settings}
                            updateErrorStatus={(status) => setErrorList(prevState => ({ ...prevState, 'settings': status }))}
                            // updateValidationTab={(value) => setValiationTabMessage(prev => ({...prev, 'settings': value}))}
                            batchStatus={batchStatus}
                            setIsError={setIsError}
                        />
                    </Tab>
                    <Tab eventKey="access" title="Access Gate Options">
                        <AccessGateOptionsTab
                            editLaserLinkItem={editLaserLinkItem}
                            updateEditLaserLinkItem={(value) => { setEditLaserLinkItem(value); setSaveButtonStatus(false); }}
                            time_zone_list={time_zone_list}
                            getDocFiles={getDocFiles}
                            defaultLinkTexts={getDefault_link_texts}
                            updateDefaultLink={setDefault_link_texts}
                            validationTrigger={getValidationStatus}
                            errorListData={errorList?.access}
                            updateErrorStatus={(status) => setErrorList(prevState => ({ ...prevState, 'access': status }))}
                            getProfile={getProfile}
                            // updateValidationTab={(value) => setValiationTabMessage(prev => ({...prev, 'access': value}))}
                            batchStatus={batchStatus}
                        />
                    </Tab>
                    <Tab eventKey="popup" title="Popup Options">
                        <PopupOptionsTab
                            editLaserLinkItem={editLaserLinkItem}
                            updateEditLaserLinkItem={(value) => { setEditLaserLinkItem(value); setSaveButtonStatus(false); }}
                            popupOptions={popupOptions}
                            validationTrigger={getValidationStatus}
                            errorListData={errorList?.popup}
                            updateErrorStatus={(status) => setErrorList(prevState => ({ ...prevState, 'popup': status }))}
                            getDocFiles={getDocFiles}
                            // updateValidationTab={(value) => setValiationTabMessage(prev => ({...prev, 'popup': value}))}
                            batchStatus={batchStatus}
                        />
                    </Tab>
                </Tabs>
            </CommonModal>
        </>
    )
}

export default LaserLinkModal;