import React, { useContext, useEffect, useState } from "react"
import CommonModal from "../CommonModal"
import CustomTable from "../../CommonComponents/Table/CustomTable";
import { networkService } from "../../config/axios";
import Button from "../../Elements/Button";
import UserInfoContext from "../../../utis/userInfoContext";

const CompanyUsersModal = ({ openModal, closeModal, companyId, selectedUserId }) => {

    const CompanyUsersModalBody = () => {
        const { setShowNotifications } = useContext(UserInfoContext)

        const [users, setUsers] = useState([])

        useEffect(() => {
            const getUsers = async () => {
                const response = await networkService(`admin/company-users/${companyId}.json`, "GET");
                delete response?.data?.users_length;
                delete response?.data[selectedUserId]
                setUsers([...Object.values(response?.data)])
            };
            getUsers(companyId);
        }, [companyId]);

        const handleMove = async (userId) => {
            try {
                const response = await networkService(`admin/users/${selectedUserId}.json?copy_user_id=${userId}`, 'DELETE');
                if (response.status == 204) {
                    closeModal()
                    setShowNotifications({
                        showToast: true,
                        toastType: "success",
                        toastMessage: "User account has been banned!",
                    })
                }
            } catch (error) {
                setShowNotifications({
                    showToast: true,
                    toastType: 'danger',
                    toastMessage: "Something went wrong"
                })
            }
        }

        const columns = React.useMemo(() => {
            return [
                {
                    Header: 'User Name',
                    accessor: 'name',
                },
                {
                    Header: 'Email',
                    accessor: 'email',
                },
                {
                    Header: 'User Type',
                    accessor: 'type_name',
                    Cell: ({ row: { original } }) => (<>{original?.type_name}</>)
                },
                {
                    Header: 'Select User',
                    accessor: 'action',
                    Cell: ({ row }) => (
                        <Button
                            text={'Move Here'}
                            classList={'success'}
                            onClick={() => handleMove(row?.original?.id)}
                        />
                    ),
                },
            ];
        }, [users]);

        return (
            <>
                <CustomTable data={users} columnData={columns} showFilters={false} />
            </>
        )
    }
    return (
        <CommonModal
            title={"Copy Data"}
            modalStatus={openModal}
            changeActiveStatus={(status) => closeModal(false)}
            classList="header-profile-modal-wrapper"
            centered
        >
            <CompanyUsersModalBody />
        </CommonModal>
    )
}
export default CompanyUsersModal