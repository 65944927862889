import React, { useContext, useEffect, useMemo, useState } from "react"
import useStatHook from "../../../Stats/StatsHook/useStatHook";
import PdfElement from "../pdfEditorElements/PdfElement";
import { EditorInfoContext } from "../../../../../utis/userInfoContext";
import useCanvasHook from "../editorHook/useCanvasHook";
import ElementOrder from "../elementOrder/ElementOrder";

const PdfElementsList = ({ timerOnDoc, zoom, docFileId, index, canvasRefs, editortype }) => {
    const {
        elements,
        pageNumber,
        setPageNumber,
        setElements
    } = useContext(EditorInfoContext);
    const { normalizeDimensions } = useCanvasHook();
    const { elemClick } = useStatHook() || {};
    const [resizeDimensions, setResizeDimensions] = useState({});
    const [actionElement, setActionElement] = useState({});

    const arrayOfElements = useMemo(() => {
        return Object.values(Object.entries(elements).length !== 0 && elements);
    }, [elements]);

    const handleAction = (element) => {
        const { desiredaction, thisElement } = element?.dropdown_data;
        if (element?.dropdown_data) {
            setActionElement({
                ...actionElement,
                [thisElement == 'thisElement' ? element?.id : thisElement]: {
                    targetElement: thisElement == 'thisElement' ? element?.id : thisElement,
                    visibility:
                        desiredaction === "hide"
                            ? false
                            : desiredaction === "reveal"
                                ? true
                                : !actionElement[thisElement]?.visibility
                },
            });
        }
    };

    const handlePDFChangeAction = (value) => {
        if (value == pageNumber) {
            const allCanvasElements = document.getElementsByClassName('react-pdf__Page');
            const canvasArray = Array.from(allCanvasElements);
            const targetCanvas = canvasArray.find((canvas) => canvas.getAttribute('data-page-number') === `${value}`);
            if (targetCanvas) {
                targetCanvas.scrollIntoView({ behavior: "instant" });
            }
        }
        setPageNumber(value);
    }

    useEffect(() => {
        setElements(window.objInit?.elements);
    }, []);

    return (
        <>
            {arrayOfElements &&
                arrayOfElements?.map((element) => {
                    let dimensions = {
                        top: normalizeDimensions(
                            element?.geometry?.top,
                            1,
                            zoom?.value
                        ),
                        left: normalizeDimensions(
                            element?.geometry?.left,
                            1,
                            zoom?.value
                        ),
                        height: normalizeDimensions(
                            element?.geometry?.height,
                            1,
                            zoom?.value
                        ),
                        width: normalizeDimensions(
                            element?.geometry?.width,
                            1,
                            zoom?.value
                        ),
                    };
                    return (
                        (docFileId == element?.docs_file_id) && !!element?.geometry?.top && !!element?.geometry?.left && !!element?.geometry?.height && !!element?.geometry?.top &&
                        index + 1 === element?.page && (
                            <div
                                id={'div' + element?.id}
                                key={element?.id}
                                style={{
                                    position: "absolute",
                                    userSelect: 'none',
                                    zIndex: 99,
                                    top: `${resizeDimensions[element.id]?.top || dimensions.top}px`,
                                    left: `${resizeDimensions[element.id]?.left || dimensions.left}px`,
                                    width: `${resizeDimensions[element.id]?.width || dimensions.width}px`,
                                    height: `${resizeDimensions[element.id]?.height || dimensions.height}px`,
                                    ...(Number(element?.id) ===
                                        Number(actionElement[element?.id]?.targetElement) && {
                                        visibility: `${actionElement[element?.id]?.visibility ? "visible" : "hidden"}`,
                                    }),
                                    transform: `rotate(${element?.rotate ?? 0}deg)`,
                                }}
                                onClick={() => element?.dropdown_data && handleAction(element)}
                            >
                                <PdfElement
                                    element={element}
                                    canvasRef={canvasRefs?.current[index]}
                                    zoom={zoom}
                                    dimensions={dimensions}
                                    setPageNumber={(value) => handlePDFChangeAction(value)}
                                    editortype={editortype}
                                    setResizeDimensions={setResizeDimensions}
                                    resizeDimensions={resizeDimensions}
                                    timerOnDoc={timerOnDoc}
                                    elemClick={elemClick}
                                />
                            </div>
                        )
                    );
                })}
            {/* <ElementOrder
                arrayOfElements={arrayOfElements}
            /> */}
        </>
    )
}
export default PdfElementsList