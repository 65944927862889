import React, { useEffect, useState } from "react"
import { debounce, searchData } from "../../../hooks/utils";

const SearchComponent = ({ data, setCurrentPageData }) => {
    const [search, setSearch] = useState('')

    const debouncedSearch = debounce((search) => {
        const results = searchData(data, search);
        setCurrentPageData(results);
    }, 500);

    useEffect(() => {
        if (search.length > 0) {
            debouncedSearch(search);
        }
    }, [search]);

    return (
        <>
            <div className="Search-inp mt-2">
                <div>
                    <input
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                        placeholder="Search..." />
                </div>
            </div>
        </>
    )
}
export default SearchComponent