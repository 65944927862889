import React from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const ColumnChart = ({ data, name }) => {
    const graphColors = ['rgb(52,152,219)', 'rgb(231,76,60)', 'rgb(46,204,113)', 'rgb(241,196,15)', 'rgb(142,68,173)'];

    const graphData = data?.map((item, index) => ({
        y: item?.[name ?? 'hits'],
        color: graphColors[index % graphColors.length],
    }));
    const categories = data?.map((item, idx) => item?.y ?? `Visit ${idx + 1}`);

    const columnOptions = {
        chart: {
            type: 'column',
        },
        title: {
            text: '',
        },
        tooltip: {
            pointFormat: '{point.y} Visits', // Customize tooltip format
        },
        xAxis: {
            categories: categories, // Set categories for x-axis
            title: {
                text: 'visits', // Optional: Add a title to the x-axis
            },
        },
        yAxis: {
            title: {
                text: 'Visits',
            },
            labels: {
                formatter: function () {
                    return this.value;
                },
            },
        },
        legend: {
            enabled: false, // Hides the legend
        },
        series: [
            {
                name: name,
                data: graphData,
            },
        ],
    };

    return (
        <>
            <HighchartsReact highcharts={Highcharts} options={columnOptions} />
        </>
    );
};

export default ColumnChart;