import React, { useContext, useEffect, useState } from "react";
import CustomTable from "../../CommonComponents/Table/CustomTable";
import { networkService } from "../../config/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { csrfToken } from "../../../utis/csrfTokenUtils";
import Paginate from "../../CommonComponents/Pagination/Paginate";
import UpdateRecipientModal from "../Stats/UpdateRecipientModal";
import DeleteOrgModal from "../../Modals/Admin/DeleteOrgModal";
import UserInfoContext from "../../../utis/userInfoContext";

const RecipientsPage = () => {
    const [recipients, setRecipients] = useState({});
    const [receiver, setReceiver] = useState({});
    const [page, setPage] = useState(1);
    const [editRecipientModal, setEditRecipientModal] = useState(null);
    const [recipientData, setRecipientData] = useState(null)
    const { setShowNotifications } = useContext(UserInfoContext)

    const fetchOrgsRecipients = async (e) => {
        if (e) e.preventDefault();
        const response = await networkService("recipients/search", "POST", { page: 1, receiver, authenticity_token: csrfToken() });
        if (Object.keys(response?.data)?.length > 0) {
            setRecipients({ ...response?.data });
        } else {
            setRecipients([]);
        };
    };

    useEffect(() => {
        fetchOrgsRecipients();
    }, []);

    const resetFilters = (e) => {
        e.preventDefault();
        setReceiver({});
        fetchOrgsRecipients();
    }

    const handleFilters = (key, value) => {
        setReceiver({
            ...receiver,
            [key]: value,
        });
    }

    const handleUpdateReceipient = async (formData) => {
        let payload = {};
        payload["receiver"] = {
            ...formData,
            name: `${formData?.first_name} ${formData?.last_name}`,
        };
        if (formData?.id == undefined) {
            payload["company_id"] = editRecipientModal?.tracking_link?.company_id;
            payload["session_id"] = editRecipientModal?.id;
            payload["campaign_id"] = editRecipientModal?.tracking_link?.campaign_id;
            payload["tracking_link_id"] = editRecipientModal?.tracking_link?.id;
        }
        const { data, status } = await networkService(
            `/receivers/${formData?.id}/${formData?.id == undefined ? "add_receiver_info" : "update_receiver_info"
            }`,
            "POST",
            payload
        );
        fetchOrgsRecipients();
        setEditRecipientModal(null);
    };

    const handleDelete = async () => {
        try {
            const response = await networkService(`recipients/${recipientData?.id}`, 'DELETE');
            if (response.status == 200) {
                setRecipientData(null)
                setShowNotifications({
                    showToast: true,
                    toastType: "success",
                    toastMessage: "user has been deleted",
                })
                fetchOrgsRecipients();
            }
        } catch (error) {
            setShowNotifications({
                showToast: true,
                toastType: 'danger',
                toastMessage: "Something went wrong"
            })
        }
    }


    const columns = React.useMemo(() => {
        return [
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Name',
                accessor: 'full_name',
                Cell: ({ row }) => (
                    <div style={{
                        width: '140px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>{row?.original?.full_name}</div>
                )
            },
            {
                Header: 'KDFs',
                accessor: 'tracking_links',
                Cell: ({ row }) => (
                    <div>{row?.original?.tracking_links?.length}</div>
                )
            },
            {
                Header: 'Visits',
                accessor: 'visits',
                Cell: ({ row }) => (
                    <div>{row?.original?.sessions?.length}</div>
                )
            },
            {
                Header: 'Pages',
                accessor: 'pages',
                Cell: ({ row }) => (
                    <div>{row?.original?.session_pages?.length}</div>
                )
            },
            {
                Header: 'Top Viewed KDFs',
                accessor: 'top_visited_kdfs',
            },
            {
                Header: 'Most Visited KDF',
                accessor: 'most_visited_kdf',
                Cell: ({ row }) => (
                    <div>{row?.original?.most_visited_kdf?.title}</div>
                )
            },
            {
                Header: 'Actions',
                accessor: 'action',
                Cell: ({ row }) => (
                    <>
                        <FontAwesomeIcon
                            icon={faPen}
                            className="cursor-pointer"
                            onClick={() => setEditRecipientModal(
                                { id: row?.original?.id, receiver: row?.original }
                            )}
                        />
                        <FontAwesomeIcon
                            icon={faTrashCan}
                            className='cancel cursor-pointer ms-1'
                            onClick={() => {
                                setRecipientData({
                                    id: row?.original?.id,
                                    fullName: row?.original?.full_name
                                })
                            }}
                        />
                    </>
                )
            },
        ];
    }, [recipients?.recipients]);

    return (
        <>
            <DeleteOrgModal
                title={"Delete User"}
                openModal={recipientData !== null}
                closeModal={() => setRecipientData(null)}
                name={'delete' + recipientData?.fullName}
                handleDelete={handleDelete}
            />
            <UpdateRecipientModal
                editRecipientModal={editRecipientModal}
                setEditRecipientModal={setEditRecipientModal}
                submitRecipientForm={handleUpdateReceipient}
            />
            <div className="header-content-main">
                <div className="doc-filter">
                    <h4 className="filter-recipients mb-0">Filters</h4>
                    <form className="d-flex">
                        <div className="d-flex form-group">
                            <input className="kdf-Tags1 ms-0" onChange={({ target: { value } }) => handleFilters('email', value)} value={receiver?.email} placeholder="Email" />
                        </div>
                        <div className="d-flex form-group">
                            <input className="kdf-Tags1" type="text" onChange={({ target: { value } }) => handleFilters('name', value)} value={receiver?.name} placeholder="Name" />
                        </div>
                        <div className="d-flex form-group">
                            <input className="kdf-Tags1" type="text" onChange={({ target: { value } }) => handleFilters('title', value)} value={receiver?.title} placeholder="KDF Title" />
                        </div>
                        <button className="btn btn-info  reset" onClick={fetchOrgsRecipients}>
                            Apply Filters
                        </button>
                        <button className="reset-btn" onClick={resetFilters}>
                            Reset Filters
                        </button>
                    </form>
                </div>

                <div className=" doc-filter">
                    <CustomTable data={recipients?.recipients || []} columnData={columns} showFilters={false} />
                    {recipients?.recipients?.length == 0 &&
                        <Paginate
                            currentPage={page}
                            totalPages={Math.ceil(recipients?.total_recepient / 10)}
                            onPageChange={(page = 1) => setPage(page)}
                            totalData={recipients?.total_recepient}
                            itemTitle={'Recipients'}
                        />
                    }
                </div>
            </div>
        </>
    )
}
export default RecipientsPage;
