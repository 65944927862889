import pako from "pako";
import { networkService } from "../components/config/axios";

export const compress = (obj) => {
  // Convert object to JSON string
  const jsonString = JSON.stringify(obj);

  // Compress the JSON string
  const compressed = pako.deflate(jsonString, { to: "string" });

  // Encode compressed data to Base64
  return btoa(compressed);
};

export const decompress = (str) => {
  // Decode Base64 string to compressed binary data
  const compressed = atob(str);

  // Decompress binary data to a JSON string
  const jsonString = pako.inflate(compressed, { to: "string" });

  // Parse JSON string back to an object
  return JSON.parse(jsonString);
};

export const setReceiverCookie = function (cookieKey, cookieValue) {
  var receiverCookie = getCookie("receiver", {});
  receiverCookie[cookieKey] = cookieValue;
  setCookie("receiver", receiverCookie);
};

export const setReceiverSession = async function (id, tlId, sessionId) {
  const url = `/assign-session/${id}/${tlId}/${sessionId}.json`;
  const response = await networkService(url, 'GET');
  return response.data;
};

export const getCookie = function (storageKey, returnedValue) {
  var storageValue = localStorage.getItem(storageKey);
  if (storageValue === null || storageValue === undefined) {
    storageValue = returnedValue;
  } else {
    storageValue = JSON.parse(storageValue);
  }

  return storageValue;
};

export const setCookie = function (storageKey, storageValue) {
  var compressedValue = JSON.stringify(storageValue);
  localStorage.setItem(storageKey, compressedValue);
};

export const dispatchEvent = (name, id) => {
  const event = new Event(name);
  const emitter = document.getElementById(id);
  emitter.dispatchEvent(event);
}

export const scrollPage = (number, behavior) => {
  const allCanvasElements = document.getElementsByClassName('react-pdf__Page');
  const canvasArray = Array.from(allCanvasElements);
  const targetCanvas = canvasArray.find((canvas) => canvas.getAttribute('data-page-number') === `${number}`);
  if (targetCanvas) {
    targetCanvas.scrollIntoView({ behavior: behavior });
  }
}

export const handleClearCookie = async () => {
  networkService("users/sign_out", "delete")
    .then((response) => {
      localStorage.clear();
      window.location.href = "/";
    })
    .catch((error) => {
      console.error("Error during sign out:", error);
    });
};