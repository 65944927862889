import React, { useContext, useEffect, useState } from "react";
import Breadcrumb from "../../../CommonComponents/BreadCrumb/BreadCrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomTable from "../../../CommonComponents/Table/CustomTable";
import DataPerPage from "../../../CommonComponents/Pagination/DataPerPage";
import SearchComponent from "../../../CommonComponents/SearchComponent/SearchComponent";
import Button from "../../../Elements/Button";
import { faInfoCircle, faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { SubscriptionPeriodArr } from "../../../../hooks/utils";
import { networkService } from "../../../config/axios";
import UserInfoContext from "../../../../utis/userInfoContext";
import DeleteOrgModal from "../../../Modals/Admin/DeleteOrgModal";
import CustomTooltip from "../../../Elements/Tooltip";
import AdminPagination from "../../../CommonComponents/Pagination/AdminPagination";

const AdminSubscriptionsPage = () => {
    const { setShowNotifications, profileData } = useContext(UserInfoContext)

    const [subscriptions, setSubscriptions] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [subData, setSubData] = useState({})
    const [currentPageData, setCurrentPageData] = useState([]);
    const [dataPerPage, setDataPerPage] = useState(10)
    const totalData = subscriptions?.length;

    const handlePageChange = (pageNumber) => {
        const indexOfLastData = pageNumber * dataPerPage;
        const indexOfFirstData = indexOfLastData - dataPerPage;
        setCurrentPageData(subscriptions?.slice(indexOfFirstData, indexOfLastData));
    };

    const handleDeleteSubscription = (id) => {
        const url = `admin/subscriptions/${id}.json`;
        networkService(url, "DELETE")
            .then((response) => {
                if (response.status == 204) {
                    setOpenModal(false)
                    setShowNotifications({
                        showToast: true,
                        toastType: "success",
                        toastMessage: "Subscription deleted",
                    })
                }
            })
            .catch((error) => {
                setShowNotifications({
                    showToast: true,
                    toastType: 'danger',
                    toastMessage: "Something went wrong"
                })
            });
    }

    useEffect(() => {
        handlePageChange(1);
    }, [subscriptions])

    useEffect(() => {
        const fetchSubs = async () => {
            const response = await networkService("admin/subscriptions.json", "GET");
            setSubscriptions([...response.data]);
        };
        fetchSubs();
    }, [openModal]);

    const columns = React.useMemo(() => {
        return [
            {
                Header: 'Name',
                accessor: 'name',
                Cell: ({ row: { original } }) => (
                    <> {original?.name}
                        <CustomTooltip
                            title={<p className="p-1 text-light">{original?.description}</p>}
                        >
                            <FontAwesomeIcon
                                icon={faInfoCircle}
                                className="ms-2"
                                style={{ cursor: 'pointer', color: 'blue' }}
                            />
                        </CustomTooltip>

                    </>
                ),
            },
            {
                Header: 'Period',
                accessor: 'period',
                Cell: ({ row: { original } }) => (
                    <>{SubscriptionPeriodArr[original?.period - 1]?.name}</>
                ),
            },
            {
                Header: 'Amount (Discount)',
                accessor: 'amount',
                Cell: ({ row: { original } }) => (
                    <>{original?.amount} ({original?.discount}) </>
                ),
            },
            {
                Header: 'Payment URL',
                accessor: 'payment_url',
            },
            {
                Header: 'Modified By',
                accessor: 'modifier_id',
                Cell: ({ row: { original } }) => (<>{profileData?.id == original?.modifier_id && profileData?.name}</>),

            },
            {
                Header: 'Actions',
                accessor: 'action',
                Cell: ({ row: { original } }) => (
                    <div className="d-flex">
                        <FontAwesomeIcon
                            className="me-2 cursor-pointer success success-green"
                            icon={faPen}
                            onClick={() => {
                                window.location.href = `subscriptions/${original?.id}/edit`
                            }}
                        />
                        <FontAwesomeIcon
                            icon={faTrashCan}
                            className="cursor-pointer cancel"
                            onClick={() => {
                                setSubData(original)
                                setOpenModal(true)
                            }}
                        />
                    </div>
                ),
            },
        ];
    }, [currentPageData, profileData]);

    return (
        <>
            <DeleteOrgModal
                title={'Delete Subscription'}
                openModal={openModal}
                closeModal={setOpenModal}
                name={'delete ' + subData?.name}
                handleDelete={() => handleDeleteSubscription(subData?.id)}
            />
            <Breadcrumb string={'Admin/Subscriptions'} />
            <div className="container">
                <div className="show-select1 d-flex mb-2">
                    <div className="mb-2">
                        <DataPerPage dataPerPage={dataPerPage} setDataPerPage={setDataPerPage} />
                        <SearchComponent
                            data={subscriptions}
                            setCurrentPageData={setCurrentPageData}
                        />
                    </div>
                    <Button
                        classList='success'
                        text={"Add Subscription"}
                        onClick={() => window.location.href = "/admin/subscriptions/new"}
                        type='button'
                    />
                </div>
                <div className="top-parent">
                    {subscriptions && <CustomTable data={currentPageData} columnData={columns} />}
                </div>
                <AdminPagination
                    dataPerPage={dataPerPage}
                    totalData={totalData}
                    onPageChange={handlePageChange}
                />
            </div>
        </>
    )
}
export default AdminSubscriptionsPage;
