import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import CustomTooltip from "../../Elements/Tooltip";
import Select from "../../Elements/Select";
import "../../../../assets/stylesheets/flags.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import styles from "./links.module.css";
import Input from "../../Elements/Input";
import noImage from "../../../../assets/images/icon_upload.svg";
import { networkService } from "../../config/axios";
import utilsUploadFormData from "../../../utis/upoadFormData";
import * as XLSX from "xlsx";
import CustomTagsInput from "../../CommonComponents/Tags/CustomTagsInput";
import CustomDropdown from "../../CommonComponents/CustomDropDown/CustomDropDown";
import { csrfToken } from "../../../utis/csrfTokenUtils";


const LaserLINKSettingsTab = ({
  editLaserLinkItem,
  handleOnchangeEvent,
  updateEditLaserLinkData,
  getDomains,
  getDocFiles,
  validationTrigger,
  updateErrorStatus,
  errorListData,
  batchStatus = null,
  setIsError
}) => {
  const receipientTypeViewNotify = useRef(null);
  const receipientTypeViewNotifyPage = useRef(null);
  const [batchFile, setBatchFile] = useState(null);
  const [filteredCampaign, setFilteredCampaign] = useState("");
  const [campaignListData, setCampaignListData] = useState([]);
  const [laserLinkType, setLaserLinkType] = useState("individual");
  const [formErrorData, setFormErrorData] = useState([]);
  const [specificEmails, setSpecificEmails] = useState([])

  const handleUpdateCampaign = async (value) => {
    if (value != undefined && value?.trim() != "") {
      let campaignValueToUpdate = {};
      let campaignExist = campaignListData?.filter(
        (item) => item?.name == value
      );
      if (campaignExist?.length > 0) {
        campaignValueToUpdate = {
          campaign: campaignExist[0].name,
          campaign_id: campaignExist[0].id,
        };
      } else {
        let campaignPayload = { name: value };
        let { data } = await networkService(
          `campaigns.json`,
          "POST",
          campaignPayload
        );
        campaignValueToUpdate = { campaign: data, campaign_id: data.id };
      }
      updateEditLaserLinkData({
        ...editLaserLinkItem,
        ...campaignValueToUpdate,
      });
      updateErrorStatus(errorListData?.filter((it) => it != "campaign"));
    } else {
      updateEditLaserLinkData({
        ...editLaserLinkItem,
        campaign: null,
        campaign_id: null,
      });
    }
  };

  const handleUpdateTags = async (value, status = null) => {

    let stateToUpdate = { ...editLaserLinkItem };
    if (value != "" && status == null) {
      let updateTagsToDoc = await utilsUploadFormData(
        `tags.json`,
        { name: value },
        "POST"
      );
      if (updateTagsToDoc?.data != undefined) {
        stateToUpdate["tag_ids"] = [
          ...editLaserLinkItem.tag_ids,
          updateTagsToDoc.data.id,
        ];
        stateToUpdate["tags"] = [
          ...editLaserLinkItem.tags,
          updateTagsToDoc.data,
        ];
      }
    } else {
      stateToUpdate["tag_ids"] = editLaserLinkItem.tags
        ?.map((item) => value == item.name)
        ?.map((item) => item.id);
      stateToUpdate["tags"] = editLaserLinkItem.tags?.map((item) =>
        value == item.name
      );
    }
    updateEditLaserLinkData({ ...editLaserLinkItem, ...stateToUpdate });
  };

  const handleValidateBatchFile = (fileGet) => {
    if (fileGet) {
      const fileType = fileGet.type;
      const fileName = fileGet.name;
      if (
        fileType === "application/vnd.ms-excel" ||
        fileType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const binaryStr = event.target.result;
          const workbook = XLSX.read(binaryStr, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const data = XLSX.utils.sheet_to_json(worksheet);
          updateEditLaserLinkData({
            ...editLaserLinkItem,
            batch_link_array: data,
            file_name: fileName,
          });
          // setExcelData(data);
        };
        reader.onerror = () => {
          // console.warn("Error reading the file.");
          setBatchFile(false);
        };
        reader.readAsBinaryString(fileGet);
        setBatchFile(fileGet);
      } else {
        setBatchFile(false);
      }
    }
  };

  const validateInnerData = () => {
    let errorToUpdate = JSON.parse(JSON.stringify(errorListData));

    // Validate campaign for "public" laserLinkType
    if (laserLinkType === "public" && editLaserLinkItem?.campaign_id == null) {
      if (!errorToUpdate?.includes("campaign")) {
        errorToUpdate.push("campaign");
      }
    } else {
      errorToUpdate = errorToUpdate?.filter((it) => it !== "campaign");
    }

    // Validate notify_when_page conditions
    if (
      editLaserLinkItem?.receipient_type === "notify_when_page" &&
      (
        editLaserLinkItem?.notify_for_page <= 0 ||
        editLaserLinkItem?.notify_for_page > getDocFiles[0]?.num_pages ||
        editLaserLinkItem?.notify_page_after < 0 ||
        editLaserLinkItem?.notify_page_after == ''
      )
    ) {
      if (!errorToUpdate?.includes("notify_page")) {
        errorToUpdate.push("notify_page");
      }
    } else {
      errorToUpdate = errorToUpdate?.filter((it) => it !== "notify_page");
    }

    // Validate batch file
    if (batchFile === false) {
      if (!errorToUpdate?.includes("batch_link")) {
        errorToUpdate.push("batch_link");
      }
    } else {
      errorToUpdate = errorToUpdate?.filter((it) => it !== "batch_link");
    }

    // Update error status
    updateErrorStatus([...errorToUpdate]);
  };

  const handleDefaultSettings = async () => {
    const { data } = await networkService("campaigns.json", "GET");
    if (data?.length > 0) {
      setCampaignListData(data);
    }
  };

  const fetchEmails = async () => {
    const response = await networkService("get-users.json", "POST", {
      authenticity_token: csrfToken(),
      field: "current_user",
      value: true
    });
    setSpecificEmails(response.data);
  };

  // const debouncedFetchEmails = useCallback(debounce(fetchEmails, 500), []);

  useEffect(() => {
    if (editLaserLinkItem?.campaign_id != null) {
      setLaserLinkType("public");
    }

    handleDefaultSettings();
  }, []);

  useEffect(() => {
    fetchEmails();
  }, [])

  useEffect(() => {
    if (validationTrigger == true) {
      validateInnerData();
    }
  }, [validationTrigger]);

  const handleErrorImage = (element) => {
    element.target.src = noImage;
  };

  return (
    <Row className="laserlink-main-sec">
      <Col md={7}>
        {batchStatus == true && (
          <CustomTooltip
            title={
              <p className="p-1 text-light">Please upload an excel file containing columns for: LaserLINK Title, First Name, Last Name, and Email Address so that you will know exactly which LaserLINK has been configured for each of your recipients. Link Title is required, needs to be UNIQUE, and located in the first column of your spreadsheet. Avoid using tabs, commas, semicolons or any other special characters in the LaserLINK Title. Each row should contain one recipient.</p>
            }
            placement={"bottom"}
          >
            <input
              type={"file"}
              className={`w-100 border p-2 ${errorListData.includes("batch_link") ? "has-error" : ""
                }`}
              accept=".xls,.xlsx"
              onChange={(e) => handleValidateBatchFile(e.target.files[0])}
            />
          </CustomTooltip>
        )}
        <Row className="mb-3">
          <Col md={4} className="laser-imgs-center">
            {batchStatus != true && (
              <a
                href={`${editLaserLinkItem?.viewer}`}
                target="_blank"
                className="laser-img-popup"
              >
                <img
                  src={editLaserLinkItem?.thumb || editLaserLinkItem?.thumbnail || noImage}
                  alt={editLaserLinkItem?.name}
                  width="100%"
                />
              </a>
            )}
          </Col>
          <Col md={8}>
            <Form.Check
              className="laser-setting-popup"
              inline
              label="Create an individual LaserLINK"
              name="link_type"
              type={"radio"}
              id={`laserLINK-individual-radio`}
              value="individual"
              checked={laserLinkType == "individual"}
              onClick={() => {
                setLaserLinkType("individual");
                updateEditLaserLinkData({
                  ...editLaserLinkItem,
                  campaign: null,
                  campaign_id: null,
                });
              }}
            />
            <Form.Check
              className="laser-setting-popup"
              inline
              label="Create a public LaserLINK"
              name="link_type"
              type={"radio"}
              id={`laserLINK-public-radio`}
              value="public"
              checked={laserLinkType == "public"}
              title="This type of LaserLINK is designed to be shared via social media."
              onClick={() => setLaserLinkType("public")}
            />
            {laserLinkType == "public" && (
              <div className="custom-tags">
                <CustomTagsInput
                  tags={
                    editLaserLinkItem?.campaign != undefined
                      ? [editLaserLinkItem?.campaign]
                      : []
                  }
                  placeholderText="Campaign..."
                  limit={1}
                  handleRemoveTags={() => handleUpdateCampaign()}
                  autoSubmit={
                    campaignListData?.filter((item) =>
                      item?.name.includes(filteredCampaign)
                    )?.length > 0
                  }
                  triggerKeyDownEvent={(value) => {
                    if (editLaserLinkItem?.campaign == undefined) {
                      setFilteredCampaign(value);
                    }
                  }}
                  handleAddTags={(value) => {
                    handleUpdateCampaign(value);
                  }}
                />
                {filteredCampaign != "" && (
                  <ul>
                    {campaignListData
                      ?.filter((item) => item?.name.includes(filteredCampaign))
                      .map((item, index) => (
                        <li
                          key={item.id ?? index}
                          onClick={() => {
                            setFilteredCampaign("");
                            handleUpdateCampaign(item.name);
                          }}
                          className="cursor-pointer"
                        >
                          {item.name}
                        </li>
                      ))}
                  </ul>
                )}
              </div>
            )}

            <Form.Check
              className="laser-setting-popup"
              inline
              label="As Template"
              name="template"
              type={"checkbox"}
              id={`laserLINK-template-checkbox`}
              checked={editLaserLinkItem?.template}
              onChange={() =>
                updateEditLaserLinkData({
                  ...editLaserLinkItem,
                  template: !editLaserLinkItem?.template,
                })
              }
            />
            <CustomTooltip
              title={<p className="p-1 text-light">To create KDF to KDF linking use this checkbox</p>}
            >
              <Form.Check
                className="laser-setting-popup"
                inline
                label="KDF to KDF Link"
                name="kdf_action_link"
                type={"checkbox"}
                id={`laserLINK-action-checkbox`}
                checked={editLaserLinkItem?.kdf_action_link}
                onChange={() =>
                  updateEditLaserLinkData({
                    ...editLaserLinkItem,
                    kdf_action_link: !editLaserLinkItem?.kdf_action_link,
                  })
                }
              />
            </CustomTooltip>
          </Col>
        </Row>
        <div className="ms-5 domain-select-lab">
          <div className="d-flex mb-2 Domain-select">
            <label className="me-3">Domain</label>
            <Select
              classList=""
              name="domain"
              noDefault={true}
              onChange={(e) => handleOnchangeEvent(e)}
              defaultValue={
                editLaserLinkItem?.domain != ""
                  ? editLaserLinkItem?.domain
                  : getDomains?.domains?.filter(
                    (item) => item.is_default == true
                  )
              }
              optionList={getDomains?.domains?.map((item) => {
                return { ...item, title: item?.name };
              })}
            />
          </div>
          <div className="d-flex align-items-center hook-second-inp laserlink-custom-tags">
            <label className="">Tags
              <CustomTooltip title={<p className="p-1 text-light">You can add a tag by pressing enter, tab, delimiting with comma or clicking outside the tags area</p>}>
                <FontAwesomeIcon icon={faQuestion} className="custom-tag-icon mx-2" />
              </CustomTooltip>
            </label>
            <CustomTagsInput
              tags={editLaserLinkItem?.tags || []}
              placeholderText="Add a new tag..."
              handleAddTags={(value) => {
                handleUpdateTags(value)
              }}
              handleRemoveTags={(value) => {
                handleUpdateTags(value, 'remove')
              }}
            />
          </div>

          <div
            className={`d-flex align-items-center hook-inp ${styles.inputIconWrapper}`}
          >
            <label className="mt-2">Hook</label>
            <CustomTooltip
              title={
                <p className="p-1 text-light">Insert text here that will be displayed along with the KDF title when you share LaserLINX via social media. Use this text to encourage recipients to click the LaserLINK you shared with them.</p>
              }
            >
              <FontAwesomeIcon
                icon={faQuestion}
                className="custom-tag-icon mt-2 mx-2"
              />
            </CustomTooltip>
            <Input
              name="seo_description"
              value={editLaserLinkItem?.seo_description}
              onChange={(e) => handleOnchangeEvent(e)}
            />
          </div>
        </div>

        {/* {JSON.stringify(editLaserLinkItem?.send_engagement_notif)} */}
        <div className="d-flex mb-2 mt-3">
          <label className="send-mail-notify">Send email notification</label>
          <Form.Check
            className="laser-setting-popup d-inline-block"
            type="switch"
            id="email-notification-switch"
            name="send_engagement_notif"
            checked={
              editLaserLinkItem?.send_engagement_notif != undefined &&
                editLaserLinkItem?.send_engagement_notif == true
                ? editLaserLinkItem?.send_engagement_notif
                : false
            }
            onChange={(element) =>
              updateEditLaserLinkData({
                ...editLaserLinkItem,
                send_engagement_notif:
                  !editLaserLinkItem?.send_engagement_notif,
              })
            }
          />
        </div>
        {!!editLaserLinkItem?.send_engagement_notif && (
          <>
            <div className="d-flex align-items-center laserLINK-email-custom-tags TO-mail">
              <label className="mx-2">to </label>
              <CustomTagsInput
                targetKey='email'
                tags={specificEmails || []}
                placeholderText="Email..."
                handleAddTags={(value) => {
                  if (typeof value == "string") {
                    updateEditLaserLinkData({
                      ...editLaserLinkItem,
                      notification_emails: [...editLaserLinkItem?.notification_emails, { email: value }],
                    })
                    setSpecificEmails([...specificEmails, { email: value }])
                  }
                }}
                handleRemoveTags={(value) => {
                  updateEditLaserLinkData({
                    ...editLaserLinkItem,
                    notification_emails: value,
                  })
                  setSpecificEmails(value)
                }}
                isEmail={true}
              />
            </div>
            <div>
              <p className="mt-4 mb-2 rec-whe">when the recipient:</p>
              <div className="d-flex cursor-pointer ">
                <Form.Check
                  className="laser-setting-popup kdf-view-second"
                  inline
                  name="notify_when"
                  type={"radio"}
                  id={`notify-kdf-radio-notify_when`}
                  value="notify_when"
                  checked={editLaserLinkItem?.receipient_type == "notify_when"}
                  onChange={(element) => {
                    updateEditLaserLinkData({
                      ...editLaserLinkItem,
                      receipient_type: element.target.value,
                    });
                    if (element.target.value == "notify_when") {
                      receipientTypeViewNotify.current.focus();
                    }
                  }}
                  label={
                    <span className="cursor-pointer ">
                      views this KDF for more than
                      <CustomTooltip title={<p className="p-1 text-light">Please enter the time in seconds</p>}>
                        <input
                          className="edit-box-input"
                          type="number"
                          value={editLaserLinkItem?.notify_after}
                          onChange={(e) =>
                            updateEditLaserLinkData({
                              ...editLaserLinkItem,
                              notify_after: e.target.value,
                              notify_for_page: "",
                              notify_page_after: "",
                            })
                          }
                          ref={receipientTypeViewNotify}
                        />
                      </CustomTooltip>
                      seconds
                      <CustomTooltip
                        placement="left"
                        title={
                          <p>
                            Time on page is measured while the recipient is
                            actually on the page.
                            <br />
                            The timer is stopped when:
                            <br />- the recipient switches tabs
                            <br />- the recipient switches windows
                            <br />- the window is not active
                            <br />- the window is active, but the screen lock is
                            on
                          </p>
                        }
                        light={true}
                      >
                        <FontAwesomeIcon
                          icon={faQuestion}
                          className="custom-tag-icon mx-2"
                        />
                      </CustomTooltip>
                    </span>
                  }
                />
              </div>
              <div className="d-flex cursor-pointer">
                <Form.Check
                  className="laser-setting-popup kdf-view-second"
                  inline
                  name="notify_when"
                  type={"radio"}
                  id={`notify-kdf-radio-notify_when_page`}
                  value="notify_when_page"
                  checked={editLaserLinkItem?.receipient_type === "notify_when_page"}
                  onChange={(element) => {
                    updateEditLaserLinkData({
                      ...editLaserLinkItem,
                      receipient_type: element.target.value,
                    });
                    if (element.target.value === "notify_when_page") {
                      receipientTypeViewNotifyPage.current.focus();
                    }
                  }}
                  label={
                    <span className="cursor-pointer">
                      views page
                      <CustomTooltip
                        title={<p className="p-1 text-light">Please enter a page between 1 and {getDocFiles[0]?.num_pages ?? 0}</p>}
                      >
                        <input
                          className={`edit-box-input ${formErrorData?.includes("notify_for_page") ? "box-input-error" : ""
                            }`}
                          type="number"
                          value={editLaserLinkItem?.notify_for_page ?? ""}
                          ref={receipientTypeViewNotifyPage}
                          onChange={(e) => {
                            const value = Number(e.target.value);
                            if (value > (getDocFiles[0]?.num_pages ?? Infinity)) {
                              if (!formErrorData?.includes("notify_for_page")) {
                                setIsError(true)
                                setFormErrorData((prevState) => [...prevState, "notify_for_page"]);
                              }
                            } else {
                              setIsError(false)
                              setFormErrorData((prevState) =>
                                prevState.filter((it) => it !== "notify_for_page")
                              );
                            }
                            updateEditLaserLinkData({
                              ...editLaserLinkItem,
                              notify_for_page: e.target.value,
                              notify_after: "",
                            });
                          }}
                          onClick={() =>
                            updateEditLaserLinkData({
                              ...editLaserLinkItem,
                              receipient_type: 'notify_when_page',
                            })}
                        />
                      </CustomTooltip>
                      for more than
                      <input
                        className={`edit-box-input ${(formErrorData?.includes("notify_page_after")) ? "box-input-error" : ""
                          }`}
                        type="number"
                        value={editLaserLinkItem?.notify_page_after ?? ""}
                        onChange={(e) => {
                          const value = Number(e.target.value);
                          if (value <= 0) {
                            setIsError(true)
                            if (!formErrorData?.includes("notify_page_after")) {
                              setFormErrorData((prevState) => [...prevState, "notify_page_after"]);
                            }
                          } else {
                            setIsError(false)
                            setFormErrorData((prevState) =>
                              prevState.filter((it) => it !== "notify_page_after")
                            );
                          }
                          updateEditLaserLinkData({
                            ...editLaserLinkItem,
                            notify_page_after: e.target.value,
                            notify_after: "",
                          });
                        }}
                        onClick={() =>
                          updateEditLaserLinkData({
                            ...editLaserLinkItem,
                            receipient_type: 'notify_when_page',
                          })}
                      />
                      seconds
                      <CustomTooltip
                        placement="left"
                        title={
                          <p>
                            Time on page is measured while the recipient is actually on the page.
                            <br />
                            The timer is stopped when:
                            <br />- the recipient switches tabs
                            <br />- the recipient switches windows
                            <br />- the window is not active
                            <br />- the window is active, but the screen lock is on
                          </p>
                        }
                        light={true}
                      >
                        <FontAwesomeIcon
                          icon={faQuestion}
                          className="custom-tag-icon mx-2"
                        />
                      </CustomTooltip>
                    </span>
                  }
                />

              </div>
            </div>
          </>
        )}
      </Col>
      <Col md={5}>
        {editLaserLinkItem?.doc_files_languages?.length > 1 ? (
          <div className="d-flex language-button mb-3">
            <CustomDropdown
              options={editLaserLinkItem.doc_files_languages?.map((lang) => {
                return {
                  value: lang?.language?.locale,
                  label: lang?.language?.name,
                  labelTitle: lang?.count_live_files == 0 ? 'This language edition needs to be published before it can be shared' : null,
                  disable: lang?.count_live_files == 0,
                  class: lang?.language?.class,
                };
              })}
              name={"language"}
              onSelect={(e) =>
                handleOnchangeEvent({ target: { name: "language", value: e } })
              }
              selected={
                editLaserLinkItem?.language?.locale != undefined
                  ? editLaserLinkItem?.language.locale
                  : (editLaserLinkItem?.language != undefined ? editLaserLinkItem?.language : editLaserLinkItem.languages)
              }
              placeholder="Select an Language"
            />
          </div>
        ) : editLaserLinkItem?.doc_files_languages?.length == 1 ? (
          <div className="d-flex language-span">
            <span className={`me-3 ${styles.languageLabel}`}>Language:</span>
            <span className="border rounded">
              <div className={`p-2 mx-1 ${editLaserLinkItem?.doc_files_languages[0].language?.class}`}></div>
              {editLaserLinkItem?.doc_files_languages[0].language?.name}
            </span>
          </div>
        ) : (
          ""
        )}

        {editLaserLinkItem?.doc_files_languages?.length == 1 && (
          <div className="mt-3 mb-3 d-flex landing-para">
            <p className={`me-1 ${styles.landingPageLabel}`}>Landing page:</p>
            <span>
              <CustomTooltip
                title={<p className="p-1 text-light">Please enter a page between 1 and {getDocFiles[0]?.num_pages ?? 0
                }</p>}
              >
                <input
                  className={`edit-box-input ${errorListData?.includes('landing_page') ? 'inputError' : ''}`}
                  type="number"
                  value={editLaserLinkItem?.landing_page ?? ""}
                  onChange={(e) => {
                    if (e.target.value == 0 || (e.target.value > (getDocFiles[0]?.num_pages || 0))) {
                      let errorToUpdate = JSON.parse(JSON.stringify(errorListData));
                      errorToUpdate.push("landing_page");
                      updateErrorStatus([...errorToUpdate]);
                    } else {
                      let errorToUpdate = JSON.parse(JSON.stringify(errorListData));
                      errorToUpdate = errorToUpdate.filter(error => error != "landing_page");
                      updateErrorStatus([...errorToUpdate]);
                    }
                    updateEditLaserLinkData({
                      ...editLaserLinkItem,
                      landing_page: e.target.value,
                    })
                  }
                  }
                />
              </CustomTooltip>
              / {getDocFiles[0]?.num_pages ?? 0}
            </span>
          </div>
        )}
        <Form.Check
          className="laser-setting-popup"
          inline
          name="show_toolbar"
          type={"checkbox"}
          id={`show_toolbar`}
          value="show_toolbar"
          checked={!!editLaserLinkItem?.show_toolbar}
          onChange={(element) =>
            updateEditLaserLinkData({
              ...editLaserLinkItem,
              show_toolbar: !!!editLaserLinkItem?.show_toolbar,
            })
          }
          label={"Show toolbar"}
        />
        <Form.Check
          className="laser-setting-popup"
          inline
          name="show_thumbnail"
          type={"checkbox"}
          id={`show_thumbnail`}
          value="show_thumbnail"
          checked={!!editLaserLinkItem?.show_thumbnail}
          onChange={(element) =>
            updateEditLaserLinkData({
              ...editLaserLinkItem,
              show_thumbnail: !!!editLaserLinkItem?.show_thumbnail,
            })
          }
          label={"Show thumbnail navigation"}
        />
        <Form.Check
          className="laser-setting-popup"
          inline
          name="privacy_policy_consent"
          type={"checkbox"}
          id={`privacy_policy_consent`}
          value="privacy_policy_consent"
          checked={!!editLaserLinkItem?.privacy_policy_consent}
          onChange={(element) =>
            updateEditLaserLinkData({
              ...editLaserLinkItem,
              privacy_policy_consent:
                !!!editLaserLinkItem?.privacy_policy_consent,
            })
          }
          label={"Require consent"}
        />
        <Form.Check
          className="laser-setting-popup"
          inline
          name="enable_download"
          type={"checkbox"}
          id={`enable_download`}
          value="enable_download"
          checked={!!editLaserLinkItem?.enable_download}
          onChange={(element) =>
            updateEditLaserLinkData({
              ...editLaserLinkItem,
              enable_download: !!!editLaserLinkItem?.enable_download,
            })
          }
          label={"Enable download"}
        />
        <Form.Check
          className="laser-setting-popup"
          inline
          name="has_optin"
          type={"checkbox"}
          id={`has_optin`}
          value="has_optin"
          checked={!!editLaserLinkItem?.has_optin}
          onChange={(element) =>
            updateEditLaserLinkData({
              ...editLaserLinkItem,
              has_optin: !!!editLaserLinkItem?.has_optin,
            })
          }
          label={"Enable opt-in"}
        />
        <Form.Check
          className="laser-setting-popup"
          inline
          name="disable_scroll"
          type={"checkbox"}
          id={`disable_scroll`}
          value="disable_scroll"
          checked={editLaserLinkItem?.disable_scroll ?? false}
          onChange={(element) => {
            let disableMessage =
              editLaserLinkItem?.disable_scroll == false
                ? {
                  disable_on: "cyoa",
                  disable_message: "This document will be scrollable in",
                }
                : {};
            updateEditLaserLinkData({
              ...editLaserLinkItem,
              disable_scroll:
                editLaserLinkItem?.disable_scroll == true ? false : true,
              disable_settings: disableMessage,
            });
          }}
          label={"Control scrolling"}
        />
        {!!editLaserLinkItem?.disable_scroll && (
          <div className="ms-3">
            <Form.Check
              className="laser-setting-popup"
              inline
              name="disable_scroll"
              type={"radio"}
              id={`notify-kdf-radio-notify_when_page_cyoa`}
              value="notify_when_page_cyoa"
              checked={
                editLaserLinkItem?.disable_settings?.disable_on == "cyoa"
              }
              onChange={(element) => {
                updateEditLaserLinkData({
                  ...editLaserLinkItem,
                  disable_settings: {
                    ...editLaserLinkItem?.disable_settings,
                    disable_on: "cyoa",
                  },
                });
              }}
              label={
                <span className="cursor-pointer">
                  CYOA to proceed
                  <CustomTooltip
                    placement="left"
                    title={
                      <p className="p-1 text-light">This setting disables scrolling completely. Recipients will need to navigate using clickable hotspots and images that you place on each page. If you allow recipients to revisit pages, keep in mind that those clicks will skew your stats.</p>
                    }
                  >
                    <FontAwesomeIcon
                      icon={faQuestion}
                      className="custom-tag-icon mx-2"
                    />
                  </CustomTooltip>
                </span>
              }
            />
            <div className="d-flex align-items-center">
              <Form.Check
                className="laser-setting-popup"
                inline
                name="disable_scroll"
                type={"radio"}
                id={`notify-kdf-radio-after_seconds`}
                value="after_seconds"
                checked={
                  editLaserLinkItem?.disable_settings?.disable_on == "seconds"
                }
                onChange={(element) => {
                  updateEditLaserLinkData({
                    ...editLaserLinkItem,
                    disable_settings: {
                      ...editLaserLinkItem?.disable_settings,
                      disable_on: "seconds",
                    },
                  });
                }}
                label={
                  <span className="cursor-pointer">
                    Delay for
                    <CustomTooltip title={<p className="p-1 text-light">Please enter the time in seconds</p>}>
                      <input
                        className="edit-box-input"
                        type="number"
                        value={
                          editLaserLinkItem?.disable_settings?.disable_for ?? ""
                        }
                        // ref={receipientTypeNotify}
                        name="disable_seconds"
                        onChange={(e) =>
                          updateEditLaserLinkData({
                            ...editLaserLinkItem,
                            disable_settings: {
                              ...editLaserLinkItem.disable_settings,
                              disable_for: e.target.value,
                            },
                          })
                        }
                      />
                    </CustomTooltip>
                    {editLaserLinkItem?.disable_settings?.disable_on ??
                      "seconds"}
                    <CustomTooltip
                      placement="left"
                      title={
                        <p className="p-1 text-light">Scrolling will be disabled for X seconds after the landing page loads so that recipients can not bypass the first page of your content.</p>
                      }
                    >
                      <FontAwesomeIcon
                        icon={faQuestion}
                        className="custom-tag-icon mx-2"
                      />
                    </CustomTooltip>
                  </span>
                }
              />
            </div>

            <p className="cursor-pointer re-notify mb-2">
              Recipient's Notification.
              <CustomTooltip
                placement="left"
                title={
                  <p className="p-1 text-light">The message you enter here will be displayed if the recipient tries to scroll prematurely.</p>
                }
              >
                <FontAwesomeIcon
                  icon={faQuestion}
                  className="custom-tag-icon mx-2"
                />
              </CustomTooltip>
            </p>
            {editLaserLinkItem?.disable_settings?.disable_on == "seconds" && (
              <input
                className="edit-box-input rec-notify-laser  mb-3 w-100"
                type="text"
                value={
                  editLaserLinkItem?.disable_settings?.disable_message ?? ""
                }
                onChange={(e) =>
                  updateEditLaserLinkData({
                    ...editLaserLinkItem,
                    disable_settings: {
                      ...editLaserLinkItem.disable_settings,
                      disable_message: e.target.value,
                    },
                  })
                }
              />
            )}
          </div>
        )}
        <Form.Check
          className="laser-setting-popup"
          inline
          name="enable_transparent_bg"
          type={"checkbox"}
          id={`enable_transparent_bg`}
          value="enable_transparent_bg"
          checked={!!editLaserLinkItem?.enable_transparent_bg}
          onChange={(element) =>
            updateEditLaserLinkData({
              ...editLaserLinkItem,
              enable_transparent_bg:
                !!!editLaserLinkItem?.enable_transparent_bg,
            })
          }
          label={"Embed - No background"}
        />
        <Form.Check
          className="laser-setting-popup"
          inline
          name="enable_shadow_box"
          type={"checkbox"}
          id={`enable_shadow_box`}
          value="enable_shadow_box"
          checked={!!editLaserLinkItem?.enable_shadow_box}
          onChange={(element) =>
            updateEditLaserLinkData({
              ...editLaserLinkItem,
              enable_shadow_box: !!!editLaserLinkItem?.enable_shadow_box,
            })
          }
          label={"Embed - No shadow"}
        />
      </Col>
    </Row>
  );
};
export default LaserLINKSettingsTab;
