import React, { useContext, useEffect, useRef, useState } from "react";
import noImage from "../../../../../assets/images/icon_upload.svg";
import Button from "../../../Elements/Button";
import CustomTooltip from "../../../Elements/Tooltip";
import { Col, Row } from "react-bootstrap";
import styles from "./KdfListCard.module.css";
import LaserLinkModal from "../../../Elements/LaserLink/LaserLinkModal";
import { networkService } from "../../../config/axios";
import UserInfoContext from "../../../../utis/userInfoContext";
import CustomTagsInput from "../../../CommonComponents/Tags/CustomTagsInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion, faRectangleXmark } from "@fortawesome/free-solid-svg-icons";

const KdfListCard = ({
  data,
  triggerUdateRecord,
  triggerUpdateRecordData,
  time_zone_list,
  forKey,
  triggerDeleteKDF,
  triggerCloneKDF,
  triggerMakePrivateEvent,
}) => {
  const [deletePDFActiveState, setDeletePDFActiveState] = useState(null);
  const [privatePDFActiveState, setPrivatePDFActiveState] = useState(null);
  const [tagsEditStatus, setTagsEditStatus] = useState(false);
  const [laserLINKEditStatus, setLaserLINKEditStatus] = useState(null);
  const [laserLINKBatchStatus, setLaserLINKBatchStatus] = useState(false);
  const [editableTitle, setEditableTitle] = useState({ status: false, value: data?.title, id: data?.id });
  const { setShowNotifications } = useContext(UserInfoContext);

  const handlePDFStatusEventTrigger = async () => {
    let recordActionData;
    if (deletePDFActiveState != null) {
      recordActionData = await customAPIRequest(
        `docs/${data.id}/files/${deletePDFActiveState}.json`,
        "DELETE",
        {}
      );
      if (recordActionData?.data != undefined) {
        triggerUdateRecord();
      }
      setDeletePDFActiveState(null);
    } else if (privatePDFActiveState != null) {
      // handleUpdateDocData({"doc_id": docs.id,"private_flag": docUploadData.docData.private_flag});
      triggerUdateRecord();
    }
  };

  const editableRef = useRef(null);
  const handleUpdateEditableTitleEvent = (e) => {
    setEditableTitle((prev) => ({ ...prev, value: e.target.value }));
  };

  const handleOutsideOrEnter = async () => {
    try {
      if (editableRef.current != null) {
        const { data, status } = await networkService(`kdfs/${editableTitle?.id}.json`, 'PATCH', { 'doc': { 'title': editableRef.current.value } });
        if (status == 200) {
          setEditableTitle((prev) => ({ ...prev, status: false }))
          triggerUdateRecord();
          setShowNotifications({
            showToast: true,
            toastType: "success",
            toastMessage: 'Changes Saved',
          })
        }
      }
    } catch (error) {
      if (error.response.status == 422) {
        setEditableTitle((prev) => ({ ...prev, status: false, value: data?.title }))
        setShowNotifications({
          showToast: true,
          toastType: "danger",
          toastMessage: 'Sorry, KDF titles need to be unique. This title already exists in your KDF library.',
        })
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (editableRef.current && !editableRef.current.contains(event.target)) {
        handleOutsideOrEnter();
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        handleOutsideOrEnter();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div key={forKey}>
      <LaserLinkModal
        laserItem={laserLINKEditStatus}
        time_zone_list={time_zone_list}
        docData={data}
        triggerCancelProcess={setLaserLINKEditStatus}
        batchStatus={laserLINKBatchStatus}
      />
      <Row
        className={`${styles.listWrapper} ${!data?.count_live_files > 0 ? styles.unPublished : ""
          } ${data?.private_flag == true && data?.user?.id != data?.current_user?.id ? styles.privateKDF : ''} gx-0`}
      >
        <Col className="text-center h-100" md={2}>
          <img
            src={data?.private_flag == true && data?.user?.id != data?.current_user?.id ? '/assets/user-lock2.png' : (data.thumb != "" ? data.thumb : noImage)}
            alt={data.title}
            className={`${styles.thumbImage} ${data.thumb != "" ? "" : styles.noThumbImage
              } w-70`}
          />
          <Button
            text={"Edit KDF"}
            classList={`${data?.count_active_files !== 0 ? "success" : "disabled"
              } full-width`}
            addonClass={"my-2 px-2 edit-kdf-btn-list"}
            onClick={() => data?.private_flag == true && data?.user?.id != data?.current_user?.id ? '' : window.open(`/editor/${data?.id}`, `editor-${data?.id}`)}
            disabled={data?.count_active_files == 0}
          />
          <div className="kdf-a">
            <a target="_blank" href={`/kdfs/${data?.id}`}>
              KDF Settings
            </a>
          </div>
        </Col>
        <Col md={7} className={styles.docWrapper}>
          <Row>
            <Col md={10}>
              <div className={styles.docTitle}>
                {
                  (data?.private_flag == true && data?.user?.id != data?.current_user?.id) ?
                    <CustomTooltip title={<p className="p-1 text-light">This is Private KDF</p>}>
                      <strong>Confidential... PRIVATE KDF</strong>
                    </CustomTooltip>
                    :
                    (
                      editableTitle.status ?
                        <input
                          type="text"
                          value={editableTitle?.value}
                          ref={editableRef}
                          onChange={(e) => handleUpdateEditableTitleEvent(e)} />
                        :
                        <p onClick={() => setEditableTitle((prev) => ({ ...prev, status: true }))}>{data?.title}</p>
                    )
                }

              </div>
              <p className={styles.docText}>
                <span>Created by: {data.user?.name || " "} </span>
                <span
                  className={`ml-1 ${data.private_permission ? "" : "blur_items"
                    }`}
                >
                  {data.num_pages === 1
                    ? `${data.num_pages} Page `
                    : `${data.num_pages ?? 0} Pages `}
                  {data.count_live_files > 0 && !data.private_flag ? (
                    <CustomTooltip
                      title={<p className="p-1 text-light">{data?.published_status ? "Published" : "Unpublished"
                      } on {data.doc_date_updated} by {data.username}</p>}
                    >
                      <span>
                        {data.count_live_files > 0
                          ? "Published"
                          : "Unpublished"}
                      </span>
                    </CustomTooltip>
                  ) :
                    data.count_live_files > 0 ? (
                      "Published"
                    ) : (
                      "Unpublished"
                    )}
                </span>
              </p>
              <p className={styles.docText}>
                <span>
                  Engagement Threshold: {data.page_viewed_sec} seconds
                </span>
              </p>
              <div
                className="custom-tag-wrapper  d-flex kdf-tag-n"
                onClick={() => data?.private_flag == true && data?.user?.id != data?.current_user?.id ? '' : (!tagsEditStatus && setTagsEditStatus(true))}
              >
                <span id="kdf-tags" className={styles.docText}>
                  Tags: {" "}
                  {!tagsEditStatus ? (
                    data?.tags?.length > 0 ? (
                      <>
                        <span id="kt-tag">{data?.tags?.map((tag, tagInd) => (
                          <span
                            key={tagInd}
                            className="tag-primary text-white mx-1 rounded"
                          >
                            {tag.name}
                          </span>
                        ))}</span>
                        {!data?.tracking_link?.privacy_policy_consent && <span className="ms-2">Consent Required</span>}
                      </>
                    ) : (
                      <>
                        <span
                          className="custom-tag-element mb-0 ms-1"
                          onClick={() => data?.private_flag == true && data?.user?.id != data?.current_user?.id ? '' : (setTagsEditStatus(true))}
                        >
                          {" "}
                          Add KDF Tags here
                        </span>
                        {!data?.tracking_link?.privacy_policy_consent && <span className="ms-2">Consent Required</span>}
                      </>
                    )
                  ) : (
                    // <CustomTags
                    //   updateValue={(value) =>
                    //     {
                    //       triggerUpdateRecordData(value, data, "tags")
                    //     }
                    //   }
                    //   tags={data?.tags}
                    //   handleCancelEvent={(status) => setTagsEditStatus(!status)}
                    // />
                    <>
                      {!data?.tracking_link?.privacy_policy_consent && <span className="ms-2">Consent Required</span>}
                      <CustomTagsInput
                        tags={data?.tags || []}
                        placeholderText="Add a new tag..."
                        handleAddTags={(value) => {
                          triggerUpdateRecordData([...data?.tags || [], value], data, "tags")
                        }}
                        handleRemoveTags={(value) => {
                          triggerUpdateRecordData([...data?.tags?.filter(it => it != value)], data, "tags")
                        }}
                      />
                      <CustomTooltip title={<p className="p-1 text-light">You can add a tag by pressing enter, tab, delimiting with comma or clicking outside the tags area</p>}>
                        <FontAwesomeIcon icon={faQuestion} className="custom-tag-icon mx-2" />
                      </CustomTooltip>
                      <FontAwesomeIcon
                        icon={faRectangleXmark}
                        onClick={() => setTagsEditStatus(false)}
                        className="cancel mx-2 edit-icon"
                      />
                    </>

                  )}
                </span>
              </div>
              {parseInt(data.stats.viewers) > 0 && (
                <div className="d-flex kdf-list-pages">
                  <CustomTooltip title={
                    <div
                      className="p-2"
                      dangerouslySetInnerHTML={{
                        __html: data?.view_stats ?? "",
                      }}
                    />
                  }>
                    <a href={`/kdf-stats/${data?.group_id}#/views`} className={`${styles.statsLink} my-1 text-center custom-link-success `}>
                      <strong className="kdf-score-l">{data.stats.viewers}</strong><span>Visits</span>
                    </a>
                  </CustomTooltip>
                  <a href={`/kdf-stats/${data?.group_id}#/at-a-glance`} className={`${styles.statsLink} my-1 text-center custom-link-success`}>
                    <CustomTooltip title={<p className="p-1 text-light">{data?.stats?.min_viewed_tooltip}</p>}>
                      <strong className="kdf-score-l">{data.stats.min_viewed}</strong>
                    </CustomTooltip>
                    <CustomTooltip title={<p className="p-1 text-light">Avarage time spent viewing this KDF was {data?.stats?.min_viewed_secs}</p>}>
                      <span>Avg Duration</span>
                    </CustomTooltip>
                  </a>
                  <a href={`/kdf-stats/${data?.group_id}#/at-a-glance`} className={`${styles.statsLink} my-1 text-center custom-link-success`}>
                    <strong className="kdf-score-l">{data.stats.percent_viewed}</strong><span>Viewed</span>
                  </a>
                  <CustomTooltip title={<p className="p-1 text-light">{data?.opt_in_reject} Bounced</p>}>
                    <a href={`/kdf-stats/${data?.group_id}#/viewers`} className={`${styles.statsLink} my-1 text-center custom-link-success`}>
                      <strong className="kdf-score-l">{data.stats.unique_viewers}</strong><span>Opt-in</span>
                    </a>
                  </CustomTooltip>
                  <a href={`/kdf-stats/${data?.group_id}#/views`} className={`${styles.statsLink} my-1 text-center custom-link-success`}>
                    <strong className="kdf-score-l">{data.stats.engagement?.replace("%", "")}</strong><span>Score</span>
                  </a>
                  <a href={`/kdf-stats/${data?.group_id}#/at-a-glance`} className={`${styles.statsLink} my-1 text-center custom-link-success`}>
                    <strong className="kdf-score-l">{data.stats.last_viewed}</strong><span>Last Viewed</span>
                  </a>
                </div>
              )}
            </Col>
            <Col md={2}>
              {data.doc_files_languages?.map((lanDoc, lanInd) => (
                <div key={lanInd}>
                  <div className={lanDoc?.language?.class} style={{
                    width: "22px",
                  }}></div>
                </div>
              ))}
            </Col>
          </Row>
        </Col>
        <Col md={3} className="LASER-CREATE">
          <Button
            text={"Create Laser LINK"}
            classList={`${data?.count_live_files == 0 ? "outline" : "success"}`}
            addonClass={"mb-2"}
            onClick={() =>
              data?.count_live_files > 0 ? setLaserLINKEditStatus(true) : setShowNotifications({
                showToast: true,
                toastType: "danger",
                toastMessage: 'This KDF is not yet published, Therefore it can not be shared.',
              })
            }
          />
          {
            data?.tracking_link_count > 0 &&
            <a href={`/links/doc/${data?.group_id}?filter=0`}>LaserLINK ({data?.tracking_link_count})</a>
          }
          <div className="laser-popups mt-1">

            <span
              className="text-link cursor-pointer d-block"
              onClick={() => data?.private_flag == true && data?.user?.id != data?.current_user?.id ? '' : (triggerDeleteKDF(data.id))}
            >
              Delete this KDF
            </span>
            <CustomTooltip title={<p className="p-1 text-light">Clone KDF</p>}>
              <span
                className="text-link cursor-pointer d-block"
                onClick={() => data?.private_flag == true && data?.user?.id != data?.current_user?.id ? '' : (triggerCloneKDF(data.id))}
              >
                Clone this KDF
              </span>
            </CustomTooltip>
            {
              data?.user?.id == data?.current_user?.id &&
              <CustomTooltip
                title={
                  <p className="p-1 text-light">Prevent other Kinetizine users from viewing and editing this KDF</p>
                }
              >
                <span
                  className="text-link cursor-pointer d-block"
                  onClick={() =>
                    data?.private_flag == true && data?.user?.id != data?.current_user?.id ? '' : (triggerMakePrivateEvent({
                      id: data.id,
                      status: data?.private_flag,
                    }))
                  }
                >
                  Make {data?.private_flag == true ? "Public" : "Private"}
                </span>
              </CustomTooltip>
            }
            <CustomTooltip
              title={
                <p className="p-1 text-light">Generate Multiple LaserLINX using a spreadsheet</p>
              }
            >
              <a
                className="link-success"
                onClick={() =>
                  data?.private_flag == true && data?.user?.id != data?.current_user?.id ? '' : (data?.count_live_files > 0 &&
                    (setLaserLINKEditStatus(true),
                      setLaserLINKBatchStatus(true)))
                }
              >
                Gen. MultiLINX
              </a>
            </CustomTooltip>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default KdfListCard;
