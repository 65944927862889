import translations from "../common/Translations";

export const getTranslation = (path) => {
    const keys = path.split('.');
    let value = translations;
    keys.forEach(key => {
        value = value[key];
    });
    return value || '';
};

export function quality_data() {
    return [
        { name: "FASTEST LOADING", value: 'better2' },
        { name: "GOOD IMAGE QUALITY", value: 'good' },
        { name: "BEST IMAGE QUALITY", value: 'best1' },
        { name: "BYPASS IMAGE OPTIMIZATION", value: 'bypass' }
    ]
}

export function animations() {
    return {
        animations: {
            Attention_Seekers: [
                { key: "Bounce", value: "bounce" },
                { key: "Flash", value: "flash" },
                { key: "Pulse", value: "pulse" },
                { key: "Rubber Band", value: "rubberBand" },
                { key: "Shake-X", value: "shakeX" },
                { key: "Shake-Y", value: "shakeY" },
                { key: "Swing", value: "swing" },
                { key: "Tada", value: "tada" },
                { key: "Wobble", value: "wobble" },
                { key: "Jello", value: "jello" }
            ],
            Bouncing_Entrances: [
                { key: "Bounce In", value: "bounceIn" },
                { key: "Bounce In Down", value: "bounceInDown" },
                { key: "Bounce In Left", value: "bounceInLeft" },
                { key: "Bounce In Right", value: "bounceInRight" },
                { key: "Bounce In Up", value: "bounceInUp" }
            ],
            Fading_Entrances: [
                { key: "Fade In", value: "fadeIn" },
                { key: "Fade In Down", value: "fadeInDown" },
                { key: "Fade In Down Big", value: "fadeInDownBig" },
                { key: "Fade In Left", value: "fadeInLeft" },
                { key: "Fade In Left Big", value: "fadeInLeftBig" },
                { key: "Fade In Right", value: "fadeInRight" },
                { key: "Fade In Right Big", value: "fadeInRightBig" },
                { key: "Fade In Up", value: "fadeInUp" },
                { key: "Fade In Up Big", value: "fadeInUpBig" }
            ],
            Flippers: [
                { key: "Flip", value: "flip" },
                { key: "Flip In X", value: "flipInX" },
                { key: "Flip In Y", value: "flipInY" }
            ],
            Lightspeed: [
                { key: "Light Speed In Left", value: "lightSpeedInLeft" },
                { key: "Light Speed In Right", value: "lightSpeedInRight" }

            ],
            Rotating_Entrances: [
                { key: "Rotate In", value: "rotateIn" },
                { key: "Rotate In Down Left", value: "rotateInDownLeft" },
                { key: "Rotate In Down Right", value: "rotateInDownRight" },
                { key: "Rotate In Up Left", value: "rotateInUpLeft" },
                { key: "Rotate In Up Right", value: "rotateInUpRight" }
            ],
            Sliding_Entrances: [
                { key: "Slide In Up", value: "slideInUp" },
                { key: "Slide In Down", value: "slideInDown" },
                { key: "Slide In Left", value: "slideInLeft" },
                { key: "Slide In Right", value: "slideInRight" }
            ],
            Zoom_Entrances: [
                { key: "Zoom In", value: "zoomIn" },
                { key: "Zoom In Down", value: "zoomInDown" },
                { key: "Zoom In Left", value: "zoomInLeft" },
                { key: "Zoom In Right", value: "zoomInRight" },
                { key: "Zoom In Up", value: "zoomInUp" }
            ], Specials: [
                { key: "Hinge", value: "hinge" },
                { key: "Roll In", value: "rollIn" }
            ]
        }
    }
}
export function disappear() {
    return {
        disappear: {
            Bouncing_Exits: [
                { key: "Bounce Out", value: "bounceOut" },
                { key: "Bounce Out Down", value: "bounceOutDown" },
                { key: "Bounce Out Left", value: "bounceOutLeft" },
                { key: "Bounce Out Right", value: "bounceOutRight" },
                { key: "Bounce Out Up", value: "bounceOutUp" }
            ],
            Fading_Exits: [
                { key: "Fade Out", value: "fadeOut" },
                { key: "Fade Out Down", value: "fadeOutDown" },
                { key: "Fade Out Down Big", value: "fadeOutDownBig" },
                { key: "Fade Out Left", value: "fadeOutLeft" },
                { key: "Fade Out Left Big", value: "fadeOutLeftBig" },
                { key: "Fade Out Right", value: "fadeOutRight" },
                { key: "Fade Out Right Big", value: "fadeOutRightBig" },
                { key: "Fade Out Up", value: "fadeOutUp" },
                { key: "Fade Out Up Big", value: "fadeOutUpBig" }
            ],
            Flippers: [
                { key: "Flip Out X", value: "flipOutX" },
                { key: "Flip Out Y", value: "flipOutY" }
            ],
            Lightspeed: [
                { key: "Light Speed Out Left", value: "lightSpeedOutLeft" },
                { key: "Light Speed Out Right", value: "lightSpeedOutRight" }
            ],
            Rotating_Exits: [
                { key: "Rotate Out", value: "rotateOut" },
                { key: "Rotate Out Down Left", value: "rotateOutDownLeft" },
                { key: "Rotate Out Down Right", value: "rotateOutDownRight" },
                { key: "Rotate Out Up Left", value: "rotateOutUpLeft" },
                { key: "Rotate Out Up Right", value: "rotateOutUpRight" }
            ],
            Sliding_Exits: [
                { key: "Slide Out Up", value: "slideOutUp" },
                { key: "Slide Out Down", value: "slideOutDown" },
                { key: "Slide Out Left", value: "slideOutLeft" },
                { key: "Slide Out Right", value: "slideOutRight" }
            ],
            Zoom_Exits: [
                { key: "Zoom Out", value: "zoomOut" },
                { key: "Zoom Out Down", value: "zoomOutDown" },
                { key: "Zoom Out Left", value: "zoomOutLeft" },
                { key: "Zoom Out Right", value: "zoomOutRight" },
                { key: "Zoom Out Up", value: "zoomOutUp" }
            ],
            Specials: [
                { key: "Roll Out", value: "rollOut" }
            ]
        }
    }
}

export function entranceLoop() {
    return {
        entrance_loop: [
            { key: "Loop 5 Seconds", value: "loop5Seconds" },
            { key: "Loop 10 Seconds", value: "loop10Seconds" },
            { key: "Loop Infinitely", value: "loopAnimation" }
        ]
    }
}

export const getObjectById = (targetId) => {
    let lightBoxes = window?.objInit?.lightBoxes
    if (lightBoxes.click) {
        for (let key in lightBoxes.click) {
            if (lightBoxes.click[key].id == targetId) {
                return lightBoxes.click[key];
            }
        }
    }

    if (lightBoxes.doc) {
        for (let key in lightBoxes.doc) {
            if (lightBoxes.doc[key].id == targetId) {
                return lightBoxes.doc[key];
            }
        }
    }

    return null;
}

export const keepProportions = (d1, d2, d3) => {
    if (0 == d3) {
        d3 = d1;
    }
    return Math.floor(d1 * d2 / d3);
};

export const calculateIntersection = (canvasRef, resizableDimensions, elemId) => {
    const canvasWidth = canvasRef?.width || 0;
    const canvasHeight = canvasRef?.height || 0;

    const intersectingLeft = Math.max(resizableDimensions?.left, 0);
    const intersectingTop = Math.max(resizableDimensions?.top, 0);
    const intersectingRight = Math.min(
        resizableDimensions?.left + resizableDimensions?.width,
        canvasWidth
    );
    const intersectingBottom = Math.min(
        resizableDimensions?.top + resizableDimensions?.height,
        canvasHeight
    );

    const isIntersecting =
        intersectingLeft < intersectingRight &&
        intersectingTop < intersectingBottom;

    if (isIntersecting) {
        const intersectingWidth = intersectingRight - intersectingLeft;
        const intersectingHeight = intersectingBottom - intersectingTop;
        const intersectingArea = intersectingWidth * intersectingHeight;

        return {
            isIntersecting: true,
            intersectingWidth,
            intersectingHeight,
            intersectingArea,
            id: elemId,
        };
    }

    return {
        isIntersecting: false,
        intersectingWidth: 0,
        intersectingHeight: 0,
        intersectingArea: 0,
        id: elemId,
    };
}

export function businessTypes() {
    return [
        { name: "Agency B2B", value: '1' },
        { name: "Agency B2C", value: '2' },
        { name: "Real Estate", value: '3' },
        { name: "Consumer Goods", value: '4' },
        { name: "Financial", value: '5' },
        { name: "Health Care", value: '6' },
        { name: "Automotive", value: '7' },
        { name: "Services", value: '8' },
        { name: "Technology", value: '9' },
        { name: "Law firms", value: '10' },
        { name: "Pharma", value: '11' },
        { name: "Alcohol", value: '12' },
        { name: "Direct Mail", value: '13' },
        { name: "Consulting", value: '14' },
    ]
}

export const categories = {
    1: 'Bug Reprt',
    2: 'Enhancement Request',
    3: 'Design / Ease of Use',
    4: 'Efficiency / Workflow Issue',
    5: 'Compatibilty (Software / Hardware )',
    6: 'Connectivity Isse',
    7: 'Domains',
    8: 'Other',
    9: 'Weather Accuracy',
    10: 'User Error',
    11: 'Content Issue',
    12: 'Artwork',
    13: 'Feature Request',
    14: 'User Experience'
}

const SubscriptionPeriod = {
    1: 'Daily',
    2: 'Weekly',
    3: 'Every 2 weeks',
    4: 'Monthly',
    5: 'Quarterly',
    6: 'Every 6 months',
    7: 'Yearly'
}

export const SubscriptionPeriodArr = Object.entries(SubscriptionPeriod).map(([key, name]) => ({
    value: Number(key),
    name,
}));

export const UserTypes = {
    1: 'Admin',
    2: 'Organization Admin',
    3: 'Power User',
    4: 'Team Leader',
    5: 'Regular User',
    6: 'Sales',
}

export const userTypeArr = Object.entries(UserTypes).map(([key, name]) => ({
    value: Number(key),
    name,
}))

export const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
};


export const searchData = (data, searchTerm) => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();

    // Recursive function to check if the value matches the search term
    const checkMatch = (value) => {
        if (typeof value === 'string' && value.toLowerCase().includes(lowercasedSearchTerm)) {
            return true;
        }
        if (Array.isArray(value)) {
            // If it's an array, check each element
            for (let i = 0; i < value.length; i++) {
                if (checkMatch(value[i])) {
                    return true;
                }
            }
        }
        if (typeof value === 'object' && value !== null) {
            // If it's an object, recursively check its properties
            for (const key in value) {
                if (value.hasOwnProperty(key) && checkMatch(value[key])) {
                    return true;
                }
            }
        }
        return false;
    };
    return data.filter(item => checkMatch(item));
};

export const getTopPage = (pages) => {
    return pages.reduce((maxObj, currentObj) => {
        return currentObj.secs_on_page > (maxObj.secs_on_page || 0) ? currentObj : maxObj;
    }, {}).page;
}

export const checkPageVisibility = function () {
    let hidden, visibilityState, visibilityChange;

    if (typeof document.hidden !== "undefined") {
        hidden = "hidden";
        visibilityChange = "visibilitychange";
        visibilityState = "visibilityState";
    } else if (typeof document.mozHidden !== "undefined") {
        hidden = "mozHidden";
        visibilityChange = "mozvisibilitychange";
        visibilityState = "mozVisibilityState";
    } else if (typeof document.msHidden !== "undefined") {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
        visibilityState = "msVisibilityState";
    } else if (typeof document.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
        visibilityState = "webkitVisibilityState";
    }

    return document[visibilityState] === "visible";
};

export const emailRegex = /\S+@\S+\.\S+/;

// laser linx data 
export const defaultLaserLINKData = {
    "name": "",
    "doc_id": '',
    "language": "en-US",
    "landing_page": 1,
    "zoom_lvl": "page-height",
    "show_toolbar": true,
    "show_thumbnail": false,
    "privacy_policy_consent": true,
    "send_engagement_notif": true,
    "notify_after": 22,
    "notify_for_page": "",
    "notify_page_after": "",
    "privacy_setting": "do_not_show",
    "expiry": null,
    "expire_time": null,
    "expire_time_zone": "",
    "has_optin": false,
    "enable_download": false,
    "optin_2nd_page_viewed": true,
    "password": null,
    "tag_ids": [],
    "notification_emails": [],
    "template": false,
    "kdf_action_link": false,
    "disable_scroll": true,
    "disable_settings": {
        "disable_on": "seconds",
        "disable_for": 3,
        "disable_message": "This document will be scrollable in"
    },
    "chat_enabled": true,
    "exit_pop": false,
    "enter_pop": false,
    "flash_pop": false,
    "default_enter_pop": false,
    "default_exit_pop": false,
    "default_flash_pop": false,
    "exit_pop_settings": {},
    "enter_pop_settings": {},
    "flash_pop_settings": {},
    "domain": "",
    "seo_description": "I'm sharing this with you because...",
    "enable_shadow_box": true,
    "enable_transparent_bg": true,
    "campaign_id": null,
    "receipient_type": "notify_when",
    "tags": [],
    "tag_ids": [],

};
export const defaultPopupOptions = {
    "show_delay": '',
    "custom_text": "To continue reading, please provide your:",
    "custom_btn_text": "OK",
    "custom_subsequent_text": "[FIRST NAME], please provide your:",
    "show_lightbox_on_enter": false,
    "lightbox_on_first_launch_only": true,
    "lightbox_on_enter_text": "Feel free to share this doc.",
    "ask_name": true,
    "ask_email": true,
    "show_info_share_with": false,
    "show_info_share_with_second": true,
    "ask_company": false,
    "ask_telephone_number": false,
    "show_on_page": '',
};

export const rgbToHex = (r, g, b) => {
    const toHex = (x) => {
        const hex = x?.toString(16);
        return hex?.length === 1 ? "0" + hex : hex;
    };
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

// Function to check if a string is in RGB format and convert it
// const rgbStringToHex = (rgb) => {
//   const rgbMatch = rgb?.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
//   if (rgbMatch) {
//     const [, r, g, b] = rgbMatch;
//     return rgbToHex(parseInt(r), parseInt(g), parseInt(b));
//   }
//   return rgb;
// };
