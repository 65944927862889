import React, { useState, useEffect } from "react";
import CommonModal from "../../../../../Modals/CommonModal";
import Input from "../../../../../Elements/Input";
import Button from "../../../../../Elements/Button";
import { networkService } from "../../../../../config/axios";
import {
  utilsGetLocalStorage,
  utilsUpdateLocalStorage,
} from "../../../../../../utis/utilsUpdateLocalStorage";
import { ToasterNotification } from "../../../../../CommonComponents/Toasts/ToastNotification";
import {
  getCookie,
  setCookie,
  setReceiverCookie,
  setReceiverSession,
} from "../../../../../../utis/helper";
import { checkPageVisibility } from "../../../../../../hooks/utils";
import useStatHook from "../../../../Stats/StatsHook/useStatHook";

const SettingsEnableOPTIN = ({ tlb, sess_id }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [telephoneNumber, setTelephoneNumber] = useState("");
  const [alertNotification, setAlertNotification] = useState(null);
  const [pageNumber, setPageNumber] = useState(tlb?.landing_page || 1);
  const receiverCookie = getCookie("receiver", {});
  const optinCookie = getCookie("optin", {});
  const [isApiCalled, setIsApiCalled] = useState(() => {
    const saved = utilsGetLocalStorage("receiver_api");
    return saved === "true";
  });

  const [emailError, setEmailError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [telephoneNumberError, setTelephoneNumberError] = useState("");
  const { actionCableOptIn } = useStatHook();

  const showOptInModal = () => {
    optinCookie[tlb?.token] = {
      show: "display",
    };
    setCookie("optin", optinCookie);
    if (!isModalOpen) setModalOpen(true);
  };

  const onScroll = (e) => {
    const value = e.target.value;
    setPageNumber(parseInt(value));
  };

  useEffect(() => {
    document.getElementById("pageNumber").addEventListener("change", onScroll);
  }, []);

  const isReceiverSetUp = () => {
    return (
      Object.keys(receiverCookie).length > 0 &&
      undefined !== receiverCookie[tlb?.token]
    );
  };

  const showOptinCondition = () => {
    return (
      Object.keys(optinCookie).length === 0 || !(tlb?.token in optinCookie)
    );
  };

  useEffect(() => {
    if (isApiCalled) return;
    if (showOptinCondition() && !isReceiverSetUp()) {
      if (
        tlb?.has_optin == true &&
        ((tlb?.optin_2nd_page_viewed &&
          (tlb?.landing_page > 1 ? (pageNumber === tlb?.landing_page + 1 ||
            pageNumber === tlb?.landing_page - 1) : pageNumber != 1)) ||
          (!tlb?.optin_2nd_page_viewed &&
            pageNumber === tlb?.opt_in.show_on_page))
      ) {
        let showOptinIn = tlb.opt_in.show_delay;
        const showOptinInterval = setInterval(function () {
          if (checkPageVisibility()) {
            if (1 == showOptinIn) {
              if (tlb?.has_optin == true) {
                showOptInModal();
                actionCableOptIn();
              }
              clearInterval(showOptinInterval);
            } else {
              showOptinIn--;
            }
          }
        }, 1000);
      }
    }
  }, [pageNumber, tlb?.has_optin, isApiCalled]);

  const handleReceiver = async (url, payload, method) => {
    const { data } = await networkService(
      url,
      method,
      payload
    );

    return data;
  }

  const handleNext = async () => {
    let valid = true;
    setEmailError("");
    setFirstNameError("");
    setLastNameError("");
    setTelephoneNumberError("");

    if (step === 1) {
      if (!email) {
        setEmailError("Email is required");
        valid = false;
      }
    } else if (step === 2) {
      if (tlb?.opt_in?.ask_name) {
        if (!firstName) {
          setFirstNameError("First Name is required");
          valid = false;
        }
        if (!lastName) {
          setLastNameError("Last Name is required");
          valid = false;
        }
      }
      if (tlb?.opt_in?.ask_telephone_number) {
        if (!telephoneNumber) {
          setTelephoneNumberError("Please Provide a valid phone number");
          valid = false;
        }
      }
    }

    if (!valid) {
      return;
    }
    try {
      if (step === 1) {
        let { data, status } = await networkService(
          "check_receiver/demo",
          "POST",
          { company_id: null, email: email }
        );
        if (data?.receiver == null) {
          setStep(2);
        } else {
          setAlertNotification({
            message: `Thanks for confirming ${email}`,
            variant: "success",
          });
          await handleReceiver(`/receivers/${data.receiver.id}.json`, {
            email: email,
            first_name: firstName,
            last_name: lastName,
            session_id: sess_id,
            tracking_link_id: tlb?.opt_in?.tracking_link_id,
            telephone_number: telephoneNumber,
          }, 'PATCH');
          setReceiverCookie(tlb?.token, { id: data?.receiver?.id });
          setReceiverSession(data?.receiver?.id, tlb?.id, window?.objInit?.sess_id);
          setIsApiCalled(true);
          utilsUpdateLocalStorage("receiver_api", true);
          setModalOpen(false);
        }
      } else if (step === 2) {
        const data = await handleReceiver("receivers/data/add.json", {
          email: email,
          first_name: firstName,
          last_name: lastName,
          session_id: sess_id,
          tracking_link_id: tlb?.opt_in?.tracking_link_id,
          telephone_number: telephoneNumber,
        }, 'POST');
        setReceiverCookie(tlb?.token, { id: data.id });
        setReceiverSession(data.id, tlb?.id, window?.objInit?.sess_id);
        setIsApiCalled(true);
        utilsUpdateLocalStorage("receiver_api", true);
        setAlertNotification({
          message:
            firstName != ""
              ? `Thanks ${firstName}, we emailed you a link to this doc for future access. We also unlocked the doc so that you can continue viewing now.`
              : `Thanks for confirming ${email}`,
          variant: "success",
        });
        setModalOpen(false);
        optinCookie[tlb?.token] = {
          show: "none",
        };
        setCookie("optin", optinCookie);
      }
    } catch (error) {
      setAlertNotification({
        message: "Email has already been taken",
        variant: "danger",
      });
    }
  };

  return (
    <>
      {alertNotification != null && (
        <ToasterNotification
          message={alertNotification?.message}
          variant={alertNotification?.variant}
        />
      )}
      <CommonModal
        title="Your permission is required to continue"
        modalStatus={isModalOpen}
        changeActiveStatus={() => { }}
        centered
        classList="hideCross optSettingsModal"
        closeModalOnEsc={false}
        footer={
          <div
            className={`d-flex ${step === 1
              ? tlb?.opt_in?.ask_name || tlb?.opt_in?.ask_telephone_number
                ? "text-end"
                : tlb?.opt_in?.custom_btn_text ?? "text-start"
              : tlb?.opt_in?.custom_btn_text ?? "text-start"
              }`}
          >
            <Button
              text={
                step === 1
                  ? tlb?.opt_in?.ask_name || tlb?.opt_in?.ask_telephone_number
                    ? "Next"
                    : tlb?.opt_in?.custom_btn_text ?? "OK"
                  : tlb?.opt_in?.custom_btn_text ?? "OK"
              }
              classList="success"
              addonClass="my-2 mx-2"
              onClick={handleNext}
            />
          </div>
        }
      >
        <div className="">
          <div>
            <p
              dangerouslySetInnerHTML={{
                __html:
                  tlb?.opt_in?.custom_text ??
                  "To continue reading, please provide your:",
              }}
            />
          </div>
          {step === 1 && (
            <>
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
              {emailError && <div className="error-message">{emailError}</div>}
            </>
          )}

          {(tlb?.opt_in?.ask_name || tlb?.opt_in?.ask_telephone_number) &&
            step === 2 && (
              <>
                {tlb?.opt_in?.ask_name && (
                  <div className="form-group d-flex">
                    <Input
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="First Name"
                      required
                    />
                    {firstNameError && (
                      <div className="error-message">{firstNameError}</div>
                    )}

                    <Input
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="Last Name"
                      required
                      className="ms-2"
                    />
                    {lastNameError && (
                      <div className="error-message">{lastNameError}</div>
                    )}
                  </div>
                )}
                {tlb?.opt_in?.ask_telephone_number && (
                  <>
                    <Input
                      type={"number"}
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      classList={"full-width"}
                      value={telephoneNumber}
                      onChange={(e) => setTelephoneNumber(e.target.value)}
                      placeholder="Tel nr."
                      required
                    />
                    {telephoneNumberError && (
                      <div className="error-message">
                        {telephoneNumberError}
                      </div>
                    )}
                  </>
                )}
              </>
            )}

          <div className="permission-paragraph mt-2 mb-2">
            <p>
              You consent to us processing your personal data. Unsubscribe
              anytime. See our{" "}
              <span className="privacy">
                <a href="/privacy-policy">Privacy Notice</a>
              </span>
            </p>
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default SettingsEnableOPTIN;
