import React from "react"

const TermsKdfContent = () => {
    return (
        <div>
            <div className="modal-body policies" id="policies">
                <p>Box Ventures LLC (hereafter referred to as “We,” “Us,” “Our,” or similar) manages a portfolio of more than 200 domains and subdomains including kinetizine.com and kzine.com. These domains are referred to collectively herein as “This Website”.</p>
                <p><strong>IMPORTANT!<br />
                    THESE TERMS OF SERVICE (TOS) GOVERN YOUR USE OF THIS WEBSITE, WHICH IS PROVIDED BY US. BY ACCESSING THIS WEBSITE, YOU ARE INDICATING YOUR ACKNOWLEDGMENT AND ACCEPTANCE OF THESE TERMS OF USE. THESE TERMS OF USE ARE SUBJECT TO CHANGE BY US AT ANY TIME IN OUR DISCRETION. YOUR USE OF THIS WEBSITE AFTER SUCH CHANGES ARE IMPLEMENTED CONSTITUTES YOUR ACKNOWLEDGMENT AND ACCEPTANCE OF THE CHANGES. PLEASE CONSULT THESE TERMS OF USE FOR ANY CHANGES PRIOR TO EVERY USE. OUR CLIENTS, ESPECIALLY THOSE WHO HAVE PAID FOR SERVICES EITHER IN PART OR IN FULL, ARE FURTHER GOVERNED BY OUR GENERAL TERMS AND CONDITIONS (GTC) FOUND AT  <a title="Box Ventures LLC General Terms and Conditions" href="https://boxonline.com/gtc">HTTPS://BOXONLINE.COM/GTC</a>.</strong></p>
                <p><span><strong>Access To This Website</strong></span></p>
                <p><strong>YOU MUST BE EIGHTEEN (18) YEARS OR OLDER TO ACCESS THIS WEBSITE. IF YOU ARE UNDER EIGHTEEN YEARS OF AGE, YOU ARE NOT PERMITTED TO ACCESS THIS WEBSITE FOR ANY REASON. DUE TO THE AGE RESTRICTIONS FOR USE OF THIS WEBSITE, NO INFORMATION OBTAINED BY THIS WEBSITE, FALLS WITHIN THE CHILD ONLINE PRIVACY ACT (COPA) AND IS NOT MONITORED AS DOING SO.</strong></p>
                <p>To access This Website or some of the resources it has to offer, you may be asked to provide certain registration details or other information. It is a condition of your use of This Website that all the information you provide on This Website will be correct, current, and complete. If we believe the information you provide is not correct, current, or complete, we have the right to refuse you access to This Website or any of its resources, and to terminate or suspend your access at any time, without notice. We will not share your information with any third party. See our Privacy Notice to learn how we use and process your information.</p>
                <p><span  ><strong>Restrictions On Use</strong></span><br />
                    You may use This Website for purposes expressly permitted by This Website. You may not use This Website for any other purpose, including any commercial purpose, without our express prior written consent. For example, you may not (and may not authorize any other party to) (i) co-brand This Website, or (ii) frame This Website, or (iii) hyper-link to This Website, without the express prior written permission of our authorized representative. For purposes of these Terms of Use, “co-branding” means to display a name, logo, trademark, or other means of attribution or identification of any party in such a manner as is reasonably likely to give a user the impression that such other party has the right to display, publish, or distribute This Website or content accessible within This Website. You agree to cooperate with us and to immediately cease any unauthorized co-branding, framing or hyper-linking activities.</p>
                <p><span  ><strong>Proprietary Information</strong></span><br />
                    The material and content (hereinafter referred to as the &#8220;Content&#8221;) accessible from This Website, and any other World Wide Web site owned, operated, licensed, or controlled by us, is our proprietary information or the party that provided the Content to us. We or the party that provided the Content to us retains all right, title, and interest in the Content. Accordingly, the Content may not be copied, distributed, republished, uploaded, posted, or transmitted in any way without our prior written consent, or unless authorized in writing elsewhere on our site, except that you may print out a copy of the Content solely for your personal use. In doing so, you may not remove or alter, or cause to be removed or altered, any copyright, trademark, trade name, service mark, or any other proprietary notice or legend appearing on any of the Content. Modification or use of the Content except as expressly provided in these Terms of Use violates our intellectual property rights. Neither title nor intellectual property rights are transferred to you by access to This Website.</p>
                <p><span  ><strong>Hyper-Links</strong></span><br />
                    This Website may contain hyper-links to other sites which are not maintained by, or related to us. Hyper-links to such sites are provided as a service to users and are not sponsored by or affiliated with This Website or us in any way. We have not reviewed such sites and are not responsible for the content of those sites. Hyper-links are to be accessed at your own risk, and we make no representations or warranties about the content, completeness or accuracy of these hyper-links nor the sites hyper-linked to This Website. Further, the inclusion of any hyper-link to a third-party site does not necessarily imply our endorsement of that site.</p>
                <p><span  ><strong>Submissions</strong></span><br />
                    You hereby grant us the royalty-free, perpetual, irrevocable, worldwide, non-exclusive right and license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform, and display all content, remarks, suggestions, ideas, graphics, or other information communicated to us through This Website (together, hereinafter known as the &#8220;Submission&#8221;), and to incorporate any Submission in other works in any form, media, or technology now known or later developed. We will not be required to treat any Submission as confidential, and may use any Submission in its business (including without limitation, for products or advertising) without incurring any liability for royalties or any other consideration of any kind, and will not incur any liability as a result of any similarities that may appear in any of our current or future operations. We treat any personal information that you submit through This Website in accordance with our Privacy Notice as set forth on This Website.</p>
                <p><span  ><strong>Disclaimer</strong></span><br />
                    You understand that we cannot and do not guarantee or warrant that files available for downloading from the Internet will be free of viruses, worms, trojan horses or other code that may manifest contaminating or destructive properties. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for accuracy of data input and output, and for maintaining a means external to This Website for the reconstruction of any lost data. We do not assume any responsibility or risk for your use of the Internet.<br />
                    The Content is not necessarily complete and up-to-date and should not be used to replace any written reports, statements, or notices provided by us. Investors, borrowers, and other persons should use the Content in the same manner as any other educational or marketing medium and should not rely on the Content to the exclusion of their own professional judgment. Information obtained by using This Website is not exhaustive and does not cover all issues, topics, or facts that may be relevant to your goals.</p>
                <p><strong>YOUR USE OF THIS WEBSITE IS AT YOUR OWN RISK. THE CONTENT IS PROVIDED &#8220;AS IS&#8221; AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESSED OR IMPLIED. WE DISCLAIM ALL WARRANTIES, INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR NON-INFRINGEMENT. WE DO NOT WARRANT THAT THE FUNCTIONS OR CONTENT CONTAINED IN THIS WEBSITE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THIS WEBSITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. WE DO NOT WARRANT OR MAKE ANY REPRESENTATION REGARDING USE, OR THE RESULT OF USE, OF THE CONTENT IN TERMS OF ACCURACY, RELIABILITY, OR OTHERWISE. THE CONTENT MAY INCLUDE TECHNICAL INACCURACIES OR TYPOGRAPHICAL ERRORS, AND WE MAY MAKE CHANGES OR IMPROVEMENTS AT ANY TIME. YOU, AND NOT US, ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION IN THE EVENT OF ANY LOSS OR DAMAGE ARISING FROM THE USE OF THIS WEBSITE OR ITS CONTENT. WE MAKE NO WARRANTIES THAT YOUR USE OF THE CONTENT WILL NOT INFRINGE THE RIGHTS OF OTHERS AND ASSUME NO LIABILITY OR RESPONSIBILITY FOR ERRORS OR OMISSIONS IN SUCH CONTENT.</strong></p>
                <p>All of the information in This Website, whether historical in nature or forward-looking, speaks only as of the date the information is posted on This Website, and we do not undertake any obligation to update such information after it is posted or to remove such information from This Website if it is not, or is no longer, accurate or complete.</p>
                <p><span  ><strong>Limitation On Liability</strong></span></p>
                <p><strong>WE AND OUR SUBSIDIARIES, AFFILIATES, LICENSORS, SERVICE PROVIDERS, CONTENT PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, AND DIRECTORS WILL NOT BE LIABLE FOR ANY INCIDENTAL, DIRECT, INDIRECT, PUNITIVE, ACTUAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR OTHER DAMAGES, INCLUDING LOSS OF REVENUE OR INCOME, PAIN AND SUFFERING, EMOTIONAL DISTRESS, OR SIMILAR DAMAGES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL OUR COLLECTIVE LIABILITY OR THAT OF OUR SUBSIDIARIES, AFFILIATES, LICENSORS, SERVICE PROVIDERS, CONTENT PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, AND DIRECTORS, TO ANY PARTY (REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT, OR OTHERWISE) EXCEED THE GREATER OF $100 OR THE AMOUNT YOU HAVE PAID TO US FOR THE APPLICABLE CONTENT, PRODUCT OR SERVICE OUT OF WHICH LIABILITY AROSE.</strong></p>
                <p><span  ><strong>Indemnity</strong></span><br />
                    You will indemnify and hold us, our subsidiaries, affiliates, licensors, content providers, service providers, employees, agents, officers, directors, and contractors (hereinafter known as the &#8220;Indemnified Parties&#8221;) harmless from any breach of these Terms of Use by you, including any use of Content other than as expressly authorized in these Terms of Use. You agree that the Indemnified Parties will have no liability in connection with any such breach or unauthorized use, and you agree to indemnify any and all resulting loss, damages, judgments, awards, costs, expenses, and attorney&#8217;s fees of the Indemnified Parties in connection therewith. You will also indemnify and hold the Indemnified Parties harmless from and against any claims brought by third parties arising out of your use of the information accessed from This Website.</p>
                <p><span  ><strong>Trademarks</strong></span><br />
                    Trademarks, service marks, and logos appearing in This Websiteare our property or the party that provided the trademarks, service marks, and logos to us. Any party that provided trademarks, service marks, and logos to us retain all rights with respect to any of their respective trademarks, service marks, and logos appearing in This Website.<br />
                    Information You Provide<br />
                    You may not post, send, submit, publish, or transmit in connection with This Website any material that:</p>
                <ul>
                    <li>you do not have the right to post, including proprietary material of any third party;</li>
                    <li>advocates illegal activity or discusses an intent to commit an illegal act;</li>
                    <li>is vulgar, obscene, pornographic, or indecent;</li>
                    <li>does not pertain directly to this site;</li>
                    <li>threatens or abuses others, libels, defames, invades privacy, stalks, is obscene, pornographic, racist, abusive, harassing, threatening or offensive;</li>
                    <li>seeks to exploit or harm children by exposing them to inappropriate content, asking for personally identifiable details or otherwise;</li>
                    <li>infringes any intellectual property or other right of any entity or person, including violating anyone&#8217;s copyrights or trademarks or their rights of publicity;</li>
                    <li>violates any law or may be considered to violate any law;</li>
                    <li>impersonates or misrepresents your connection to any other entity or person or otherwise manipulates headers or identifiers to disguise the origin of the content;</li>
                    <li>advertises any commercial endeavor (e.g., offering for sale products or services) or otherwise engages in any commercial activity (e.g., conducting raffles or contests, displaying sponsorship banners, and/or soliciting goods or services) except as may be specifically authorized on This Website;</li>
                    <li>solicits funds, advertisers or sponsors;</li>
                    <li>includes programs which contain viruses, worms and/or trojan horses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications;</li>
                    <li>disrupts the normal flow of dialogue, causes a screen to scroll faster than other users are able to type, or otherwise act in a way which affects the ability of other people to engage in real time activities via This Website;</li>
                    <li>includes MP3 format files;</li>
                    <li>amounts to a &#8216;pyramid&#8217;, ponzi, multi-level marketing or similar scheme;</li>
                    <li>disobeys any policy or regulations established from time to time regarding use of This Website or any networks connected to This Website; or</li>
                    <li>contains hyper-links to other sites that contain content that falls within the descriptions set forth above.</li>
                </ul>
                <p>Although under no obligation to do so, we reserve the right to monitor use of This Website to determine compliance with these Terms of Use, as well the right to remove or refuse any information for any reason. Notwithstanding these rights, you remain solely responsible for the content of your submissions. You acknowledge and agree that neither we, nor any third party that provides Content to us will assume or have any liability for any action or inaction by us or such third party with respect to any submission.</p>
                <p><span  ><strong>Security</strong></span><br />
                    Any passwords used for This Website are for individual use only. You will be responsible for the security of your password (if any). We are entitled to monitor your password and, at our discretion, require you to change it. If you use a password that we consider not secure, we are entitled to require the password to be changed and/or terminate your account.<br />
                    You are prohibited from using any services or facilities provided in connection with This Website to compromise security or tamper with system resources and/or accounts. The use or distribution of tools designed for compromising security (e.g., password guessing programs, cracking tools or network probing tools) is strictly prohibited. If you become involved in any violation of system security, we reserve the right to release your details to system administrators at other sites in order to assist them in resolving security incidents. We reserve the right to investigate suspected violations of these Terms of Use.<br />
                    We reserve the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity of anyone posting any e-mail messages, or publishing or otherwise making available any materials that are believed to violate these Terms of Use.</p>
                <p><strong>BY ACCEPTING THIS AGREEMENT YOU WAIVE AND HOLD US HARMLESS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY US DURING, OR AS A RESULT OF OUR INVESTIGATIONS, AND/OR FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER US OR LAW ENFORCEMENT AUTHORITIES.</strong></p>
                <p><span  ><strong>Governing Law</strong></span><br />
                    This Agreement will be governed by and construed in accordance with Swiss law notwithstanding any principles of conflicts of law. You specifically consent to personal jurisdiction in Zug, Switzerland in connection with any dispute between you and us arising out of these Terms of Use or pertaining to the subject matter hereof. The parties to these Terms of Use each agree that the exclusive venue for any dispute between the parties arising out of these Terms of Use or pertaining to the subject matter of these Terms of Use will be in the cantonal and federal courts of Zug, Switzerland.</p>
                <p><span  ><strong>Miscellaneous</strong></span><br />
                    If any part of these Terms of Use is unlawful, void or unenforceable, that part will be deemed severable and will not affect the validity and enforceability of any remaining provisions. These Terms of Use constitute the entire agreement among the parties relating to this subject matter. Notwithstanding the foregoing, any additional terms and conditions on This Website will govern the items to which they pertain. We may revise these Terms of Use at any time by updating this document.</p>
            </div>
        </div>
    )
}
export default TermsKdfContent