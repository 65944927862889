import React, { useEffect } from "react"

function TextEditorSection({ handleTextChange, html_text, errorState }) {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "/tinymce/tinymce.min.js";
        script.onload = () => {
            window.tinymce.init({
                selector: "#tiny-editor",
                plugins: [
                    "anchor", "autolink", "charmap", "codesample", "emoticons", "image", "link", "lists", "media", "searchreplace", "table", "visualblocks", "wordcount"
                ],
                toolbar: "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | removeformat",
                setup: function (editor) {
                    editor.on("change", function () {
                        handleTextChange(editor.getContent());
                    });
                },
                placeholder: "Start writing...",
            });
        };
        document.body.appendChild(script);
    }, [handleTextChange]);
    return (
        <>
            <div>
                <textarea
                    id="tiny-editor"
                    value={html_text}
                    style={{ width: "100%", height: "300px" }}
                />
                {errorState?.textErr && <p className="text-danger">{errorState?.textErr}</p>}
            </div>
            {errorState?.textErr && <p className="text-danger">{errorState?.textErr}</p>}
        </>
    );
}

export default TextEditorSection;
