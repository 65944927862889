import React from 'react';
import PieChart from './PieChart';
import Pie3DChart from './Pie3DChart';
import DonutChart from './DonutChart';
import Donut3DChart from './Donut3DChart';
import BarChart from './BarCharts';
import ColumnChart from './ColumnChart';
import Column3DChart from './Column3DChart';


const ShowCharts = ({ selectedChart, data, name }) => {
  let chartDisplay;

  switch (selectedChart) {
    case 'Pie':
      chartDisplay = <PieChart data={data} name={name} />;
      break;
    case '3D Pie':
      chartDisplay = <Pie3DChart data={data} name={name} />;
      break;
    case 'Donut':
      chartDisplay = <DonutChart data={data} name={name} />;
      break;
    case '3D Donut':
      chartDisplay = <Donut3DChart data={data} name={name} />;
      break;
    case 'Bar':
      chartDisplay = <BarChart data={data} name={name} />;
      break;
    case 'Column':
      chartDisplay = <ColumnChart data={data} />;
      break;
    case '3D Column':
      chartDisplay = <Column3DChart data={data} />;
      break;
    default:
      chartDisplay = <PieChart data={data} name={name} />;
  }

  return (
    <div>
      {chartDisplay}
    </div>
  );
};

export default ShowCharts;
