import React, { useState } from "react";
import "./ElementOrder.css"

const ElementOrder = ({ arrayOfElements }) => {
    const [showElementOrder, setShowElementOrder] = useState(false)

    return (
        <>
            <div className={`${showElementOrder ? "elem-move-left" : 'elem-move-right'} element-order-main`}>
                <div className="element-order-sidebar" onClick={() => setShowElementOrder(!showElementOrder)}>
                    <p>
                        Element Order
                    </p>
                </div>
                <div className="inner-element-order">
                    {arrayOfElements && arrayOfElements?.map((elem, idx) => (
                        <div className="element-img-order" key={idx}>
                            {/* {console.log(elem, elem?.element_type_name)} */}
                            <h1>nitin</h1>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
export default ElementOrder;