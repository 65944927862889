import React, { useState } from "react";
import Breadcrumb from "../../../CommonComponents/BreadCrumb/BreadCrumb";
import UsersListing from "./UsersListing";
import DataPerPage from "../../../CommonComponents/Pagination/DataPerPage";
import SearchComponent from "../../../CommonComponents/SearchComponent/SearchComponent";

const Users = () => {
    const [dataPerPage, setDataPerPage] = useState(10)
    const [selectAction, setSelectAction] = useState('action')

    return (
        <>
            <Breadcrumb string={'Admin/Users'} />
            <div className="container">
                <div className="show-select1 d-flex mb-2">
                    <div className="mb-2">
                        <DataPerPage dataPerPage={dataPerPage} setDataPerPage={setDataPerPage} />
                        {/* <SearchComponent
                            data={Users}
                            setCurrentPageData={setCurrentPageData}
                        /> */}
                    </div>
                    <div className="custom-select-container me-2 ms-4 ali">
                        <select
                            id="custom-select"
                            className="select-action resend-select"
                            onChange={(e) => setSelectAction(e.target.value)}
                            defaultChecked={selectAction}>
                            <option value="action">Select Action</option>
                            <option value="delete">Delete Selected</option>
                            <option value="sendMail">Resend Signup Verification</option>
                        </select>
                    </div>
                </div>
                <div className="top-parent">
                    <UsersListing dataPerPage={dataPerPage} selectAction={selectAction} setSelectAction={setSelectAction} />
                </div>

            </div>
        </>
    )
}
export default Users;
