import React, { useState, useEffect } from "react";
import Breadcrumb from "../../../CommonComponents/BreadCrumb/BreadCrumb";
import Button from "../../../Elements/Button";
import CustomTable from "../../../CommonComponents/Table/CustomTable";
import { UserTypes } from "../../../../hooks/utils";
import DataPerPage from "../../../CommonComponents/Pagination/DataPerPage";
import SearchComponent from "../../../CommonComponents/SearchComponent/SearchComponent";
import { networkService } from "../../../config/axios";
import AdminPagination from "../../../CommonComponents/Pagination/AdminPagination";

const AdminDomainManagePage = () => {
    const [domainUsers, setDomainUsers] = useState([])
    const [currentPageData, setCurrentPageData] = useState([]);
    const [dataPerPage, setDataPerPage] = useState(10)
    const totalData = domainUsers?.length;

    const handlePageChange = (pageNumber) => {
        const indexOfLastData = pageNumber * dataPerPage;
        const indexOfFirstData = indexOfLastData - dataPerPage;
        setCurrentPageData(domainUsers?.slice(indexOfFirstData, indexOfLastData));
    };

    useEffect(() => {
        const fetchDomains = async () => {
            const response = await networkService("admin/get_domain_users.json", "GET");
            setDomainUsers([...response?.data?.users]);
        };
        fetchDomains();
    }, []);

    useEffect(() => {
        handlePageChange(1);
    }, [domainUsers])

    const columns = React.useMemo(() => {
        return [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'User Type',
                accessor: 'user_type',
                Cell: ({ row }) => (<>
                    {UserTypes[row?.original?.user_type]}
                </>)
            },
            {
                Header: 'Last Login',
                accessor: 'current_sign_in_at',
            },
            {
                Header: 'Company Name',
                accessor: 'company.name',
            },
            {
                Header: 'Default Domain',
                accessor: 'modifier.name',
            },
            {
                Header: 'Domains',
                accessor: 'action',
                Cell: ({ row: { original } }) => (
                    <Button
                        classList={'success'}
                        text={'Domains'}
                        onClick={() => window.location.href = `/admin/get_domains/${original?.id}`}
                    />
                ),

            },

        ];
    }, [currentPageData]);

    return (
        <>
            <Breadcrumb string={'Admin/Domain Manager'} />
            <div className="container kinetizine">
                <div className="show-select1 d-flex mb-2">
                    <div className="mb-2">
                        <DataPerPage dataPerPage={dataPerPage} setDataPerPage={setDataPerPage} />
                        <SearchComponent
                            data={domainUsers}
                            setCurrentPageData={setCurrentPageData}
                        />
                    </div>
                </div>
                <div className="container">
                    <CustomTable data={currentPageData} columnData={columns} />
                    <AdminPagination
                        dataPerPage={dataPerPage}
                        totalData={totalData}
                        onPageChange={handlePageChange}
                    />
                </div>
            </div>
        </>
    )
}
export default AdminDomainManagePage;