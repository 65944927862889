import React, { useState } from "react";
import './Paginate.module.css';

const AdminPagination = ({ dataPerPage, totalData, onPageChange, dataCount }) => {
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(totalData / dataPerPage);

    const handlePageChange = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
            onPageChange(pageNumber); // Notify parent about page change
        }
    };

    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;

    const rangeStart = indexOfFirstData + 1; // Add 1 to make it 1-based index
    const rangeEnd = indexOfLastData < totalData ? indexOfLastData : totalData; 

    return (
        <div className="showing-main d-flex justify-content-between mt-2 mb-5 align-items-center">
            <div className="showing-para">
                <p>Showing {rangeStart} to {rangeEnd} of {totalData} entries</p>
            </div>
            <div className="pagination-buttons">
                <button
                    className="previous-btn me-2 previous-pagenisation"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    Previous
                </button>

                {[...Array(totalPages).keys()].map((index) => {
                    const page = index + 1;
                    return (
                        <button
                            key={page}
                            onClick={() => handlePageChange(page)}
                            style={{ fontWeight: currentPage === page ? 'bold' : 'normal' }}
                        >
                            {page}
                        </button>
                    );
                })}

                <button
                    className="previous-btn previous-pagenisation ms-2"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>
        </div>
    );
}

export default AdminPagination;
