import React, { useEffect, useRef, useState } from "react";
import useStatHook from "../Pages/Stats/StatsHook/useStatHook";

const YouTubeIframe = ({ src, style, title, id, resizeDimensions, dimensions, ...props }) => {
    const { setVideoData } = useStatHook() || {};
    const videoId = src?.split('/')[4]?.split('?')[0] || '';
    const iframeRef = useRef(null);
    const intervalRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    // const sendVideoStatsData = (currentTime, duration) => {
    //     let videoStatsData = {
    //         id: id,
    //         watchedPercent: ((Number(currentTime) / Number(duration)) * 100).toFixed(2),
    //         watchedSeconds: currentTime,
    //         duration: duration
    //     }
    //     videoStats(videoStatsData)
    // }

    useEffect(() => {
        if (!videoId) {
            console.error("Invalid videoId.");
            return;
        }

        let intervalId;

        // Function to handle the YouTube Iframe API loading
        const onYouTubeIframeAPIReady = () => {
            if (window.YT && window.YT.Player) {
                const player = new window.YT.Player(iframeRef.current, {
                    videoId,
                    events: {
                        onStateChange: (event) => handlePlayerStateChange(event, player),
                        onError: handlePlayerError,
                    },
                });
            }
        };

        const handlePlayerStateChange = (event, player) => {
            switch (event.data) {
                case window.YT.PlayerState.PLAYING:
                    startTracking(player)
                    setIsPlaying(true);
                    break;
                case window.YT.PlayerState.PAUSED:
                    setIsPlaying(false);
                    stopTracking()
                    break;
                case window.YT.PlayerState.ENDED:
                    setIsPlaying(false);
                    break;
                default:
                    break;
            }
        };

        const handlePlayerError = (event) => {
            console.error("YouTube Player Error: ", event.data);
            switch (event.data) {
                case 2:
                    console.error("Invalid parameter value in videoId or API request.");
                    break;
                case 5:
                    console.error("HTML5 player error. Issue with video playback.");
                    break;
                case 100:
                    console.error("Video not found. The videoId may be incorrect or removed.");
                    break;
                case 101:
                case 150:
                    console.error("Embedding not allowed for this video.");
                    break;
                default:
                    console.error("Unknown error.");
                    break;
            }
        };

        const startTracking = (player) => {
            if (!intervalRef.current) {
                if (player && typeof player.getCurrentTime === "function") {
                    const currentTime = player?.getCurrentTime()
                    const duration = player?.getDuration();
                    setVideoData({
                        id: id,
                        watchedPercent: ((Number(currentTime) / Number(duration)) * 100).toFixed(2),
                        watchedSeconds: Number(currentTime),
                        duration: duration,
                        isPlaying: true
                    })
                    intervalRef.current = true
                    // intervalRef.current = setInterval(() => {
                    //         sendVideoStatsData(time, duration);
                    //     }
                    // }, 1000);
                } else {
                    intervalRef.current = true
                    setVideoData(prev => ({
                        ...prev,
                        isPlaying: true
                    }))
                }
            }
        };

        const stopTracking = () => {
            if (intervalRef.current) {
                setVideoData(prev => ({
                    ...prev,
                    isPlaying: false
                }))
                // clearInterval(intervalRef.current);
                intervalRef.current = false;
            }
        }

        if (!window.YT) {
            const tag = document.createElement("script");
            tag.src = "https://www.youtube.com/iframe_api";
            const firstScriptTag = document.getElementsByTagName("script")[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

            window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
        } else {
            onYouTubeIframeAPIReady();
        }

        return () => {
            window.onYouTubeIframeAPIReady = null;
            if (iframeRef.current && iframeRef.current.player) {
                iframeRef.current.player.destroy();
            }
            clearInterval(intervalId);
        };
    }, [videoId, isPlaying]);

    return (
        <iframe
            ref={iframeRef}
            style={style}
            src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1&origin=${window.location.origin}`}
            title={title}
            id={id}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            {...props}
        ></iframe>
    );
};

export default React.memo(YouTubeIframe);
