import React from "react"
import { Form } from "react-bootstrap"

const SelectChart = ({ selectedChart, handleSelectChange, name }) => {
    const chartOptions = ['Pie', '3D Pie', 'Donut', '3D Donut', 'Bar', 'Column', '3D Column']

    return (
        <div className="w-100">
            <Form.Select
                name="type"
                value={selectedChart[name]}
                onChange={(e) => handleSelectChange(e, name)}
            >
                {chartOptions?.map((option, idx) => (
                    <option key={idx} value={option}>{option}</option>
                ))}
            </Form.Select>
        </div>
    )
}
export default SelectChart