// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".links-module__inputIconWrapper___3HjC4 input {\n    margin-bottom: 0;\n}\n.links-module__languageFlag___1ebCl {\n    width: 100%;\n    height: 34px;\n    padding: 6px 12px;\n    font-size: 14px;\n    color: #555555;\n    border: 1px solid #ccc;\n}\n.links-module__languageFlag___1ebCl img {\n    display: inline-block;\n    width: 22px;\n    margin-right: 5px;\n}\n.links-module__languageLabel___2zrz8 {\n    width: 100% !important;\n    max-width: 100px !important;\n}\n.links-module__landingPageLabel___15RUb {\n    width: 100% !important;\n    max-width: 110px !important;\n}", "",{"version":3,"sources":["links.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,cAAc;IACd,sBAAsB;AAC1B;AACA;IACI,qBAAqB;IACrB,WAAW;IACX,iBAAiB;AACrB;AACA;IACI,sBAAsB;IACtB,2BAA2B;AAC/B;AACA;IACI,sBAAsB;IACtB,2BAA2B;AAC/B","file":"links.module.css","sourcesContent":[".inputIconWrapper input {\n    margin-bottom: 0;\n}\n.languageFlag {\n    width: 100%;\n    height: 34px;\n    padding: 6px 12px;\n    font-size: 14px;\n    color: #555555;\n    border: 1px solid #ccc;\n}\n.languageFlag img {\n    display: inline-block;\n    width: 22px;\n    margin-right: 5px;\n}\n.languageLabel {\n    width: 100% !important;\n    max-width: 100px !important;\n}\n.landingPageLabel {\n    width: 100% !important;\n    max-width: 110px !important;\n}"]}]);
// Exports
exports.locals = {
	"inputIconWrapper": "links-module__inputIconWrapper___3HjC4",
	"languageFlag": "links-module__languageFlag___1ebCl",
	"languageLabel": "links-module__languageLabel___2zrz8",
	"landingPageLabel": "links-module__landingPageLabel___15RUb"
};
module.exports = exports;
