import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import './CommonModal.css';
const CommonModal = ({ title, children, footer, modalStatus, classList, changeActiveStatus = () => { }, lightBox = false, closeModalOnEsc = true, showFooter = true, ...rest }) => {
    // const modalRef = useRef(null);
    const [show, setShow] = useState(false);
    useEffect(() => {
        setShow(modalStatus);
        return () => {
            setShow(false);
        }
    }, [modalStatus]);

    useEffect(() => {

        return () => {
            setShow(false);
        }
    }, []);

    // close modal on esc
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (closeModalOnEsc && event.key === 'Escape' && modalStatus) {
                setShow(false)
                changeActiveStatus(false)
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [modalStatus]);

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            backdrop="static"
            keyboard={false}
            className={`${classList != undefined ? classList : ''} ${lightBox ? 'lightboxModal' : ''}`}
            {...rest}
        >
            {!lightBox &&
                <Modal.Header closeButton className="cursor-grab">
                    <Modal.Title>
                        {title ?? ''}
                    </Modal.Title>
                    <div className='d-flex'>
                        <FontAwesomeIcon onClick={() => changeActiveStatus(false)} icon={faXmark} className='au-modal-close-btn' />
                    </div>
                </Modal.Header>
            }
            <Modal.Body>
                {children}
            </Modal.Body>
            {
                showFooter && <>
                    {!lightBox ?
                        <Modal.Footer>
                            {footer}
                        </Modal.Footer> :
                        <Modal.Footer>
                            <FontAwesomeIcon
                                onClick={() => changeActiveStatus(false)}
                                icon={faXmark}
                                className='au-modal-close-btn'
                            />
                        </Modal.Footer>
                    }
                </>
            }
        </Modal>
    )
}
export default React.memo(CommonModal);