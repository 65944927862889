import React from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts3d from 'highcharts/highcharts-3d';

const Pie3DChart = ({ data, name }) => {

  const graphData = data?.map(item => ({
    name: item?.[name] ?? name,
    y: item?.hits
  }));

  const options = {
    chart: {
      type: 'pie',
      options3d: {
        enabled: true,
        alpha: 45,
        beta: 0,
        depth: 50,
      },
    },
    title: {
      text: '',
    },
    tooltip: {
      pointFormat: '{point.y} Visits ({point.percentage:.1f}%)',
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        depth: 35,
        dataLabels: {
          enabled: true,
          format: '{point.name}',
        },
      },
    },
    series: [
      {
        name: name,
        data: graphData,
      },
    ],
  };

  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </>
  )
}
export default Pie3DChart