import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../CommonComponents/BreadCrumb/BreadCrumb";
import Button from "../../../Elements/Button";
import CustomTable from "../../../CommonComponents/Table/CustomTable";
import { networkService } from "../../../config/axios";
import AdminPagination from "../../../CommonComponents/Pagination/AdminPagination";
const AdminCustomisationLogPage = (props) => {
    const [customizationLogs, setCustomizationlLogs] = useState([])
    const [currentPageData, setCurrentPageData] = useState([]);
    const dataPerPage = 10;
    const totalData = customizationLogs?.length;

    const handlePageChange = (pageNumber) => {
        const indexOfLastData = pageNumber * dataPerPage;
        const indexOfFirstData = indexOfLastData - dataPerPage;
        setCurrentPageData(customizationLogs?.slice(indexOfFirstData, indexOfLastData));
    };

    useEffect(() => {
        const fetchLogs = async () => {
            const response = await networkService("admin/customization_log.json", "GET");
            setCustomizationlLogs([...response.data?.customizations_log]);
        };
        fetchLogs();
    }, []);

    useEffect(() => {
        handlePageChange(1);
    }, [customizationLogs])

    const columns = React.useMemo(() => {
        return [
            {
                Header: 'User Name',
                accessor: 'user_name',
            },
            {
                Header: 'Message',
                accessor: 'message',
            },
            {
                Header: 'Approved',
                accessor: 'approved',
                Cell: ({ row }) => (
                    <>
                        {row?.original?.approved ?
                            <Button
                                classList={'success'}
                                text={'Approved'}
                            /> :
                            <Button
                                classList={'alert'}
                                text={'Rejected'}
                            />
                        }
                    </>
                )
            },
        ];
    }, [currentPageData]);

    return (
        <>
            <Breadcrumb string={'Admin/Customization Logs'} />
            <div className="container kinetizine">
                <CustomTable data={currentPageData} columnData={columns} showFilters={false} />
                <AdminPagination
                    dataPerPage={dataPerPage}
                    totalData={totalData}
                    onPageChange={handlePageChange}
                />
            </div>
        </>
    )
}
export default AdminCustomisationLogPage;
